import React, { useEffect, useState } from "react";
import { useSubheader } from "../../../_metronic/layout";
import api from "../../../api";
import CustomTable from "../../../app/components/CustomTable";
import { format, parseISO, isValid } from "date-fns";
import moment from "moment";
import { numberFormat } from "../../../functions";
import { useSelector } from "react-redux";

export function CommissionsReport() {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Comisiones");
  
  const { user } = useSelector((state) => state?.auth);
  const [rows, setRows] = useState([]);
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(moment().subtract(7, "days"));

  const fetchRows = async (search, start, end) => {
    api
      .get("bi/commissions", {
        params: {
          StartDate: moment(start ? start : startDate).format("yyyy/MM/DD"),
          EndDate: moment(end ? end : endDate).format("yyyy/MM/DD"),
       },
      })
      .then(
        (result) => {
          let data = result.data.body;
          setRows(data);
        },
        (error) => {
          setRows([]);
          console.log(error);
        }
      );
  };

  
  const handleCallback = async(start, end, label) => {
    await setStartDate(start);
    await setEndDate(end);
    await fetchRows("", start, end);
  };

  useEffect(() => {
    fetchRows();
    return () => {
    }
  }, []);


  const headRows = [
    { name: "Id", label: "Folio" },
    {
      name: "InsertedSAPAt",
      label: "Fecha",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return isValid(value)
            ? format(value, "dd/MM/yyyy HH:mm")
            : format(new Date(value), "dd/MM/yyy HH:mm");
        },
      },
    },
    { name: "ContractNumber", label: "Contrato" },
    {
      name: "Total",
      label: "Total",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return numberFormat(value ?? 0);
        },
      },
    },
    {
      name: "CommissionBase",
      label: "Base para comisión",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return numberFormat(value ?? 0);
        },
      },
    },
    {
      name: "Commission",
      label: "Comisión Integre 26%",
      options: {
        display: user.RoleId == 1 ? true : false,
        filter: false,
        customBodyRender: (value) => {
            return numberFormat(value ?? 0);
        },
      },
    },
    {
      name: "SecondaryCommission",
      label: "Comisión Broker",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return numberFormat(value ?? 0);
        },
      },
    },
    {
        name: "AmountFinanced",
        label: "Monto financiado",
        options: {
          filter: false,
          customBodyRender: (value) => {
            return numberFormat(value ?? 0);
          },
        },
    },
    { name: "NumberPayments", label: "Cuotas" },
    { name: "FinancingDescription", label: "Forma de financiamiento" },
    { name: "PlanDescription", label: "Plan contratado" },    
    { name: "Week", label: "Semana" },    
    { name: "Name", label: "Consultor" },
    { name: "ManagerName", label: "Gerente" },
  ];

  return (
    <CustomTable
      data={rows}
      columns={headRows}
      title="Comisiones"
      actionAdd={false}
      dateRange={true}
      handleCallback={handleCallback}
      startDate={startDate}
      endDate={endDate}
    />
  );
}

export default CommissionsReport;