import { parseISO } from "date-fns";
import externalApi from "./externalApi";


export const numberFormat = (value) =>
  new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(value);

export const calculateAge = (date) => {
  let date2;
  if (typeof date === "string") {
    date2 = new Date(parseISO(date));
    date = date2;
  }
  if (date) {
    let today = new Date();
    let ageCalc = today.getFullYear() - date.getFullYear();
    let birthdate = today.getMonth() - date.getMonth();

    if (!isNaN(ageCalc)) {
      if (
        birthdate < 0 ||
        (birthdate === 0 && today.getDate() < date.getDate())
      ) {
        ageCalc--;
      }
      return ageCalc;
    }
    return ageCalc;
  }
};

export const getZipCodeData = async (zipCode) => {
  if (zipCode?.length === 5) {
    return  await externalApi
      .get(
        `https://api.copomex.com/query/info_cp/${zipCode}?type=simplified&token=4e2b8742-6d14-4f4b-a341-9c83ea8f6f00`
      )
      .then((response) => {
        var data = response.data.response;
        if (!data.error) {
          if (data.estado === "México") {
            data.estado = "Estado de México";
          } else if (data.estado === "Veracruz de Ignacio de la Llave") {
            data.estado = "Veracruz";
          }else if (data.estado === "Coahuila de Zaragoza") {
            data.estado = "Coahuila";
          }else if (data.estado === "Michoacán de Ocampo") {
            data.estado = "Michoacán";
          }
          return data
        } else {
          return 
        }
      })
      .catch(function(error) {
        console.log(error);
        
      })
    }
}
