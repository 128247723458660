import axios from "axios";
import { useState, useEffect } from "react";

const splashScreen = document.getElementById("splash-screen");
let loading = true;

const api = () => {
  function isTokenExpiredError(errorResponse) {
    if(errorResponse){
      return errorResponse.status === 403 ? true : false;
    }    
  }

  let isAlreadyFetchingAccessToken = false;

  const getResetToken = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const user = JSON.parse(localStorage.getItem("user"));
    const latitude = JSON.parse(localStorage.getItem("latitude"));
    const longitude = JSON.parse(localStorage.getItem("longitude"));
    const data = { token: accessToken, username: user.UserName, Latitude: latitude, Longitude: longitude };
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch("https://api.leader.sale/user/updateToken", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let data = JSON.parse(result);
        if (data.status === 200) {
          localStorage.removeItem("accessToken");
          localStorage.setItem("accessToken", data.body.accessToken);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const saveRefreshToken = () => {};

  let subscribers = [];

  async function resetTokenAndReattemptRequest(error) {
    try {
      const { response: errorResponse } = error;
      const resetToken = await getResetToken();
      
      if (!resetToken) {
        return Promise.reject(error);
      }
      
      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((access_token) => {
          errorResponse.config.headers.Authorization = "Bearer " + access_token;
          resolve(axios(errorResponse.config));
        });
      });
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        const response = await axios({
          method: "post",
          url: `user/updateToken`,
          data: {
            token: resetToken,
          },
        });
        if (!response.data) {
          return Promise.reject(error);
        }
        const newToken = response.data.token;
        saveRefreshToken(newToken);
        isAlreadyFetchingAccessToken = false;
        onAccessTokenFetched(newToken);
      }
      return retryOriginalRequest;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  function onAccessTokenFetched(access_token) {
    subscribers.forEach((callback) => callback(access_token));
    subscribers = [];
  }

  function addSubscriber(callback) {
    subscribers.push(callback);
  }

  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use(
    function(config) {
      splashScreen.classList.remove("hidden");
      
      const token = localStorage.getItem("accessToken");

      if (token) {
        config.headers.Authorization = token ? `Bearer ${token}` : "";
      }
      return config;
    },
    function(error) {
      splashScreen.classList.add("hidden");
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    
    function(response) {
      
      splashScreen.classList.add("hidden");

      return response;
    },
    
    function(error) {
      const errorResponse = error.response;
      splashScreen.classList.add("hidden");
      if(errorResponse.status === 401){
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        localStorage.removeItem("persist:_B11_AlcisCorp_");
        window.location.replace("/auth/login")
        return;
      } else if (isTokenExpiredError(errorResponse)) {  
        return resetTokenAndReattemptRequest(error);
      }else{
        return Promise.reject(error.response);
      }     
    }
  );

  return instance;
};

export default api();
