/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import ApexCharts from "apexcharts";
import moment from "moment";

export function StatsTotalDonut({
  className,
  total,
  startDate,
  endDate
}) {
  let series = [];
  let categories = [];
  let count = 0;
  if (total && total.length > 0) {
    total.map((data) => {
      series.push(data.SalesPerMethod);
      categories.push(data.DownPaymentMethod);
    });
    count = series.reduce((total, num)=> num + total);
  }
  

  useEffect(() => {
    const element = document.getElementById("kt_stats_widget_total_donut");

    if (!element) {
      return;
    }

    const options = {
      series: series,
      plotOptions: {
        pie: {
          customScale: 0.8,
          donut: {
            labels: {
              show: true,
              
            }
          },
        },
      },
      labels: categories,
      colors: [ "#00e495", "#32067e", "#fd7e14", "#e83e8c", "#008ffb", "#ff4560", "#feaf1a"],
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    try {
      if (total) {
        const chartActivities = new ApexCharts(element, options);
        chartActivities.render();
        return function cleanUp() {
          chartActivities.destroy();
        };
      }
    } catch {}
  }, [total]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column p-2">
        <div className="d-flex align-items-start justify-content-between card-spacer">
        <div className="d-flex flex-row justify-content-between mr-2" style={{width: "100%"}}>
        <div className="d-flex flex-column mr-2">
           
           <span
              className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5"
            >
              TOTAL
            </span>
            <span className="text-dark-75 text-hover-primary">
              Del {moment(startDate).format("DD/MM/yyyy")} al {moment(endDate).format("DD/MM/yyyy")}
            </span>
            </div>
            <span className="symbol symbol-light-primary symbol-45">
            <span className="symbol-label font-weight-bolder font-size-h6">
              {count}
            </span>
          </span> 
          </div>
        </div>
        <>
          {total ? (
            <div
              id="kt_stats_widget_total_donut"
              className="card-rounded-bottom"
              style={{ height: "80px" }}
            ></div>
          ) : (
            <></>
          )}
        </>
      </div>
    </div>
  );
}
