/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../../api";
import queryString from "query-string";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from "@material-ui/core/Avatar";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import intervalToDuration from 'date-fns/intervalToDuration'
import socketIOClient from "socket.io-client";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { format, parseISO } from "date-fns";
import {useSelector} from "react-redux";
 
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    overflowX: "hidden",
    listStyle: 'none',
    margin: 0,
    background: "none",
    boxShadow: "none",
    marginBottom: "14px",
    [theme.breakpoints.down('sm')]: {
      overflowX: "auto",
      "&:hover, &:focus, &:active, &:visited": {
        overflowX: "auto",
        "&::-webkit-scrollbar":{ 
          display:"flex"
        },
      },
      "&::-webkit-scrollbar":{ 
        display:"none",
        "&:hover": {
          display: "flex"
        }
      },
      
    },
    "&:hover, &:focus": {
      overflowX: "auto",
      "&::-webkit-scrollbar":{ 
        display:"inline-block"
      },
    },
    "&::-webkit-scrollbar":{
      width: "0.5rem",   
      height: "0.5rem",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#ccc",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#b3b3b3",
      boxShadow: "0 0 2px 1px rgba(0, 0, 0, 0.2)"
    },
    "&::-webkit-scrollbar-thumb:active": {
      backgroundColor: "#999999"
    },
    "&::-webkit-scrollbar-track":{
      background: "#e1e1e1",
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-track:hover": {
      background: "#d4d4d4"
    },
    "&::-webkit-scrollbar-track:active": {
      background: "#d4d4d4"
    }
  },

  chip: {
    margin: theme.spacing(0.5),
  },
  small: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    textTransform: "uppercase"
  },
  itemAvatar: {
    minWidth: "fit-content",
    padding: "4px"
  },
  rootList: {
    cursor: "pointer",
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(1),
    padding: "0 6px",
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  }, 
  primary: {
    padding: "0"
  }
}));

export function ListsWidgetCarousel() {
  const classes = useStyles();
  const [chipData, setChipData] = React.useState([]);
  const [carouselLeads, setCarouselLeads] = useState( 0 );
  const [prospectsCarousel, setProspectsCarousel] = useState();
  const history = useHistory();
  const [directAssignment, setDirectAssignment] = useState(false);
  const [open, setOpen] = useState(false);
  const [membersGroup, setMembersGroup] = useState([]);
  const [lead, setLead] = useState({});
  const [assign, setAssign] = useState({});
  const {user} = useSelector(state => state.auth);
  
  const handleClickOpen = (lead) => {
    setLead(lead);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    api.post(`carousel/${lead.Id}/assign`,{
      SlpCode: assign.EmployeeId
    } ).then(
      (result) => {
        if(result.data.status === 201) {
          setOpen(false)
          fetchCarousel()
        }
      },
      (error) => {
        console.log(error)
      }
    );
  };

  useEffect(() => {
    if(user.CarouselOn === 1 || user.RoleId === 4) {
      fetchCarousel();
      fetchMembersGroup();
      if(user.RoleId <= 4) {
        setDirectAssignment(true)
      }
      const interval = setInterval(() => {
        fetchCarousel();
      }, 30000);
      return () => {
        clearInterval(interval);
        setMembersGroup([])
      };
    }
  }, [user]);
  
  const fetchAttend = (lead) => {
    api.post(`carousel/${lead.Id}/attend`).then((result) => {
      let data = result.data;
      if (data.status === 201) {
        let stringLead = queryString.stringify(lead);
        history.push(
          `prospect/add/?data=${stringLead}`
        );
      }
    });
  };
  const formattedDate = (date) => {
    let result = intervalToDuration({
      start: parseISO(date),
      end: new Date()
    })
    return `Hace ${result.minutes} min`
   }
  const fetchCarousel = () => {
    api.get("carousel").then(
      (result) => {
        if (result.status === 200) {
          if(Array.isArray(result.data.body)){
          setChipData(result.data.body);
          setCarouselLeads( result.data.body.length );
          }
          //setProspectsCarousel(result.data.body);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const fetchMembersGroup = (id) => {
    api.get("catalog/advisers").then(
      (result) => {
        if(result.data.status === 200) {
          setMembersGroup(result.data.body)
        }
      },
      (error) => {
        console.log(error)
      }
    );
  };
 
  useEffect(()=> {
    if(prospectsCarousel){
      if(Array.isArray(prospectsCarousel)){
        setChipData(prospectsCarousel);
        setCarouselLeads( prospectsCarousel.length );
      }
    }
  },[prospectsCarousel])

  return (
      <List className={classes.root}>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{lead.FullName}</DialogTitle>
        <DialogContent>
          <Typography
                component="p"
                variant="caption"
                color="initial"
              >
            Por favor selecciona al consultor que deseas asignar de forma directa este prospecto.
          </Typography>
          <Autocomplete
            name="Consultor"
            options={membersGroup}
            getOptionLabel={(option) => `${option.Adviser}`}
            style={{ width: "100%" }}
            onChange={(event, value)=> value !== null ? setAssign(value) : setAssign({})}
            renderInput={(params) => (
              <TextField {...params} label="Consultor" variant="outlined" />
            )}
          /> 
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <ListItem className={classes.rootList} key="-1">
        <center>
        LEADs
        <span className="card-label font-weight-bolder text-center">
          <br /><br/> {carouselLeads}
        </span>
        </center>
      </ListItem>
        {chipData.map((lead) => {
        return (
          <ListItem className={classes.rootList} key={lead.Id}>
            <ListItemAvatar className={classes.itemAvatar}>
              <Avatar className={classes.small}>
               {lead.Name[0]}
              </Avatar>
            </ListItemAvatar>
            <ListItemText className={classes.primary} primary={
              <React.Fragment>
                <Typography
                component="p"
                variant="body1"
                color="primary"
                style={{
                  padding: 0,
                  textOverflow:"ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",}} 
                  onClick={() => fetchAttend(lead)} 
                >{lead.FullName.toUpperCase()}</Typography>
                <Typography
                component="p"
                variant="caption"
                color="initial"
                style={{padding: 0}}
              >
                {lead.ProspectionMeans}
                <br></br>
                {format(parseISO(lead.CreatedAt), "dd/MM/yyyy HH:mm")}
              </Typography>
              <Typography
                component="p"
                variant="subtitle2"
                color="initial"
                style={{padding: 0}}
              >
                {formattedDate(lead.CreatedAt)}
              </Typography>
              { directAssignment && (
                <Grid container justify="flex-end">
                <Grid item>
                <Button variant="outlined" color="primary" onClick={() => handleClickOpen(lead)}>
                  Reasignar
                </Button>
                </Grid>
              </Grid>
              )}
              </React.Fragment>
              }/>
          </ListItem>
        );
      }
      )}
    </List>

  );
}
