import React, { useState, useEffect, useRef } from "react";
import {
  StatsDailySales,
  StatsProspectionSales,
  StatsRanking,
  StatsTopItemsSold,
  StatsTotalDonut,
  StatsFinancing,
  StatsCash,
  StatsSalesAmount,
  StatsHeatMapByCounty,
  /* StatsHeatMapByItemCode */
} from "../widgets";

import moment from "moment";
import DateRangePickerCustom from "../../../app/components/DateRangePickerCustom";
import api from "../../../api";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import { numberFormat } from "../../../functions";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import CustomTable from "../../../app/components/CustomTable";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SalesDashboard(props) {
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
  const [salesAdvisors, setSalesAdvisors] = useState([]);
  const [salesReport, setSalesReport] = useState({});

  const [advisers, setAdvisers] = useState([]);
  const [assistantManagers, setAssistantManagers] = useState([]);
  const [assistantManagersId, setAssistantManagersId] = useState([]);
  const [managements, setManagements] = useState([]);
  const [managementsId, setManagementsId] = useState([]);
  const [jrManagements, setJrManagements] = useState([]);
  const [jrManagementsId, setJrManagementsId] = useState([]);
  const [businessLine, setBusinessLine] = useState({});

  const [activeTeams, setActiveTeams] = useState( [] );
  const [activeTeamsId, setActiveTeamsId] = useState( [] );

  const inputRef = useRef(null);

  const { user } = useSelector((state) => state.auth);

  const businessLines = [
    {
      Code: "*",
      Name: "Todas (PF, SG)",
    },
    {
      Code: "PF",
      Name: "Previsión final",
    },
    {
      Code: "SG",
      Name: "Santa Gloria",
    },
  ];

  const fetchAssistantManagers = () => {
    api.get("catalog/vice-principal-byuser").then(
      (result) => {
        setAssistantManagers(result.data.body);
      },
      (error) => {
        setAssistantManagers([]);
      }
    );
  };

  const fetchManagements = () => {
    let assistantManagers = assistantManagersId.map(item => item.AssistantManagerId);
    api.get("catalog/managements-byuser", {
      params: {
        AssistantManagers: assistantManagers,
      },
    }).then(
      (result) => {
        setManagements(result.data.body);
      },
      (error) => {
        setManagements([]);
      }
    );
  }

  const fetchJrManagements = () => {
    let managements = managementsId.map(item => item.ManagementId);
    api.get("catalog/managements-jr-byuser", {
      params: {
        canalManagers: managements,
      },
    }).then(
      (result) => {
        setJrManagements(result.data.body);
      },
      (error) => {
        setJrManagements([]);
      }
    );
  }

  const fetchActiveTeams = () => {
    api.get( "catalog/active-teams" )
      .then(
        (result) => {
          setActiveTeams( result.data.body );
        },
        (error) => {
          setActiveTeams( [] );
        }
      );
  }

  const fetchAdvisers = () => {
    let managements = jrManagementsId.map(item => item.ManagementId);
    api.get("catalog/advisers-byuser", {
        params: {
          Management: managements,
        },
      })
      .then(
        (result) => {
          setAdvisers(result.data.body);
        },
        (error) => {
          setAdvisers([]);
        }
      );
  }

  const fetchAdvisersTeams = () => {
    let activeTeams = activeTeamsId.map( item => item.TeamId );
    api.get( "catalog/team-members", {
      params: {
        AdvisorsByTeams: 1,
        TeamId: activeTeams
      }
    })
    .then(
      (result) => {
        setAdvisers( result.data.body );
      },
      (error) => {
        setAdvisers( [] );
      }
    );
    
  }

  const fetchData = (start, end) => {
    let ids = salesAdvisors.map(item => item.SlpCode);
    let jrManagements = jrManagementsId.map(item => item.ManagementId);
    let managements = managementsId.map(item => item.ManagementId);
    let assistantManagers = assistantManagersId.map(item => item.AssistantManagerId);
    let activeTeams = activeTeamsId.map( item => item.TeamId );
    let payload = {
      BusinessLine: businessLine && businessLine.Code ? businessLine.Code : "*",
      StartDate: moment(start).format("yyyy/MM/DD"),
      EndDate: moment(end).format("yyyy/MM/DD"),
      SlpCode: ids,
      JrManagement: jrManagements,
      Management: managements,
      AssistantManagers: assistantManagers,
      ActiveTeams: activeTeams
    }

    return api
      .get(`dashboard/get`, {
        params: payload,
      })
      .then(
        (result) => {
          if (result.status === 201) {
            setSalesReport(result.data.body);
          }
        },
        (error) => {
          setSalesReport({});
          console.log(error);
        }
      );
  }

  const handleBusinessLineChange = (event, value) => {
    if (value !== null) {      
      setBusinessLine(value);
    } else {
      setBusinessLine(undefined);
    }
  }

  const handleAssistantManagerChange = (event, value) => {
    if (value !== null) {      
      setAssistantManagersId(value);
      setManagementsId([]);
      setJrManagementsId([]);
      setSalesAdvisors([]);
    } else {
      setAssistantManagersId(undefined);
    }
  }

  const handleManagementChange = (event, value) => {
    if (value !== null) {      
      setManagementsId(value);
      setJrManagementsId([]);
      setSalesAdvisors([])
    } else {
      setManagementsId(undefined);
    }
  }

  const handleJrManagementChange = (event, value) => {
    if (value !== null) {      
      setJrManagementsId(value);
      setSalesAdvisors([])
    } else {
      setManagementsId(undefined);
    }
  }

  const handleActiveTeamsChange = (event, value) => {
    if (value !== null) {
      setActiveTeamsId( value );
      setManagementsId( [] );
      setJrManagementsId( [] );
      setSalesAdvisors( [] );

    } else {
      setActiveTeamsId( undefined );

    }
  }

  const handleAdviserChange = (event, value) => {
    if (value !== null) {
      setSalesAdvisors(value);
    } else {
      setSalesAdvisors(undefined);
    }
  }

  const handleCallback = async (start, end) => {
    await setStartDate(start);
    await setEndDate(end);
    await fetchData(start, end);
  }

  useEffect(() => {
    fetchAssistantManagers();
    fetchActiveTeams();
  }, []);

  useEffect(() => {
    fetchManagements();
  }, [assistantManagersId]);

  useEffect(() => {
    fetchJrManagements();
  }, [managementsId]);

  useEffect(() => {
    fetchAdvisers();
  }, [jrManagementsId]);

  useEffect( () => {
    fetchAdvisersTeams();
  }, [activeTeamsId] );

  useEffect(() => {
    fetchData(startDate, endDate);
  }, [salesAdvisors, managementsId, jrManagementsId, assistantManagersId, businessLine, activeTeamsId]);

  const executeScroll = () => inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });   

  const headRows = [
    { name: "AdvisorName", label: "Nombre" },
    { name: "ManagerName", label: "Gerencia" },
  ];

  const headRowsSales = [
    { name: "AdvisorName", label: "Nombre" },
    { name: "Sales",       label: "Ventas" },
    { name: "ManagerName", label: "Gerencia" },
  ];

  const headRowsHeatMapByCounty = [
    { name: "StateCode", label: "Estado" },
    { name: "County", label: "Alcaldía" },
    { name: "ZipCode", label: "Código Postal" },
    { name: "SalesCount", label: "Ventas" },
    { name: "SalesAmount", label: "Monto" },
  ];

  const headRowsHeatMapByItemCode = [
    { name: "StateCode", label: "Estado" },
    { name: "County", label: "Alcaldía" },
    { name: "ItemName", label: "Producto" },
    { name: "SalesCount", label: "Ventas" },
    { name: "SalesAmount", label: "Monto" },
  ];
  return (
    <>
      <div className="card">
        <div className="card-header border-0 pt-5 bg-light">
          <div className="row align-items-center">
            <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xxl-2 mb-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Filtros
                </span>
              </h3>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-xs-12 col-sm-6 col-md-2">
              <Autocomplete             
                size="small"
                options={businessLines}
                getOptionLabel={(option) => option.Name}
                onChange={handleBusinessLineChange}                
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Linea de negocio"
                    placeholder="Linea de negocio"
                  />
                )}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-5">
              <Autocomplete
                multiple               
                size="small"
                options={assistantManagers}
                getOptionLabel={(option) => option.AssitantManagerName}
                onChange={handleAssistantManagerChange}                
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Subdirección"
                    placeholder="Subdirección"
                  />
                )}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-5">
              <Autocomplete
                multiple               
                size="small"
                options={managements}
                getOptionLabel={(option) => option.ManagementName}
                onChange={handleManagementChange}                
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Gerencia de Canal"
                    placeholder="Gerencia de Canal"
                  />
                )}
              />
            </div>

            <div className="col-xs-12 col-sm-6 col-md-5">
              <Autocomplete
                multiple               
                size="small"
                options={jrManagements}
                getOptionLabel={(option) => option.ManagementName}
                onChange={handleJrManagementChange}                
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Gerencia Jr"
                    placeholder="Gerencia Jr"
                  />
                )}
              />
            </div>

            { user.RoleId > 0 && user.RoleId < 4 && (
              <div className="col-xs-12 col-sm-6 col-md-5">
                <Autocomplete
                  multiple               
                  size="small"
                  options={activeTeams}
                  getOptionLabel={(option) => option.Name}
                  onChange={handleActiveTeamsChange}                
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Equipo"
                      placeholder="Seleccione Equipo"
                    />
                  )}
                />
              </div>
            ) }

            <div className="col-xs-12 col-sm-6 col-md-6">
              <Autocomplete
                name="SlpCode"
                multiple   
                size="small"
                options={advisers}
                getOptionLabel={(option) =>
                  `${option.EmployeeId} - ${option.Adviser}`
                }
                onChange={handleAdviserChange}
                value={salesAdvisors}

                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="SlpCode"
                    label="Asesor"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6">
              <DateRangePickerCustom
                handleCallback={handleCallback}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
          <div className="col-md-8 col-lg-8 col-xxl-8 mb-5 bg-primary rounded-xl py-5">
              <div className="row m-0">
                <div className="col-md-12 px-6 py-2 rounded-xl">
                  <h3>Bye Bye Friend</h3>
                </div>
                <div className="col-md-3 px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h3 className="card-title font-weight-bolder my-0 text-white">
                    Total de ventas
                  </h3>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>

                  <span className="text-white font-weight-bolder font-size-h3">
                    <i className="flaticon-diagram display-4 text-white font-weight-bold"></i>{" "}
                    {(salesReport.SalesBBF && salesReport.SalesBBF.Sales) ? salesReport.SalesBBF.Sales : 0}
                  </span>
                </div>
                <div className="col-md-3 px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h3 className="card-title font-weight-bolder my-0 text-white">
                    Monto de venta
                  </h3>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>

                  <span className="text-white font-weight-bolder font-size-h3">
                    <i className="flaticon-coins display-4 text-white font-weight-bold"></i>{" "}
                    {numberFormat((salesReport.SalesBBF && salesReport.SalesBBF.SalesContract) ? salesReport.SalesBBF.SalesContract : 0)}
                  </span>
                </div>
                <div className="col-md-3 px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h3 className="card-title font-weight-bolder my-0 text-white">
                    Monto de descuento
                  </h3>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>

                  <span className="text-white font-weight-bolder font-size-h3">
                    <i className="flaticon-price-tag display-4 text-white font-weight-bold"></i>{" "}
                    {numberFormat((salesReport.SalesBBF && salesReport.SalesBBF.SalesDiscount) ? salesReport.SalesBBF.SalesDiscount : 0)}
                  </span>
                </div>
                <div className="col-md-3 px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h3 className="card-title font-weight-bolder my-0 text-white">
                    Monto neto de venta
                  </h3>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>

                  <span className="text-white font-weight-bolder font-size-h3">
                    <i className="flaticon-piggy-bank display-4 text-white font-weight-bold"></i>{" "}
                    {numberFormat((salesReport.SalesBBF && salesReport.SalesBBF.SalesAmount) ? salesReport.SalesBBF.SalesAmount : 0)}
                  </span>
                </div>
              </div>
              <hr></hr>
              <div className="row m-0">
              <div className="col-md-12 px-6 py-2 rounded-xl">
                  <h3>Otras líneas de negocio</h3>
                </div>
                <div className="col px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h3 className="card-title font-weight-bolder my-0 text-white">
                    Monto de venta
                  </h3>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>

                  <span className="text-white font-weight-bolder font-size-h3">
                    <i className="flaticon-coins display-4 text-white font-weight-bold"></i>{" "}
                    {numberFormat(salesReport.SalesContract ?? 0)}
                  </span>
                </div>

                <div onClick={executeScroll} className="col px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h3 className="card-title font-weight-bolder my-0 text-white">
                    Asesores con venta
                  </h3>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>

                  <span className="text-white font-weight-bolder font-size-h3">
                    <i className="flaticon-users display-4 text-white font-weight-bold"></i>{" "}
                    {salesReport.SalesAdvisors ?? 0}
                  </span>
                </div>

              </div>
              <div className="row m-0">
                <div className="col px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h4 className="card-title  my-0 text-white">
                  Monto de descuento
                  </h4>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <span className="text-white font-weight-bolder font-size-h3">
                    <i className="flaticon-price-tag display-4 text-white font-weight-bold"></i>{" "}
                    {numberFormat(salesReport.DiscountAmount ?? 0)}
                  </span>
                </div>

                <div onClick={executeScroll} className="col px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h3 className="card-title font-weight-bolder my-0 text-white">
                    Asesores al día
                  </h3>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>

                  <span className="text-white font-weight-bolder font-size-h3">
                    <i className="flaticon-users display-4 text-white font-weight-bold"></i>{" "}
                    {salesReport.DailyAdvisers ?? 0}
                  </span>
                </div>

              </div>
              <div className="row m-0">
                <div className="col px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h4 className="card-title  my-0 text-white">
                  Monto neto de venta
                  </h4>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>

                  <span className="text-white font-weight-bolder font-size-h3">
                    <i className="flaticon-piggy-bank display-4 text-white font-weight-bold"></i>{" "}
                    {numberFormat(salesReport.SalesAmount ?? 0)}
                  </span>
                </div>

                <div className="col px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h3 className="card-title font-weight-bolder my-0 text-white">
                    Asesores Inicial
                  </h3>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>

                  <span className="text-white font-weight-bolder font-size-h3">
                    <i className="flaticon-users display-4 text-white font-weight-bold"></i>{" "}
                    {salesReport.InitialSalesAdvisors ?? 0}
                  </span>
                </div>

              </div>
              <hr></hr>
              <div className="row m-0">
                <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                    <i className="flaticon-diagram text-warning display-4 text-white font-weight-bold"></i>
                    <span className="text-warning font-weight-bolder font-size-h3">
                      {salesReport.SalesTotal ?? 0}
                    </span>
                  </span>
                  <span className="text-warning font-weight-bold font-size-h6">
                    Total de ventas
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-7 mb-7">
                  <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                    <i className="flaticon-notepad text-primary display-4 text-white font-weight-bold"></i>
                    <span className="text-primary font-weight-bolder font-size-h3">
                      {numberFormat(salesReport.AverageTicket ?? 0)}
                    </span>
                  </span>
                  <span className="text-primary font-weight-bold font-size-h6 mt-2">
                    Ticket promedio
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <div className="col bg-light-info px-6 py-8 rounded-xl mr-7 mb-7">
                  <span className="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                    <i className="flaticon-notepad text-info display-4 text-white font-weight-bold"></i>
                    <span className="text-info font-weight-bolder font-size-h3">
                      {numberFormat(salesReport.AveragePlanTicket ?? 0)}
                    </span>
                  </span>
                  <span className="text-info font-weight-bold font-size-h6 mt-2">
                    Ticket promedio por servicio
                  </span>
                </div>
                <div className="col bg-light-success px-6 py-8 rounded-xl mr-7 mb-7">
                  <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                    <i className="flaticon-business text-success  display-4 text-white font-weight-bold"></i>
                    <span className="text-success font-weight-bolder font-size-h3">
                      {numberFormat(salesReport.DownPaymentTotal ?? 0)}
                    </span>
                  </span>

                  <span className="text-success font-weight-bold font-size-h6 mt-2">
                    Suma total de enganches
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-xxl-4 mb-5 bg-danger rounded-xl py-5">
              <div className="row m-0">
                <div className="col px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h3 className="card-title font-weight-bolder my-0 text-white">
                    Monto de venta cancelada
                  </h3>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>

                  <span className="text-white font-weight-bolder font-size-h3">
                    <i className="flaticon-coins display-4 text-white font-weight-bold"></i>{" "}
                    {numberFormat(salesReport.SalesContractCancelled ?? 0)}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <div className="col px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h4 className="card-title  my-0 text-white">
                  Monto de descuento de venta cancelada
                  </h4>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <span className="text-white font-weight-bolder font-size-h3">
                    <i className="flaticon-price-tag display-4 text-white font-weight-bold"></i>{" "}
                    {numberFormat(salesReport.DiscountAmountCancelled ?? 0)}
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <div className="col px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h4 className="card-title  my-0 text-white">
                  Monto neto de venta cancelada
                  </h4>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>

                  <span className="text-white font-weight-bolder font-size-h3">
                    <i className="flaticon-piggy-bank display-4 text-white font-weight-bold"></i>{" "}
                    {numberFormat(salesReport.SalesAmountCancelled ?? 0)}
                  </span>
                </div>
              </div>
              <hr></hr>
              <div className="row m-0">
                <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                    <i className="flaticon-diagram text-warning display-4 text-white font-weight-bold"></i>
                    <span className="text-warning font-weight-bolder font-size-h3">
                      {salesReport.SalesTotalCancelled ?? 0}
                    </span>
                  </span>
                  <span className="text-warning font-weight-bold font-size-h6">
                    Total de ventas canceladas
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <div className="col bg-light-success px-6 py-8 rounded-xl mr-7 mb-7">
                  <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                    <i className="flaticon-business text-success  display-4 text-white font-weight-bold"></i>
                    <span className="text-success font-weight-bolder font-size-h3">
                      {numberFormat(salesReport.DownPaymentTotalCancelled ?? 0)}
                    </span>
                  </span>
                  <span className="text-success font-weight-bold font-size-h6 mt-2">
                    Suma total de enganches cancelados
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-xxl-12 mb-5 bg-light-primary rounded-xl py-5">
              <StatsDailySales
                dailySales={salesReport.DailySales}
                endDate={endDate}
                startDate={startDate}
              />
            </div>


            <div className="col-md-12 col-lg-12 col-xxl-12 mb-5 bg-light-primary rounded-xl py-5">
              <StatsProspectionSales
                prospectionSales={salesReport.ProspectionMeansSales}
                endDate={endDate}
                startDate={startDate}
              />
            </div>



            <div className="col-md-6 col-lg-6 col-xxl-6 mb-5 bg-light-primary rounded-xl py-5">
              <StatsSalesAmount
                amount={salesReport.DailySales}
                endDate={endDate}
                startDate={startDate}
              />
            </div>
            <div className="col-md-6 col-lg-6 col-xxl-6 mb-5 bg-light-primary rounded-xl py-5">
              <StatsCash
                cash={salesReport.DailySales}
                endDate={endDate}
                startDate={startDate}
              />
            </div>
            <div className="col-md-6 col-lg-6 col-xxl-6 mb-5 bg-light-primary rounded-xl py-5">
              <StatsFinancing
                financing={salesReport.Financing}
                endDate={endDate}
                startDate={startDate}
              />
            </div>
            <div className="col-md-6 col-lg-6 col-xxl-6 mb-5 bg-light-primary rounded-xl py-5">
              <StatsTotalDonut
                total={salesReport.DownPaymentMethod}
                endDate={endDate}
                startDate={startDate}
              />
            </div>
            <div className="col-md-12 col-lg-12 col-xxl-12 mb-5 bg-light-primary rounded-xl py-5">
              <StatsTopItemsSold
                topItemsSold={salesReport.TopItemsSold}
                endDate={endDate}
                startDate={startDate}
              />
            </div>
            <div className="col-md-12 col-lg-12 col-xxl-12 mb-5 bg-light-primary rounded-xl py-5">
              <StatsRanking
                ranking={salesReport.RankingSlp}
                endDate={endDate}
                startDate={startDate}
              />
            </div>
            <div className="col-md-12 col-lg-4 col-xxl-4 mb-5 bg-light-primary rounded-xl py-5">
              <CustomTable
                data={salesReport.AdvisorsPerformance && salesReport.AdvisorsPerformance.AdvisorsActive ? salesReport.AdvisorsPerformance.AdvisorsActive : []}
                columns={headRows}
                title="Asesores activos"
                actionAdd={false}
              />
            </div>
            <div ref={inputRef} className="col-md-12 col-lg-4 col-xxl-4 mb-5 bg-light-primary rounded-xl py-5">
              <CustomTable
                data={salesReport.AdvisorsPerformance && salesReport.AdvisorsPerformance.AdvisorsWithSales ? salesReport.AdvisorsPerformance.AdvisorsWithSales : []}
                columns={headRowsSales}
                title="Asesores con venta"
                actionAdd={false}
              />
            </div>
            <div className="col-md-12 col-lg-4 col-xxl-4 mb-5 bg-light-primary rounded-xl py-5">
              <CustomTable
                data={salesReport.AdvisorsPerformance && salesReport.AdvisorsPerformance.AdvisorsWithoutSales ? salesReport.AdvisorsPerformance.AdvisorsWithoutSales : []}
                columns={headRows}
                title="Asesores sin venta"
                actionAdd={false}
              />
            </div>

            <div className="col-md-12 col-lg-6 col-xxl-6 mb-5 bg-light-primary rounded-xl py-5">
              <CustomTable
                data={salesReport.HeatMap && salesReport.HeatMap.byCountyZipCode ? salesReport.HeatMap.byCountyZipCode : []}
                columns={headRowsHeatMapByCounty}
                title="Ventas por Alcaldía/Municipio"
                actionAdd={false}
              />
            </div>
            <div className="col-md-12 col-lg-6 col-xxl-6 mb-5 bg-light-primary rounded-xl py-5">
              <CustomTable
                data={salesReport.HeatMap && salesReport.HeatMap.byItemCode ? salesReport.HeatMap.byItemCode : []}
                columns={headRowsHeatMapByItemCode}
                title="Ventas por Alcaldía-Producto"
                actionAdd={false}
              />
            </div>

            <div className="col-md-12 col-lg-12 col-xxl-12 mb-5 bg-light-primary rounded-xl py-5">
              <StatsHeatMapByCounty
                topHeatMapCounty={salesReport.HeatMap && salesReport.HeatMap.byCounty ? salesReport.HeatMap.byCounty : []}
                endDate={endDate}
                startDate={startDate}
              />
            </div>
            {/* <div className="col-md-12 col-lg-12 col-xxl-12 mb-5 bg-light-primary rounded-xl py-5">
              <StatsHeatMapByItemCode
                topHeatMapItemCode={salesReport.HeatMap && salesReport.HeatMap.byItemCode ? salesReport.HeatMap.byItemCode : []}
                endDate={endDate}
                startDate={startDate}
              />
            </div> */}

          </div>
        </div>
      </div>
    </>
  );
}
