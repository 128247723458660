import api from "../../../../api"

export const LOGIN_URL = "user/auth/signin";
export const REGISTER_URL = "user/auth/register";
export const REQUEST_PASSWORD_URL = "user/auth/forgot-password";

export const ME_URL = "user/me";

export function login(username, password, Latitude, Longitude) {
  return api.post(LOGIN_URL, { username, password, Latitude, Longitude })
    .then( (response) => {
      if (response.data.body.accessToken) {
        localStorage.setItem("accessToken", response.data.body.accessToken);
        localStorage.setItem("user", JSON.stringify(response.data.body));
      }
      return response.data.body;
    } );
}

export function register(email, fullname, username, password) {
  return api.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return api.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  return JSON.parse(localStorage.getItem('user'));
  //return api.get(ME_URL).then(response => {
    //return response.data.body;
  //});
}