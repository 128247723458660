/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { numberFormat } from "../../../../functions";

export function StatsRanking( { className, ranking, startDate, endDate} ) {
  let series = [];
  let categories = [];
  let amounts = [];

  if (ranking && ranking.length > 0) {
    ranking.map( (data) => {  //console.log( "---- ARRAY RANKING ---->>>>>", data.TotalAmount );
      series.push( data.SalesTotal );
      categories.push( data.SlpName );
      amounts.push( parseFloat( data.TotalAmount ) );

    } );

  }
  useEffect( () => {
    const element = document.getElementById( "kt_stats_ranking" );
    if (!element) {
      return;
    }

    const options = {
      series: [
        {
          name: "Ranking de Asesores",
          data: series,
          amounts: amounts
        },
      ],
      tooltip: {
        enabled: true,
        custom: function( { series, seriesIndex, dataPointIndex, w } ) { //console.log( "----- INDICE ---->>>>>", w );
          return '<div style="padding:5px"><div style="background: rgba(200,225,250,0.5); padding:15px">' + 
                  w.config.xaxis.categories[ dataPointIndex ] + '<br /><span style="font-size:10px">' + 
                  w.config.series[ 0 ].name + '</span><br /><hr>' +
            '<li> Contratos: ' + series[ seriesIndex ][ dataPointIndex ] + '</li>' +
            '<li> Monto ' + numberFormat(w.config.series[ 0 ].amounts[ dataPointIndex ]) + '</li>' +
            '</div></div>';
        }
    
      },
      chart: {
        type: "bar",
        maxHeight: "fit-content",
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (value, series, data) { //console.log( "---- DATA LABELS ---->>>>", series, series.w.config.series[0].amounts[ series.dataPointIndex ] );
          return value;
        },
        offsetX:  30,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ["#304758"]
        }
      },
      legend: {
        show: true,
      },
      stroke: {
        curve: 'straight'
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      xaxis: {
        categories: categories,
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
    };
    try {
      if ( ranking) {
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
          chart.destroy();
        };
      }
    } catch {}
  }, [
    ranking
  ]);



  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
        <div className="d-flex flex-row justify-content-between mr-2" style={{width: "100%"}}>
            <span
              className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5"
            >
              Ranking asesores con mas ventas Leader
            </span>
            <span className="text-dark-75 text-hover-primary">
              Del {moment(startDate).format("DD/MM/yyyy")} al {moment(endDate).format("DD/MM/yyyy")}
            </span>
            </div>
          
        </div>
        <div className="flex-grow-1">
          {ranking ? (
            <div
              id="kt_stats_ranking"
              className="card-rounded-bottom"
              style={{ height: "230px" }}
            ></div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
