/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { numberFormat } from "../../../../functions";
import moment from "moment";

export function StatsCash({ className, cash, startDate,
  endDate}) {

let series = [];
let categories = [];
if (cash && cash.length > 0) {
  cash.map((data) => {
    series.push(data.DownPaymentTotal);
    categories.push(data.SalesDay);
  })
}
  useEffect(() => {
    const element = document.getElementById("kt_stats_cash");
    if (!element) {
      return;
    }

    const options = {
        series: [
            {
                name: "Variación",
                data: series,
              },
          ],
            chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: false
            }
          },
          colors: ['#77B6EA', '#545454'],
          dataLabels: {
            enabled: true,
            formatter: function (val) {
                return numberFormat(val);
              },
          },
          stroke: {
            curve: 'smooth'
          },
          
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 1
          },
          xaxis: {
            categories: categories,
           
          },
          yaxis: {
           
            labels: {
              formatter: function (val) {
                return numberFormat(val);
              }
            }
          
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              gradientToColors: [ '#FDD835'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100]
            },
          },
         
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
          }
          };
    try {
      if ( cash) {
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
          chart.destroy();
        };
      }
    } catch {}
  }, [
    cash
  ]);



  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
        <div className="d-flex flex-row justify-content-between mr-2" style={{width: "100%"}}>
           <span
              className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5"
            >
              Variación flujo de efectivo (Enganches)
            </span>
            <span className="text-dark-75 text-hover-primary">
              Del {moment(startDate).format("DD/MM/yyyy")} al {moment(endDate).format("DD/MM/yyyy")}
            </span>
            </div>
          
        </div>
        <div className="flex-grow-1">
          {cash ? (
            <div
              id="kt_stats_cash"
              className="card-rounded-bottom"
              style={{ height: "230px" }}
            ></div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
