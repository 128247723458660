/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import ApexCharts from "apexcharts";
import { format, parseISO } from "date-fns";

export function StatsDonutProspect({
  className,
  activitiesStatus,
}) {
  let series = [];
  let categories = [];
  if (activitiesStatus) {
    let payload = {
      "Atendidos" : activitiesStatus?.ProspectAttending ?? 0,
      "No atendidos": activitiesStatus?.ProspectUnattended ?? 0,   
    }
    series = Object.values(payload);
    categories = Object.keys(payload);
  }

  useEffect(() => {
    const element = document.getElementById("kt_stats_widget_chart_donut_prospect");

    if (!element) {
      return;
    }

    const options = {
      series: series,
      plotOptions: {
        pie: {
          customScale: 0.8,
          donut: {
            labels: {
              show: true,
            }
          },
        },
      },
      labels: categories,
      colors: ["#008ffb", "#ff4560", "#feaf1a", "#00e495"],
      chart: {
        type: "donut",
      },
      legend: {
        position: 'bottom',
        onItemClick: {
          toggleDataSeries: false
      },
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      
    };

    try {
      if (activitiesStatus) {
        const chartActivities = new ApexCharts(element, options);
        chartActivities.render();
        return function cleanUp() {
          chartActivities.destroy();
        };
      }
    } catch {}
  }, [activitiesStatus]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column p-0">
        <div className="d-flex align-items-start justify-content-between card-spacer ">
          <div className="d-flex flex-column mr-2">
            <span
              className="text-dark-75 font-weight-bolder font-size-h5"
            >
              Histórico atención de prospectos 
            </span>
            <span
              className="text-primary font-size-h6"
            >Del {activitiesStatus.ProspectDate ? format(parseISO(activitiesStatus.ProspectDate), "dd/MM/yyyy") : ""} al {format(new Date(), "dd/MM/yyyy")}</span>
          </div>
          <span className="symbol symbol-light-primary symbol-45">
            <span className="symbol-label font-weight-bolder font-size-h6">
              {activitiesStatus?.Prospects ?? 0}
            </span>
          </span>
        </div>
        <>
          {activitiesStatus ? (
            <div
              id="kt_stats_widget_chart_donut_prospect"
              className="card-rounded-bottom"
            ></div>
          ) : (
            <></>
          )}
        </>
      </div>
    </div>
  );
}
