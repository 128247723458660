import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import CustomToolbar from "./CustomToolbar";
import { useSelector } from "react-redux";
import { KeyboardDatePicker } from "@material-ui/pickers";
import CustomFooter from "./CustomFooter";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import DateRangePickerCustom from "./DateRangePickerCustom";
import CustomWeekPicker from "./CustomWeekPicker";
import clsx from 'clsx';

/*  #ffbe00 Naranja
 #fd007a Rosa
 #ff9603 El otro naranja mas fuerte
 #00a9fc Azul chicle
 #00e0b3 Verde fosfo */

function CustomTable(props) {
  const { user } = useSelector((state) => state.auth);
  const [count, setCount] = useState(0);
  const {
    data,
    columns,
    title,
    handleActionClick,
    actionAdd,
    customOptions,
    handleCallback,
    handleDateChange,
    startDate,
    endDate,
    dateRange,
    weekPicker,
    picker,
    hideFooter,
    setStartDate,
    handleTableChange,
    draggableColumns,
    getTrProps,
    setRowProps
  } = props;
  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          paper: {
            boxShadow: "none",
          },
        },
        MuiTableCell: {
          root: {
            /* '&:hover': {backgroundColor: 'cian'}, */
            padding: "1rem",
          },
        },
        MUIDataTableToolbar: {
          titleRoot: {
            "& > h6": {
              fontSize: "1.5rem",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: "400",
              lineHeight: "1.334",
              letterSpacing: "0em",
            },
          },
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: "#ffffff",
          },
        },
        MuiTableRow: {
          root: {
            "&.MuiTableRow-hover": {
              "&:hover": {
                backgroundColor: "yellow"
              }
            }
 
            /* '&:hover': {
            backgroundColor: 'yellow !important'
          } */
        }

        },

      },
    });

  const options = {
    filter: true,
    fixedHeader: true,
    fixedSelectColumn: true,
    tableLayout: "auto",
    selectableRows: "none",
    filterType: "multiselect",
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
    selectableRowsHeader: true,
    selectableRowsHideCheckboxes: false,
    selectToolbarPlacement: 'above',
    customToolbar: () => {
      return (
        <>
          {actionAdd && actionAdd === true ? (
            <CustomToolbar handleClick={handleActionClick} />
          ) : (
            <></>
          )}
          {dateRange && dateRange === true ? (
            <DateRangePickerCustom
              handleCallback={handleCallback}
              startDate={startDate}
              endDate={endDate}
            />
          ) : (
            <></>
          )}
          {weekPicker && weekPicker === true ? (
            <CustomWeekPicker
            setStartDate={setStartDate} />
          ) : (
            <></>
          )}
           {picker && picker === true ? (
           <KeyboardDatePicker
           autoOk
           variant="inline"
           inputVariant="outlined"
           format="MM/yyyy"
           InputAdornmentProps={{ position: "end" }}
           onChange={(date) => handleDateChange(date)}
           disableFuture
           size="small"
           name="StarDate"
           value={startDate}
           minDate={new Date("1800")}
           maxDate={new Date()}
           views={["month", "year"]}           
         />
          ) : (
            <></>
          )}
        </>
      );
    },
    search: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    download: (user.RoleId < 6 || user.EmployeeId === 6077) ? true : false,
    downloadOptions: {
      filename: `${title}.csv`,
      separator: ",",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    print: user.RoleId < 5 ? true : false,
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      setCount(count);
      if(hideFooter){
        return <></>
      }else{
        return (
        <CustomFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
        />
      );
      }
      
    },
    textLabels: {
      body: {
        noMatch: "No hay coincidencias",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },

      pagination: {
        next: "Siguiente página",
        previous: "Página anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver Columnas",
        filterTable: "Filtar Tabla",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESTABLECER",
      },
      viewColumns: {
        title: "Mostrar columnas",
        titleAria: "Mostrar/Ocultar columnas de la tabla",
      },
      selectedRows: {
        text: " Fila(s) seleccionada(s)",
        delete: "Eliminar",
        deleteAria: "Filas eliminadas",
      },
    },
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'testClass123',
      };
    },


    /* setRowProps: (row, dataIndex, rowIndex) => { console.log( "VERIFICANDO COLOR DE LINEA ....( 233 )" );
      return {
        style: { 
          'background': (rowIndex % 2 === 0 ? 'lightblue' : '#f5f5f5'), 
          "font": (rowIndex % 2 === 0 ? 'italic small-caps bold 12px/30px Georgia, serif' : ''),
          'border': (rowIndex % 2 === 0 ? '2px solid blue' : ''),
          
        },
      };
    } */


  };

  let fullOptions = { ...options };
  // SI trae handleTableChange agrega el onTableChange en options.
  if (handleTableChange) {
    fullOptions = { ...fullOptions, onTableChange: (action, tableState) => {
                      switch (action) {
                        case 'filterChange':
                          handleTableChange( action, tableState );
                          break;
                      }
              }
      };
  }

  if (setRowProps) {
    fullOptions = { ...fullOptions, setRowProps: setRowProps };

  }

  //console.log( "VERIFICANDO que esta configurando el MUI DATATABLE", draggableColumns );
  // Columnas Draggables.
  if (draggableColumns) {
    fullOptions = { ...fullOptions, draggableColumns: true };
    //console.log( "COLUMNAS DRAGABLES ------>>>>>", fullOptions );
  }

  if (customOptions) {
    fullOptions = { ...fullOptions, ...customOptions };
  }

  return (
    <React.Fragment>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={title.length > 0 ? `${title} (${count})`: ""}
          data={data}
          columns={columns}
          options={fullOptions}
          actionAdd={actionAdd}
          getTrProps={getTrProps}
        />
      </MuiThemeProvider>
    </React.Fragment>
  );
}

export default CustomTable;
