import React from "react";
import api from "../../../api";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { AdvanceTablesActivities, ListsWidgetCarousel } from "../widgets";

import CustomDatePicker from "../../../app/components/CustomDatePicker";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "auto",
    margin: "1rem",
    maxHeight: "45vh",
    display: "inline-block",
    "&:hover, &:focus": {
      overflow: "auto",
      "&::-webkit-scrollbar": {
        display: "inline-block",
      },
    },
    "&::-webkit-scrollbar": {
      width: "0.5rem",
      height: "0.5rem",
      display: "inline-block",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#ccc",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#b3b3b3",
      boxShadow: "0 0 2px 1px rgba(0, 0, 0, 0.2)",
    },
    "&::-webkit-scrollbar-thumb:active": {
      backgroundColor: "#999999",
    },
    "&::-webkit-scrollbar-track": {
      background: "#e1e1e1",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track:hover": {
      background: "#d4d4d4",
    },
    "&::-webkit-scrollbar-track:active": {
      background: "#d4d4d4",
    },
  },
  bgDangerExpired: {
    borderLeft: "8px solid red",
  },
}));

export default function ActivitiesDashboard(props) {

  const fetchActivitiesTotales = async (startDate, endDate) => {
    return await api
      .get(
        `activity/activities-by-type?InitialActivityDate=${format(
          new Date(startDate),
          "yyyy/MM/dd"
        )}&FinalActivityDate=${format(
          new Date(endDate),
          "yyyy/MM/dd"
        )}`
      )
      .then(
        (result) => {
          if (result.data.status === 200) {
            return result.data.body;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12 col-lg-12 col-xxl-12 order-1 order-xxl-1">
          <ListsWidgetCarousel className="card-stretch gutter-b" />
        </div>
        <div className="col-md-12 col-lg-12 col-xxl-12 order-2 order-xxl-2">
          <AdvanceTablesActivities className="card-stretch gutter-b" />
        </div>
      </div>
      <CustomDatePicker
        fetchActivitiesTotales={fetchActivitiesTotales}
      />
    </>
  );
}
