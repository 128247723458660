/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import api from "../../../../../api";

export function QuickUser() {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const [currentUser, setCurrentUSer] = useState({});

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
  };

  const handleAvailable = () => {
    api.get("user/carousel/on").then((result) => {
      if (result.status === 201) {
        setCurrentUSer(result.data.body);
      }
    });
  };

  useEffect(() => {
    api.get("user/me").then((result) => {
      if (result.status === 200) {
        setCurrentUSer(result.data.body);
      }
    });
  }, []);

  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        {/* <h3 className="font-weight-bold m-0">
            Perfil
            <small className="text-muted font-size-sm ml-2">0 mensajes</small>
          </h3> */}
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5 symbol-light-success">
            <span className="symbol-label font-size-h1 font-weight-bold">
              {user.Employee.FirstName[0]}
            </span>
            <i className="symbol-badge bg-success" />
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {user.Employee.FirstName}
            </a>
            <div className="text-muted mt-1">{user.Role.Name}</div>
            <div className="navi mt-2">
              {user.Employee.Email && (
                <a href="#" className="navi-item">
                  <span className="navi-link p-0 pb-2">
                    <span className="navi-icon mr-1">
                      <span className="svg-icon-lg svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail-notification.svg"
                          )}
                        ></SVG>
                      </span>
                    </span>
                    <span className="navi-text text-muted text-hover-primary">
                      {user.Employee.Email}
                    </span>
                  </span>
                </a>
              )}
              {user.Employee.Mobile && (
                <a href="#" className="navi-item">
                  <span className="navi-link p-0 pb-2">
                    <span className="navi-icon mr-1">
                      <span className="svg-icon-lg svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Call.svg"
                          )}
                        ></SVG>
                      </span>
                    </span>
                    <span className="navi-text text-muted text-hover-primary">
                      {user.Employee.Mobile}
                    </span>
                  </span>
                </a>
              )}
            </div>
            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
            <button
              className="btn btn-light-primary btn-bold"
              onClick={logoutClick}
            >
              Salir
            </button>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />
        {user.TeamCarousel === 1 && (
          <>
          <div className="navi navi-spacer-x-0 p-0">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customSwitches"
                readOnly
                onChange={handleAvailable}
                checked={currentUser.CarouselOn === 1 ? true : false}
              />
              <label className="custom-control-label" htmlFor="customSwitches">
                Disponible para carrusel
              </label>
            </div>
          </div>
          <div className="separator separator-dashed mt-8 mb-5" />
          </>
        )}

        <div className="navi navi-spacer-x-0 pt-2">
          <div className="navi-link">
            <div className="navi-text">
              <div className="font-weight-bold">Empresa</div>
              <div className="text-muted">{user.Company.Name}</div>
            </div>
          </div>
        </div>

        <div className="navi navi-spacer-x-0 pt-2">
          <div className="navi-link">
            <div className="navi-text">
              <div className="font-weight-bold">Número de empleado</div>
              <div className="text-muted">{user.Employee.EmployeeId}</div>
            </div>
          </div>
        </div>

        <div className="navi navi-spacer-x-0 pt-2">
          <div className="navi-link">
            <div className="navi-text">
              <div className="font-weight-bold">Número vendedor</div>
              <div className="text-muted">{user.Employee.ExtEmpNo}</div>
            </div>
          </div>
        </div>
        <div className="navi navi-spacer-x-0 pt-2">
          <div className="navi-link">
            <div className="navi-text">
              <div className="font-weight-bold">Puesto</div>
              <div className="text-muted">{user.Employee.JobTitle}</div>
            </div>
          </div>
        </div>
        <div className="navi navi-spacer-x-0 pt-2">
          <div className="navi-link">
            <div className="navi-text">
              <div className="font-weight-bold">Línea de negocio</div>
              <div className="text-muted">
                {user.Employee.BusinessLine.Name}
              </div>
            </div>
          </div>
        </div>
        {/*
            <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Shopping/Chart-bar1.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">Estadísticas</div>
                  <div className="text-muted">Metas, ventas y más</div>
                </div>
              </div>
            </a>

            <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Files/Selected-file.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">Actividades</div>
                  <div className="text-muted">Lista de actividades</div>
                </div>
              </div>
            </a>
          </div>

        {/*  <div className="separator separator-dashed my-7"></div>
          
          <div>
            <h5 className="mb-5">Notificaciónes</h5>
            <div className="d-flex align-items-center bg-light-danger rounded p-5 gutter-b">
              <span className="svg-icon svg-icon-danger mr-5">
                <SVG
                    src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Group-chat.svg"
                    )}
                    className="svg-icon svg-icon-lg"
                ></SVG>
              </span>
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <a
                      href="#"
                      className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1"
                  >
                    Purpose would be to persuade
                  </a>
                  <span className="text-muted font-size-sm">Due in 2 Days</span>
                </div>

                <span className="font-weight-bolder text-danger py-1 font-size-lg">
                -27%
              </span>
            </div>
          </div> */}
      </div>
    </div>
  );
}
