import React from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TodayIcon from "@material-ui/icons/Today";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import CallIcon from "@material-ui/icons/Call";
import Typography from "@material-ui/core/Typography";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { format, parseISO } from "date-fns";

export default function ActivityTimeLine(props) {
  const { title, activities } = props;

  return (
    <div>
      <Typography variant="h5" color="textSecondary">
        {title}
      </Typography>
      <Timeline align="alternate">
        {activities && activities.length > 0 ? (
          activities.map((activity, index) => (
            <TimelineItem key={`activity-${index}`}>
              <TimelineOppositeContent>
                <Typography variant="body1" color="textSecondary">
                  {format(parseISO(activity.ActivityDateFullDate), "dd/MM/yyyy")}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <b>{`${activity.StartTime} - ${activity.EndTime} `}</b>
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                {activity.ActivityTypeId === 1 ? (
                  <TimelineDot
                    style={{ borderColor: "#f41835" }}
                    variant="outlined"
                  >
                    <TodayIcon color="error" />
                  </TimelineDot>
                ) : activity.ActivityTypeId === 2 ? (
                  <TimelineDot color="primary" variant="outlined">
                    <CallIcon color="primary" />
                  </TimelineDot>
                ) : activity.ActivityTypeId === 3 ? (
                  <TimelineDot color="action" variant="outlined">
                    <MailOutlineIcon />
                  </TimelineDot>
                ) : activity.ActivityTypeId === 4 ? (
                  <TimelineDot
                    style={{ borderColor: "#0ACC96" }}
                    variant="outlined"
                  >
                    <WhatsAppIcon style={{ color: "#0ACC96" }} />
                  </TimelineDot>
                ) : (
                  <TimelineDot
                    style={{ borderColor: "#0ACC96" }}
                    variant="outlined"
                  >
                    <ChatOutlinedIcon style={{ color: "#0ACC96" }} />
                  </TimelineDot>
                )}
  
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="caption" color="textSecondary" component="p">
                  {activity.ProspectName}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  component="p"
                >
                  {activity.ActivitySubject}
                </Typography>
                {activity.OnTime === 1 ? (
                  <span className="label label-lg label-light-success label-inline">
                    A tiempo
                  </span>
                ) : activity.OnTime  === 0 ? (
                  <span className="label label-lg label-light-danger label-inline">
                    Atrasado
                  </span>
                ) : (
                  <></>
                )}
              </TimelineContent>
            </TimelineItem>
          ))

        ) : <Typography variant="body1" color="textSecondary">
        <b>Sin actividades para este día</b>
      </Typography>}
        
      </Timeline>
    </div>
  );
}
