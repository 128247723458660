import { createMuiTheme } from "@material-ui/core/styles";

export const style = createMuiTheme({
  palette: {
    text:{
      discount: "#00F200"
    },
    primary: {
      main: "#00a9fc",
    },
    secondary: {
      main: "#1b1b28",
    },
    error: {
      main: "#f41835",
    },
    naranjaIcon: {
      main: "#ff9603"
    }
  },
  overrides: {
    MuiStepIcon:{
      root: {
        width: "1.2em",
        height: "1.2em"
      },
      text:{ fill: "#ffffff"}
    },
    MuiCardHeader: {
      root: {
      },
      avatar: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      },
    },
    MuiAvatar: {
      root:{
        width:"60px",
        height: "60px"
      }
    },
    MuiCardActions:{
      root: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
      }
    },
    MuiTypography: {
      root: {
        padding: "3px",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
        marginTop: "8px",

      },
    },
    // Style sheet name ⚛️
    MuiButton: {
      root: {},
      containedPrimary: {
        color: "#ffffff",
      },
    },
    MuiButtonBase: {
      root: {
        background: {
          main: "#FE6B8B",
        },
      },
    },
    MuiDivider: {
      root: {
        margin: "6px 0px",
      },
    },
    MuiFormGroup: {
      root: {
        flexDirection: "row",        

      }
    },
    MuiInputBase: {
      root: {

      }
    },
    MuiOutlinedInput:{
      root: {

      },
      input:{
       
      }
    },
    MuiAutocomplete: {
      root: {
      },
      inputRoot: {

      }
    },
    stepBody: {
      minHeight: "60vh"
    },
  },
});