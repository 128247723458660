import React, { useState, useEffect } from "react";

import moment from "moment";
import api from "../../../api";
import { useSelector } from "react-redux";
import { numberFormat } from "../../../functions";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../_helpers";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {makeStyles} from "@material-ui/core/styles";
import {Popover, Button, Typography} from "@material-ui/core";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Clear } from "@material-ui/icons";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SalesDashboard(props) {
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
  const [salesAdvisors, setSalesAdvisors] = useState([]);
  const [salesReport, setSalesReport] = useState({});

  const [advisers, setAdvisers] = useState([]);
  const [assistantManagers, setAssistantManagers] = useState([]);
  const [assistantManagersId, setAssistantManagersId] = useState([]);
  const [managements, setManagements] = useState([]);
  const [managementsId, setManagementsId] = useState([]);
  const [businessLine, setBusinessLine] = useState({});

  const [socialsWeb, setSocialsWeb] = useState({});
  const [socialWebId, setSocialWebId] = useState({});
  const [socialWebName, setSocialWebName] = useState("");
  const { user } = useSelector((state) => state.auth);

  const businessLines = [
    {
      Code: "BF",
      Name: "Bye Bye Friend",
    },
    {
      Code: "PF",
      Name: "Previsión Final",
    },
    {
      Code: "SG",
      Name: "Santa Gloria",
    },
  ];

  const fetchAssistantManagers = () => {
    api.get("catalog/assistant-managers").then(
      (result) => {
        setAssistantManagers(result.data.body);
      },
      (error) => {
        setAssistantManagers([]);
      }
    );
  };

  const fetchManagements = () => {
    let assistantManagers = assistantManagersId.map(item => item.AssistantManagerId);
    api.get("catalog/managements", {
      params: {
        AssistantManagers: assistantManagers,
      },
    }).then(
      (result) => {
        setManagements(result.data.body);
      },
      (error) => {
        setManagements([]);
      }
    );
  }

  const fetchAdvisers = () => {
    let managements = managementsId.map(item => item.ManagementId);
    api.get("catalog/advisers", {
        params: {
          Management: managements,
        },
      })
      .then(
        (result) => {
          setAdvisers(result.data.body);
        },
        (error) => {
          setAdvisers([]);
        }
      );
  }

  const fetchData = (start, end) => {
    let ids = salesAdvisors.map(item => item.SlpCode);
    let managements = managementsId.map(item => item.ManagementId);
    let assistantManagers = assistantManagersId.map(item => item.AssistantManagerId);

    let payload = {
      BusinessLine: businessLine && businessLine.Code ? businessLine.Code : "*",
      SocialWeb: socialWebId ? socialWebId : 0,
      StartDate: moment(start).format("yyyy/MM/DD"),
      EndDate: moment(end).format("yyyy/MM/DD"),
      SlpCode: ids,
      Management: managements,
      AssistantManagers: assistantManagers,
    };

    /* return api
      .get( user && user.Id !== 2 ? `dashboard/get-financial` : `dashboard/get-financial-v2`, { */
    return api
      .get( `dashboard/get-financial-v2`, {
          params: payload,
      })
      .then(
        (result) => {
          if (result.status === 201) {
            setSalesReport( result.data.body );
          } else {
            setSalesReport( {} );
          }
        },
        (error) => {
          setSalesReport( {} );
          console.log(error);
        }
      );
  }


  const fetchSocialsWeb = (start) => {
    api.get("dashboard/financial-socialweb", {
        params: {
          BusinessLine: businessLine && businessLine.Code ? businessLine.Code : "",
          StartDate: moment( start ).format( "yyyy/MM/DD" ),
        },
      })
      .then(
        (result) => {
          setSocialsWeb( result.data.body );
        },
        (error) => {
          setSocialsWeb( [] );
        }
      );
      setSocialWebId( undefined );
      setSocialWebName( "" );
  }




  const handleBusinessLineChange = (event, value) => {
    if (value !== null) {      
      setBusinessLine( value );

    } else {
      setBusinessLine( undefined );

    }
    setSocialsWeb( [] );
  }

  const handleAssistantManagerChange = (event, value) => {
    if (value !== null) {      
      setAssistantManagersId(value);
      setManagementsId([]);
      setSalesAdvisors([]);
    } else {
      setAssistantManagersId(undefined);
    }
  }

  const handleManagementChange = (event, value) => {
    if (value !== null) {      
      setManagementsId(value);
      setSalesAdvisors([])
    } else {
      setManagementsId(undefined);
    }
  }

  const handleAdviserChange = (event, value) => {
    if (value !== null) {
      setSalesAdvisors(value);
    } else {
      setSalesAdvisors(undefined);
    }
  }


  const handleSocialsWebChange = async (event, value) => {
    if (value !== null) {      
      await setSocialWebId( value.ProspectionMeansId );
      await setSocialWebName( value.Name );

    } else {
      /* setSocialsWeb( [] ); */
      await setSocialWebId( undefined );
      await setSocialWebName( "" );

    }
  }


  const handleCallback = async ( start ) => {
    await setStartDate(start);
    await fetchData( start, start );
  }

  useEffect(() => {
    fetchAssistantManagers();
  }, []);

  useEffect(() => {
    fetchManagements();
  }, [assistantManagersId]);

  useEffect(() => {
    fetchAdvisers();
  }, [managementsId]);

  useEffect(() => {
    fetchData(startDate, endDate);
  }, [salesAdvisors, managementsId, assistantManagersId,socialWebId]);

  useEffect( () => {
    fetchSocialsWeb( startDate );
  }, [businessLine]);



  const DM_FACEBOOK = "FACEBOOK";
  const DM_GOOGLE = "GOOGLE";
  const DM_JGL = "PAGINA_JGL";

  /* Detalle Leads Facebook */
  const useStyles1 = makeStyles( theme => ( {
    popover: {
      pointerEvents: "none"    },
    paper: {
      padding: theme.spacing( 1 ),
      background: "rgb( 256, 254, 190 )"

    }
  }));
  
  const classes1 = useStyles1();
  const [anchorEl1, setAnchorEl1] = React.useState( null );
  function handlePopoverOpen1( event ) {
    setAnchorEl1( event.currentTarget );
  }
  function handlePopoverClose1() {
    setAnchorEl1( null );
  }
  const open1 = Boolean( anchorEl1 );


  /* Detalle Leads Google */
  const useStyles2 = makeStyles( theme => ( {
    popover: {
      pointerEvents: "none"
    },
    paper: {
      padding: theme.spacing( 1 )
    }
  }));
    const classes2 = useStyles2();
  const [anchorEl2, setAnchorEl2] = React.useState(null);
    function handlePopoverOpen2(event) {
    setAnchorEl2(event.currentTarget);
  }
    function handlePopoverClose2() {
    setAnchorEl2(null);
  }
  const open2 = Boolean(anchorEl2);

  /* Detalle Leads Pagina WEB */
  const useStyles3 = makeStyles( theme => ( {
    popover: {
      pointerEvents: "none"
    },
    paper: {
      padding: theme.spacing( 1 )
    }
  }));
    const classes3 = useStyles3();
  const [anchorEl3, setAnchorEl3] = React.useState( null );
  function handlePopoverOpen3( event ) {
    setAnchorEl3( event.currentTarget );
  }
  function handlePopoverClose3() {
    setAnchorEl3( null );
  }
  const open3 = Boolean( anchorEl3 );



  const [ anchorEl_CampaignCost, setAnchorEl_CampaignCost ] = React.useState( null );
  function handlePopoverOpen_CampaignCost( event ) {
    setAnchorEl_CampaignCost( event.currentTarget );
  }
  function handlePopoverClose_CampaignCost() {
    setAnchorEl_CampaignCost( null );
  }
  const open_CampaignCost = Boolean( anchorEl_CampaignCost );

  const [ anchorEl_ExercisedBudget, setAnchorEl_ExercisedBudget ] = React.useState( null );
  function handlePopoverOpen_ExercisedBudget( event ) {
    setAnchorEl_ExercisedBudget( event.currentTarget );
  }
  function handlePopoverClose_ExercisedBudget() {
    setAnchorEl_ExercisedBudget( null );
  }
  const open_ExercisedBudget = Boolean( anchorEl_ExercisedBudget );

  const [ anchorEl_LeadCost, setAnchorEl_LeadCost ] = React.useState( null );
  function handlePopoverOpen_LeadCost( event ) {
    setAnchorEl_LeadCost( event.currentTarget );
  }
  function handlePopoverClose_LeadCost() {
    setAnchorEl_LeadCost( null );
  }
  const open_LeadCost = Boolean( anchorEl_LeadCost );

  const [ anchorEl_ROI, setAnchorEl_ROI ] = React.useState( null );
  function handlePopoverOpen_ROI( event ) {
    setAnchorEl_ROI( event.currentTarget );
  }
  function handlePopoverClose_ROI() {
    setAnchorEl_ROI( null );
  }
  const open_ROI = Boolean( anchorEl_ROI );


  const [ anchorEl_TotalLeads, setAnchorEl_TotalLeads ] = React.useState( null );
  function handlePopoverOpen_TotalLeads( event ) {
    setAnchorEl_TotalLeads( event.currentTarget );
  }
  function handlePopoverClose_TotalLeads() {
    setAnchorEl_TotalLeads( null );
  }
  const open_TotalLeads = Boolean( anchorEl_TotalLeads );

  const [ anchorEl_SalesTotal, setAnchorEl_SalesTotal ] = React.useState( null );
  function handlePopoverOpen_SalesTotal( event ) {
    setAnchorEl_SalesTotal( event.currentTarget );
  }
  function handlePopoverClose_SalesTotal() {
    setAnchorEl_SalesTotal( null );
  }
  const open_SalesTotal = Boolean( anchorEl_SalesTotal );

  const [ anchorEl_SalesContract, setAnchorEl_SalesContract ] = React.useState( null );
  function handlePopoverOpen_SalesContract( event ) {
    setAnchorEl_SalesContract( event.currentTarget );
  }
  function handlePopoverClose_SalesContract() {
    setAnchorEl_SalesContract( null );
  }
  const open_SalesContract = Boolean( anchorEl_SalesContract );

  const [ anchorEl_Conversion, setAnchorEl_Conversion ] = React.useState( null );
  function handlePopoverOpen_Conversion( event ) {
    setAnchorEl_Conversion( event.currentTarget );
  }
  function handlePopoverClose_Conversion() {
    setAnchorEl_Conversion( null );
  }
  const open_Conversion = Boolean( anchorEl_Conversion );

  const [ anchorEl_AverageTime, setAnchorEl_AverageTime ] = React.useState( null );
  function handlePopoverOpen_AverageTime( event ) {
    setAnchorEl_AverageTime( event.currentTarget );
  }
  function handlePopoverClose_AverageTime() {
    setAnchorEl_AverageTime( null );
  }
  const open_AverageTime = Boolean( anchorEl_AverageTime );

  const [ anchorEl_AverageDaysSales, setAnchorEl_AverageDaysSales ] = React.useState( null );
  function handlePopoverOpen_AverageDaysSales( event ) {
    setAnchorEl_AverageDaysSales( event.currentTarget );
  }
  function handlePopoverClose_AverageDaysSales() {
    setAnchorEl_AverageDaysSales( null );
  }
  const open_AverageDaysSales = Boolean( anchorEl_AverageDaysSales );
  

  const useStyles = makeStyles( (theme) => ( {
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    customWidth: {
      width: "100%" /* "max-content" */
    }
  } ) );
  const classes = useStyles();

  return (
    <>
      <div className="card">
        <div className="card-header border-0 pt-5 bg-light">
          <div className="row align-items-center">
            <div className="col-xs-12 col-sm-6">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Financiero del mes
                </span>
              </h3>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-xs-12 col-sm-6">
              <KeyboardDatePicker
                fullWidth
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="FINANCIERO DEL "
                format="MM/yyyy"
                InputAdornmentProps={ { position: "end" } }
                onChange={ (date) => handleCallback( date ) }
                value={startDate}
                minDate={new Date( 2021 )}
                maxDate={ new Date() }
                views={ [ "month", "year" ] }
              />
            </div>
          </div>
        </div>

{/*         { user && user.Id !== 2
        ?
        <div className="card-body">
          <div className="row">
            <div className="col-md-9 col-lg-9 col-xxl-8 mb-7 bg-dark rounded-xl py-5">

              <div className="row m-0">
                <div className="col bg-dark px-6 py-8 rounded-xl mr-4 mb-4 text-center">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder text-white">
                      { salesReport.PeriodBudget }
                    </span>
                  </h3>
                </div>
                <div className="col bg-white px-6 py-8 rounded-xl mr-4 mb-4 text-center">
                  <img className="min-w-50px max-h-50px" src="media/logos/facebook-lg.png" />
                </div>
                <div className="col bg-white px-6 py-8 rounded-xl mr-4 mb-4 text-center">
                  <img className="min-w-50px max-h-50px" src="media/logos/google-lg.png" />
                </div>
                <div className="col bg-white px-6 py-8 rounded-xl mr-4 mb-4 text-center">
                  <img className="min-w-50px max-h-50px" src="media/logos/jgl-lg.png" />
                </div>
              </div>

              <div className="row m-0">
                <br></br>
              </div>

              <div className="row m-0">
                <div className="col bg-light-light px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl( "/media/svg/icons/Communication/Group.svg" )} />
                  </span>
                  <br></br>
                  <span className="text-body font-weight-bold font-size-h6">
                    Total de LEADs
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    {salesReport.DigitalLeads && salesReport.DigitalLeads.length > 0 && salesReport.DigitalLeads[ 0 ].TotalLeads ? salesReport.DigitalLeads[ 0 ].TotalLeads : 0}
                  </span>
                  { salesReport.Details && salesReport.Details.filter( (det) => det.DigitalGroup === DM_FACEBOOK ).length > 0 ?
                    <div>
                      <Typography
                          aria-owns={open1 ? "mouse-over-popover" : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handlePopoverOpen1}
                          onMouseLeave={handlePopoverClose1}
                      >
                        Detalle LEADs ...
                      </Typography>
                      <Popover
                          id="mouse-over-popover"
                          className={classes1.popover}
                          classes={{
                              paper: classes1.paper
                          }}
                          open={open1}
                          anchorEl={anchorEl1}
                          anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left"
                          }}
                          transformOrigin={{
                              vertical: "top",
                              horizontal: "left"
                          }}
                          onClose={handlePopoverClose1}
                          disableRestoreFocus
                      >
                        <Typography>
                          { salesReport.Details ? salesReport.Details.map( digitalItem => (digitalItem.DigitalGroup === DM_FACEBOOK ? <div className="col bg-light-info px-2 py-2 rounded-xl mr-4 mb-4"> <span className="text-body font-weight-bold font-size-h6"> {digitalItem.Name} </span> <span className="text-body font-weight-bold font-size-h3">{digitalItem.Leads} <br/> </span> </div>: <></>) ) : <></> }
                        </Typography>
                      </Popover>
                    </div>
                  :
                  <></>
                }
                </div>

                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    {salesReport.DigitalLeads && salesReport.DigitalLeads[ 1 ].TotalLeads ? salesReport.DigitalLeads[ 1 ].TotalLeads : 0}
                  </span>
                  { salesReport.Details && salesReport.Details.filter( (det) => det.DigitalGroup === DM_GOOGLE ).length > 0 ?
                    <div>
                      <Typography
                          aria-owns={open2 ? "mouse-over-popover" : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handlePopoverOpen2}
                          onMouseLeave={handlePopoverClose2}
                      >
                        Detalle LEADs ...
                      </Typography>
                      <Popover
                          id="mouse-over-popover"
                          className={classes2.popover}
                          classes={{
                              paper: classes2.paper
                          }}
                          open={open2}
                          anchorEl={anchorEl2}
                          anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left"
                          }}
                          transformOrigin={{
                              vertical: "top",
                              horizontal: "left"
                          }}
                          onClose={handlePopoverClose2}
                          disableRestoreFocus
                      >
                        <Typography>
                          { salesReport.Details ? salesReport.Details.map( digitalItem => (digitalItem.DigitalGroup === DM_GOOGLE ? <div className="col bg-light-info px-2 py-2 rounded-xl mr-4 mb-4"> <span className="text-body font-weight-bold font-size-h6"> {digitalItem.Name} </span> <span className="text-body font-weight-bold font-size-h3">{digitalItem.Leads} <br/> </span> </div>: <></>) ) : <></> }
                        </Typography>
                      </Popover>
                    </div>
                  :
                    <></>
                  }
                </div>

                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    {salesReport.DigitalLeads && salesReport.DigitalLeads[ 2 ].TotalLeads ? salesReport.DigitalLeads[ 2 ].TotalLeads : 0}
                  </span>
                  { salesReport.Details && salesReport.Details.filter( (det) => det.DigitalGroup === DM_JGL ).length > 0 ?
                    <div>
                      <Typography
                          aria-owns={open3 ? "mouse-over-popover" : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handlePopoverOpen3}
                          onMouseLeave={handlePopoverClose3}
                      >
                        Detalle LEADs ...
                      </Typography>
                      <Popover
                          id="mouse-over-popover"
                          className={classes3.popover}
                          classes={{
                              paper: classes3.paper
                          }}
                          open={open3}
                          anchorEl={anchorEl3}
                          anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left"
                          }}
                          transformOrigin={{
                              vertical: "top",
                              horizontal: "left"
                          }}
                          onClose={handlePopoverClose3}
                          disableRestoreFocus
                      >
                        <Typography>
                          { salesReport.Details ? salesReport.Details.map( digitalItem => (digitalItem.DigitalGroup === DM_JGL ? <div className="col bg-light-info px-2 py-2 rounded-xl mr-4 mb-4"> <span className="text-body font-weight-bold font-size-h6"> {digitalItem.Name} </span> <span className="text-body font-weight-bold font-size-h3">{digitalItem.Leads} <br/> </span> </div>: <></>) ) : <></> }
                        </Typography>
                      </Popover>
                    </div>
                  :
                    <></>
                  }
                </div>
              </div>

              <div className="row m-0">
                <div className="col bg-light-light px-6 py-8 rounded-xl mr-4 mb-4">
                  <i className="flaticon-presentation-1 text-body display-4 text-body font-weight-bold"></i>
                  <br></br>
                  <span className="text-body font-weight-bold font-size-h6">
                    Total de VENTAS
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    {salesReport.DigitalLeads && salesReport.DigitalLeads[ 0 ].SalesTotal ? salesReport.DigitalLeads[ 0 ].SalesTotal : 0}
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    {salesReport.DigitalLeads && salesReport.DigitalLeads[ 1 ].SalesTotal ? salesReport.DigitalLeads[ 1 ].SalesTotal : 0}
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    {salesReport.DigitalLeads && salesReport.DigitalLeads[ 2 ].SalesTotal ? salesReport.DigitalLeads[ 2 ].SalesTotal : 0}
                  </span>
                </div>
              </div>

              <div className="row m-0">
                <div className="col bg-light-light px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl( "/media/svg/icons/Shopping/Calculator.svg" )} />
                  </span>
                  <br></br>
                  <span className="text-body font-weight-bold font-size-h6">
                    Conversión
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    { salesReport.DigitalLeads && salesReport.DigitalLeads[ 0 ].Conversion ? salesReport.DigitalLeads[ 0 ].Conversion.toFixed( 3 ) : 0 }%
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    { salesReport.DigitalLeads && salesReport.DigitalLeads[ 1 ].Conversion ? salesReport.DigitalLeads[ 1 ].Conversion.toFixed( 3 ) : 0 }%
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    { salesReport.DigitalLeads && salesReport.DigitalLeads[ 2 ].Conversion ? salesReport.DigitalLeads[ 2 ].Conversion.toFixed( 3 ) : 0 }%
                  </span>
                </div>
              </div>

              <div className="row m-0">
                <br></br><br></br><br></br>
              </div>

              <div className="row m-0">
                <div className="col bg-light-info px-6 py-8 rounded-xl mr-4 mb-4">
                  <i className="flaticon-coins display-4 font-weight-bold"></i><br></br>
                  <span className="text-body font-weight-bold font-size-h6">
                    Monto de VENTAS
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    {numberFormat( salesReport.DigitalLeads && salesReport.DigitalLeads[ 0 ].SalesAmount ? salesReport.DigitalLeads[ 0 ].SalesAmount : 0 )}
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    {numberFormat( salesReport.DigitalLeads && salesReport.DigitalLeads[ 1 ].SalesAmount ? salesReport.DigitalLeads[ 1 ].SalesAmount : 0 )}
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    {numberFormat( salesReport.DigitalLeads && salesReport.DigitalLeads[ 2 ].SalesAmount ? salesReport.DigitalLeads[ 2 ].SalesAmount : 0 )}
                  </span>
                </div>
              </div>

              <div className="row m-0">
                <div className="col bg-light-info px-6 py-8 rounded-xl mr-4 mb-4">
                  <i className="flaticon2-graphic display-4 font-weight-bold"></i>
                  <br></br>
                  <span className="text-body font-weight-bold font-size-h6">
                    Costo de CAMPAÑA
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    {numberFormat( salesReport.DigitalLeads && salesReport.DigitalLeads[ 0 ].CampaignCost ? salesReport.DigitalLeads[ 0 ].CampaignCost : 0 )}
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    {numberFormat( salesReport.DigitalLeads && salesReport.DigitalLeads[ 1 ].CampaignCost ? salesReport.DigitalLeads[ 1 ].CampaignCost : 0 )}
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    {numberFormat( salesReport.DigitalLeads && salesReport.DigitalLeads[ 2 ].CampaignCost ? salesReport.DigitalLeads[ 2 ].CampaignCost : 0 )}
                  </span>
                </div>
              </div>

              <div className="row m-0">
                <div className="col bg-light-info px-6 py-8 rounded-xl mr-4 mb-4">
                  <i className="flaticon2-graphic display-4 font-weight-bold"></i>
                  <br></br>
                  <span className="text-body font-weight-bold font-size-h6">
                    Costo por LEAD
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    {numberFormat( salesReport.DigitalLeads && salesReport.DigitalLeads[ 0 ].LeadCost ? salesReport.DigitalLeads[ 0 ].LeadCost : 0 )}
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    {numberFormat( salesReport.DigitalLeads && salesReport.DigitalLeads[ 1 ].LeadCost ? salesReport.DigitalLeads[ 1 ].LeadCost : 0 )}
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    {numberFormat( salesReport.DigitalLeads && salesReport.DigitalLeads[ 2 ].LeadCost ? salesReport.DigitalLeads[ 2 ].LeadCost : 0 )}
                  </span>
                </div>
              </div>

              <div className="row m-0">
                <div className="col bg-light-info px-6 py-8 rounded-xl mr-4 mb-4">
                  <i className="flaticon-piggy-bank text-info display-4 font-weight-bold"></i>
                  <span className="text-body font-weight-bold font-size-h6">
                    ROI
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    { salesReport.DigitalLeads && salesReport.DigitalLeads[ 0 ].ROI ? salesReport.DigitalLeads[ 0 ].ROI.toFixed( 3 ) : 0 }%
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    { salesReport.DigitalLeads && salesReport.DigitalLeads[ 1 ].ROI ? salesReport.DigitalLeads[ 1 ].ROI.toFixed( 3 ) : 0 }%
                  </span>
                </div>
                <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-4">
                  <span className="text-body font-weight-bolder font-size-h3">
                    { salesReport.DigitalLeads && salesReport.DigitalLeads[ 2 ].ROI ? salesReport.DigitalLeads[ 2 ].ROI.toFixed( 3 ) : 0 }%
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-xxl-4 mb-5 bg-light-danger rounded-xl py-5">
              <div className="row m-0">
                <div className="col px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h3 className="card-title font-weight-bolder my-0">
                    KPI's
                  </h3>
                </div>
              </div>
              <div className="row m-0">
                <div className="col bg-light-light px-6 py-8 rounded-xl mr-7 mb-7">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                    <i className="flaticon-piggy-bank text-body display-4 text-body font-weight-bold"></i>
                    <span className="text-body font-weight-bolder font-size-h3">
                       { salesReport.ROI ? salesReport.ROI.toFixed( 3 ) : 0 }%
                    </span>
                  </span>
                  <span className="text-body font-weight-bold font-size-h6">
                    ROI Total
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <div className="col bg-light-success px-6 py-8 rounded-xl mr-7 mb-7">
                  <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                    <i className="flaticon-stopwatch text-success  display-4 text-white font-weight-bold"></i>
                    <span className="text-success font-weight-bolder font-size-h3">
                      { salesReport.AverageTime ? salesReport.AverageTime.toFixed( 3 ) : 0 } mins.
                    </span>
                  </span>
                  <span className="text-success font-weight-bold font-size-h6 mt-2">
                    Tiempo Promedio de Respuesta
                  </span>
                </div>
              </div>

              <div className="row m-0">
                <div className="col bg-light-light px-6 py-8 rounded-xl mr-7 mb-7">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                    <i className="flaticon-diagram text-body display-4 text-body font-weight-bold"></i>
                    <span className="text-body font-weight-bolder font-size-h3">
                      { numberFormat( salesReport.AverageTicket ?? 0 ) }
                    </span>
                  </span>
                  <span className="text-body font-weight-bold font-size-h6">
                    TT Promedio
                  </span>
                </div>
              </div>
              <div className="row m-0">
                <div className="col bg-light-success px-6 py-8 rounded-xl mr-7 mb-7">
                  <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                    <i className="flaticon2-notepad text-success  display-4 text-white font-weight-bold"></i>
                    <span className="text-success font-weight-bolder font-size-h3">
                      { numberFormat( salesReport.AverageTicketServices ?? 0 ) }
                    </span>
                  </span>
                  <span className="text-success font-weight-bold font-size-h6 mt-2">
                    TT Promedio Servicios
                  </span>
                </div>
              </div>

              { salesReport.SalesBusinessLine && salesReport.SalesBusinessLine[ 0 ] ?
              <div className="row m-0">
                <div className="col bg-light-light px-6 py-8 rounded-xl mr-7 mb-7">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                    <i className="flaticon-diagram text-body display-4 font-weight-bold"></i>
                    <span className="text-body font-weight-bolder font-size-h3">
                      { numberFormat( salesReport.SalesBusinessLine[ 0 ].SalesAmount ?? 0 ) }
                    </span>
                  </span>
                  <span className="text-body font-weight-bold font-size-h6">
                    Ventas { salesReport.SalesBusinessLine[ 0 ].BusinessLine }
                  </span>
                </div>
              </div> 
                : <></> 
              }
              { salesReport.SalesBusinessLine && salesReport.SalesBusinessLine[ 1 ] ?
              <div className="row m-0">
                <div className="col bg-light-light px-6 py-8 rounded-xl mr-7 mb-7">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                    <i className="flaticon-diagram text-body display-4  font-weight-bold"></i>
                    <span className="text-body font-weight-bolder font-size-h3">
                      { numberFormat( salesReport.SalesBusinessLine[ 1 ].SalesAmount ?? 0 ) }
                    </span>
                  </span>
                  <span className="text-body font-weight-bold font-size-h6">
                    Ventas { salesReport.SalesBusinessLine[ 1 ].BusinessLine }
                  </span>
                </div>
              </div> 
                : <></> 
              }
              { salesReport.SalesBusinessLine && salesReport.SalesBusinessLine[ 2 ] ?
              <div className="row m-0">
                <div className="col bg-light-light px-6 py-8 rounded-xl mr-7 mb-7">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                    <i className="flaticon-diagram text-body display-4 text-white font-weight-bold"></i>
                    <span className="text-body font-weight-bolder font-size-h3">
                      { numberFormat( salesReport.SalesBusinessLine[ 2 ].SalesAmount ?? 0 ) }%
                    </span>
                  </span>
                  <span className="text-body font-weight-bold font-size-h6">
                    Ventas { salesReport.SalesBusinessLine[ 2 ].BusinessLine }
                  </span>
                </div>
              </div> 
                : <></> 
              }

              <div className="row m-0">
                <div className="col bg-light-success px-6 py-8 rounded-xl mr-7 mb-7">
                  <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                    <i className="flaticon-calendar-with-a-clock-time-tools text-success  display-4 text-white font-weight-bold"></i>
                    <span className="text-success font-weight-bolder font-size-h3">
                      { salesReport.AverageDaysSales ? salesReport.AverageDaysSales.toFixed( 1 ) : 0 } Días
                    </span>
                  </span>
                  <span className="text-success font-weight-bold font-size-h6 mt-2">
                    Días Promedio Para Venta
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
        : */}
        <div className="card-body">
          <div className="row text-center">
                <div className="col-xs-12 col-md-4">
                  <Autocomplete
                      size="small"
                      options={ businessLines }
                      getOptionLabel={ (option) => option.Name }
                      onChange={ handleBusinessLineChange }                
                      renderInput={ (params) => (
                          <TextField
                              {...params}
                              variant="outlined"
                              label="Línea de Negocio"
                              placeholder="Línea de Negocio"
                          />
                      ) }
                  />
                </div>
                <div className="col-xs-12 col-md-4">
                  <Autocomplete
                      size="small"
                      options={ socialsWeb }
                      getOptionLabel={ (option) => option.Name }
                      inputValue={ socialWebName }
                      onChange={ handleSocialsWebChange }                
                      renderInput={ (params) => (
                          <TextField
                              {...params}
                              variant="outlined"
                              label="Medio Digital"
                              placeholder="Medio Digital"
                          />
                      ) }
                  />
                </div>
          </div>
          <br></br>

          <div className="card">
            <div className="row text-center">
              <div className="col-md-12 bg-dark rounded-xl py-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder text-white">
                      { salesReport.PeriodBudget }
                    </span>
                  </h3>
                  { salesReport && salesReport.DigitalLeads && salesReport.DigitalLeads.length > 0 ? 
                      salesReport.DigitalLeads.map( (digital) => (
                          <Accordion className={classes.customWidth + " rounded"}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                  <Typography aria-owns={ open1 ? "mouse-over-popover" : undefined } aria-haspopup="true"
                                                onMouseEnter={ handlePopoverOpen1 } onMouseLeave={ handlePopoverClose1 }  className="row m-0 col-md-12" >
                                    <div className="row m-0 col-md-12">
                                      <div className="col px-6 py-8 rounded-xl mr-2 mb-2 col-md-2">
                                          <span className="text-body font-weight-bolder font-size-h5">
                                            Linea de Negocio
                                          </span>
                                          <br></br>
                                          { digital.BusinessLine && (
                                          <img src={ digital.BusinessLine === "PF"
                                                        ? "/media/svg/logoClient/PF.png"
                                                        : digital.BusinessLine === "SG"
                                                            ? "/media/svg/logoClient/SG.png"
                                                            : digital.BusinessLine === "BF"
                                                                ? "/media/svg/logoClient/BF.png"
                                                                : "/media/svg/logoClient/dummy.png"
                                                    }
                                                alt={ digital.BusinessLine === "PF"
                                                          ? "Privisión Final"
                                                          : digital.BusinessLine === "SG"
                                                              ? "Santa Gloria"
                                                              : digital.BusinessLine === "BF"
                                                                  ? "Bye Bye Friend"
                                                                  : "Logo"
                                                    }
                                                style={ { width: "80px" } }
                                            /> ) }
                                      </div>

                                      <div className="col px-6 py-8 rounded-xl mr-2 mb-2 col-md-3">
                                          { digital.SocialWeb && (
                                          <img src={ digital.SocialWeb === "FACEBOOK"
                                                        ? "/media/logos/facebook-lg.png"
                                                        : digital.SocialWeb === "GOOGLE"
                                                            ? "/media/logos/google-lg.png"
                                                            : "/media/logos/logo-web.png"
                                                    }
                                                alt={ digital.SocialWeb === "FACEBOOK"
                                                        ? "Facebook"
                                                        : digital.SocialWeb === "GOOGLE"
                                                            ? "Google"
                                                            : "Página WEB"
                                                    }
                                                style={ { width: "50px" } }
                                            /> ) }
                                          <br></br>
                                          <span className="text-body font-weight-bolder font-size-h3">
                                            {digital.Name}
                                          </span>
                                      </div>

                                      <div className={ `col ${  digital.BusinessLine === "PF"
                                                        ? "bg-light-primary"
                                                        : digital.BusinessLine === "SG"
                                                            ? "bg-light-info"
                                                            : digital.BusinessLine === "BF"
                                                                ? "bg-light-danger"
                                                                : "bg-danger"} px-4 py-4 rounded-xl mr-2 mb-2 mt-2 col-md-3` }>
                                          <span className="text-body font-weight-bolder font-size-h3">
                                            LEADS:
                                          </span>
                                          <br></br>
                                          <span className="text-body font-weight-bolder font-size-h1">
                                            { digital.TotalLeads ? digital.TotalLeads : 0 }
                                          </span>
                                      </div>

                                      <div className={ `col ${  digital.BusinessLine === "PF"
                                                        ? "bg-light-primary"
                                                        : digital.BusinessLine === "SG"
                                                            ? "bg-light-info"
                                                            : digital.BusinessLine === "BF"
                                                                ? "bg-light-danger"
                                                                : "bg-danger"} px-4 py-4 rounded-xl mr-2 mb-2 mt-2 col-md-3` }>
                                          <span className="text-body font-weight-bolder font-size-h3">
                                                VENTAS:
                                          </span>
                                          <br></br>
                                          <span className="text-body font-weight-bolder font-size-h1">
                                            { digital.SalesTotal ? digital.SalesTotal : 0 }
                                          </span>
                                      </div>
                                    </div>
                                  </Typography>
                                  <Popover id="mouse-over-popover" className={ classes1.popover } classes={ { paper: classes1.paper } }
                                                open={ open1 } anchorEl={ anchorEl1 } anchorOrigin={ { vertical: "top", horizontal: "center" } }
                                                transformOrigin={ { vertical: "center", horizontal: "left" } } onClose={ handlePopoverClose1 } disableRestoreFocus >
                                      De click para abrir o cerrar el detalle.
                                  </Popover>
                              </AccordionSummary>

                              <AccordionDetails style={ {flexGrow: 1} }>
                                  <div className={ `row m-0 border ${  digital.BusinessLine === "PF"
                                                        ? "border-primary"
                                                        : digital.BusinessLine === "SG"
                                                            ? "border-info"
                                                            : digital.BusinessLine === "BF"
                                                                ? "border-danger"
                                                                : "border-danger"} rounded-xl col-md-12` }>
                                      <div className="col px-6 py-8 rounded-xl mr-4 mb-6 col-5">
                                        <div>
                                          <Typography aria-owns={ open_CampaignCost ? "mouse-over-popover" : undefined } aria-haspopup="true"
                                                onMouseEnter={ handlePopoverOpen_CampaignCost } onMouseLeave={ handlePopoverClose_CampaignCost } >
                                              <div className={ `col ${  digital.BusinessLine === "PF"
                                                        ? "bg-primary"
                                                        : digital.BusinessLine === "SG"
                                                            ? "bg-info"
                                                            : digital.BusinessLine === "BF"
                                                                ? "bg-danger"
                                                                : "bg-danger"} px-6 py-8 rounded-xl mr-4 mb-2 text-white` }>
                                                PRESUPUESTO CAMPAÑA: &nbsp;&nbsp;&nbsp;
                                                <span className="text-body font-weight-bolder font-size-h1 text-white">
                                                  { numberFormat( digital.CampaignCost ? digital.CampaignCost : 0 ) }
                                                </span>
                                              </div>
                                          </Typography>
                                          <Popover id="mouse-over-popover" className={ classes1.popover } classes={ { paper: classes1.paper } }
                                                open={ open_CampaignCost } anchorEl={ anchorEl_CampaignCost } anchorOrigin={ { vertical: "top", horizontal: "center" } }
                                                transformOrigin={ { vertical: "center", horizontal: "left" } } onClose={ handlePopoverClose_CampaignCost } disableRestoreFocus >
                                            * Igual a la cantidad de dinero aprobada para el periodo.
                                          </Popover>
                                        </div>

                                        <div>
                                          <Typography aria-owns={ open_ExercisedBudget ? "mouse-over-popover" : undefined } aria-haspopup="true"
                                                onMouseEnter={ handlePopoverOpen_ExercisedBudget } onMouseLeave={ handlePopoverClose_ExercisedBudget } >
                                              <div className={ `col ${  digital.BusinessLine === "PF"
                                                        ? "bg-primary"
                                                        : digital.BusinessLine === "SG"
                                                            ? "bg-info"
                                                            : digital.BusinessLine === "BF"
                                                                ? "bg-danger"
                                                                : "bg-danger"} px-6 py-8 rounded-xl mr-4 mb-2 text-white` }>
                                                PRESUPUESTO DE CAMPAÑA EJERCIDO: &nbsp;&nbsp;&nbsp;
                                                <span className="text-body font-weight-bolder font-size-h1 text-white">
                                                  { numberFormat( digital.ExercisedBudget ? digital.ExercisedBudget : 0 ) }
                                                </span>
                                              </div>
                                          </Typography>
                                          <Popover id="mouse-over-popover" className={ classes1.popover } classes={ { paper: classes1.paper } }
                                                open={ open_ExercisedBudget } anchorEl={ anchorEl_ExercisedBudget } anchorOrigin={ { vertical: "top", horizontal: "center" } }
                                                transformOrigin={ { vertical: "center", horizontal: "left" } } onClose={ handlePopoverClose_ExercisedBudget } disableRestoreFocus >
                                            * Dinero realmente gastado para la campaña.
                                          </Popover>
                                        </div>

                                        <div>
                                          <Typography aria-owns={ open_LeadCost ? "mouse-over-popover" : undefined } aria-haspopup="true"
                                                onMouseEnter={ handlePopoverOpen_LeadCost } onMouseLeave={ handlePopoverClose_LeadCost } >
                                              <div className={ `col ${  digital.BusinessLine === "PF"
                                                        ? "bg-primary"
                                                        : digital.BusinessLine === "SG"
                                                            ? "bg-info"
                                                            : digital.BusinessLine === "BF"
                                                                ? "bg-danger"
                                                                : "bg-danger"} px-6 py-8 rounded-xl mr-4 mb-2 text-white` }>
                                                COSTO POR LEAD: &nbsp;&nbsp;&nbsp;
                                                <span className="text-body font-weight-bolder font-size-h1 text-white">
                                                  { numberFormat( digital.LeadCost ? digital.LeadCost : 0 ) }
                                                </span>
                                              </div>
                                          </Typography>
                                          <Popover id="mouse-over-popover" className={ classes1.popover } classes={ { paper: classes1.paper } }
                                                open={ open_LeadCost } anchorEl={ anchorEl_LeadCost } anchorOrigin={ { vertical: "top", horizontal: "center" } }
                                                transformOrigin={ { vertical: "center", horizontal: "left" } } onClose={ handlePopoverClose_LeadCost } disableRestoreFocus >
                                            * Presupuesto de Campaña Ejercido entre el Total de Leads.
                                          </Popover>
                                        </div>

                                        <div>
                                          <Typography aria-owns={ open_ROI ? "mouse-over-popover" : undefined } aria-haspopup="true"
                                                onMouseEnter={ handlePopoverOpen_ROI } onMouseLeave={ handlePopoverClose_ROI } >
                                              <div className={ `col ${  digital.BusinessLine === "PF"
                                                        ? "bg-primary"
                                                        : digital.BusinessLine === "SG"
                                                            ? "bg-info"
                                                            : digital.BusinessLine === "BF"
                                                                ? "bg-danger"
                                                                : "bg-danger"} px-6 py-8 rounded-xl mr-4 mb-2 text-white` }>
                                                MARGEN BRUTO: &nbsp;&nbsp;&nbsp;
                                                <span className="text-body font-weight-bolder font-size-h1 text-white">
                                                  { numberFormat( digital.ROI ? digital.ROI : 0 ) }
                                                </span>
                                            </div>
                                          </Typography>
                                          <Popover id="mouse-over-popover" className={ classes1.popover } classes={ { paper: classes1.paper } }
                                                open={ open_ROI } anchorEl={ anchorEl_ROI } anchorOrigin={ { vertical: "top", horizontal: "center" } }
                                                transformOrigin={ { vertical: "center", horizontal: "left" } } onClose={ handlePopoverClose_ROI } disableRestoreFocus >
                                            * (Monto de Ventas - Presupuesto de Campaña Ejercido) / Monto de Ventas.
                                          </Popover>
                                        </div>
                                      </div>

                                      <div className="col px-6 py-8 rounded-xl mr-7 mb-6 col-5">
                                        <div>
                                          <Typography aria-owns={ open_TotalLeads ? "mouse-over-popover" : undefined } aria-haspopup="true"
                                                onMouseEnter={ handlePopoverOpen_TotalLeads } onMouseLeave={ handlePopoverClose_TotalLeads } >
                                              <div className={ `col ${  digital.BusinessLine === "PF"
                                                        ? "bg-light-primary"
                                                        : digital.BusinessLine === "SG"
                                                            ? "bg-light-info"
                                                            : digital.BusinessLine === "BF"
                                                                ? "bg-light-danger"
                                                                : "bg-danger"} px-6 py-8 rounded-xl mr-4 mb-2` }>
                                                Total de Leads: &nbsp;&nbsp;&nbsp;
                                                <span className="text-body font-weight-bolder font-size-h2">
                                                  { digital.TotalLeads ? digital.TotalLeads : 0 }
                                                </span>
                                            </div>
                                          </Typography>
                                          <Popover id="mouse-over-popover" className={ classes1.popover } classes={ { paper: classes1.paper } }
                                                open={ open_TotalLeads } anchorEl={ anchorEl_TotalLeads } anchorOrigin={ { vertical: "top", horizontal: "center" } }
                                                transformOrigin={ { vertical: "center", horizontal: "left" } } onClose={ handlePopoverClose_TotalLeads } disableRestoreFocus >
                                            * Cuenta de leads del periodo, empresa y social media elegido.
                                          </Popover>
                                        </div>

                                        <div>
                                          <Typography aria-owns={ open_SalesTotal ? "mouse-over-popover" : undefined } aria-haspopup="true"
                                                onMouseEnter={ handlePopoverOpen_SalesTotal } onMouseLeave={ handlePopoverClose_SalesTotal } >
                                              <div className={ `col ${  digital.BusinessLine === "PF"
                                                        ? "bg-light-primary"
                                                        : digital.BusinessLine === "SG"
                                                            ? "bg-light-info"
                                                            : digital.BusinessLine === "BF"
                                                                ? "bg-light-danger"
                                                                : "bg-danger"} px-6 py-8 rounded-xl mr-4 mb-2` }>
                                                Total de Ventas: &nbsp;&nbsp;&nbsp;
                                                <span className="text-body font-weight-bolder font-size-h2">
                                                  { digital.SalesTotal ? digital.SalesTotal : 0 }
                                                </span>
                                            </div>
                                          </Typography>
                                          <Popover id="mouse-over-popover" className={ classes1.popover } classes={ { paper: classes1.paper } }
                                                open={ open_SalesTotal } anchorEl={ anchorEl_SalesTotal } anchorOrigin={ { vertical: "top", horizontal: "center" } }
                                                transformOrigin={ { vertical: "center", horizontal: "left" } } onClose={ handlePopoverClose_SalesTotal } disableRestoreFocus >
                                            * Cuenta de ventas del periodo, empresa y social media elegido.
                                          </Popover>
                                        </div>

                                        <div>
                                          <Typography aria-owns={ open_SalesContract ? "mouse-over-popover" : undefined } aria-haspopup="true"
                                                onMouseEnter={ handlePopoverOpen_SalesContract } onMouseLeave={ handlePopoverClose_SalesContract } >
                                              <div className={ `col ${  digital.BusinessLine === "PF"
                                                        ? "bg-light-primary"
                                                        : digital.BusinessLine === "SG"
                                                            ? "bg-light-info"
                                                            : digital.BusinessLine === "BF"
                                                                ? "bg-light-danger"
                                                                : "bg-danger"} px-6 py-8 rounded-xl mr-4 mb-2` }>
                                                Monto de Ventas: &nbsp;&nbsp;&nbsp;
                                                <span className="text-body font-weight-bolder font-size-h2">
                                                  { numberFormat( digital.SalesContract ? digital.SalesContract : 0 ) }
                                                </span>
                                            </div>
                                          </Typography>
                                          <Popover id="mouse-over-popover" className={ classes1.popover } classes={ { paper: classes1.paper } }
                                                open={ open_SalesContract } anchorEl={ anchorEl_SalesContract } anchorOrigin={ { vertical: "top", horizontal: "center" } }
                                                transformOrigin={ { vertical: "center", horizontal: "left" } } onClose={ handlePopoverClose_SalesContract } disableRestoreFocus >
                                            * Suma del monto de ventas del periodo, empresa y social media elegido.
                                          </Popover>
                                        </div>

                                        <div>
                                          <Typography aria-owns={ open_Conversion ? "mouse-over-popover" : undefined } aria-haspopup="true"
                                                onMouseEnter={ handlePopoverOpen_Conversion } onMouseLeave={ handlePopoverClose_Conversion } >
                                              <div className={ `col ${  digital.BusinessLine === "PF"
                                                        ? "bg-light-primary"
                                                        : digital.BusinessLine === "SG"
                                                            ? "bg-light-info"
                                                            : digital.BusinessLine === "BF"
                                                                ? "bg-light-danger"
                                                                : "bg-danger"} px-6 py-8 rounded-xl mr-4 mb-2` }>
                                                Conversión LEAD a Venta: &nbsp;&nbsp;&nbsp;
                                                <span className="text-body font-weight-bolder font-size-h2">
                                                  { digital.Conversion ? digital.Conversion.toFixed( 3 ) : 0 }%
                                                </span>
                                            </div>
                                          </Typography>
                                          <Popover id="mouse-over-popover" className={ classes1.popover } classes={ { paper: classes1.paper } }
                                                open={ open_Conversion } anchorEl={ anchorEl_Conversion } anchorOrigin={ { vertical: "top", horizontal: "center" } }
                                                transformOrigin={ { vertical: "center", horizontal: "left" } } onClose={ handlePopoverClose_Conversion } disableRestoreFocus >
                                            * Total de ventas / Total de leads.
                                          </Popover>
                                        </div>

                                        <div>
                                          <Typography aria-owns={ open_AverageTime ? "mouse-over-popover" : undefined } aria-haspopup="true"
                                                onMouseEnter={ handlePopoverOpen_AverageTime } onMouseLeave={ handlePopoverClose_AverageTime } >
                                              <div className={ `col ${  digital.BusinessLine === "PF"
                                                        ? "bg-light-primary"
                                                        : digital.BusinessLine === "SG"
                                                            ? "bg-light-info"
                                                            : digital.BusinessLine === "BF"
                                                                ? "bg-light-danger"
                                                                : "bg-danger"} px-6 py-8 rounded-xl mr-4 mb-2` }>
                                                Tiempo de Respuesta Promedio: &nbsp;&nbsp;&nbsp;
                                                <span className="text-body font-weight-bolder font-size-h2">
                                                  { digital.AverageTime ? digital.AverageTime.toFixed( 2 ) : 0 }
                                                </span> mins.
                                            </div>
                                          </Typography>
                                          <Popover id="mouse-over-popover" className={ classes1.popover } classes={ { paper: classes1.paper } }
                                                open={ open_AverageTime } anchorEl={ anchorEl_AverageTime } anchorOrigin={ { vertical: "top", horizontal: "center" } }
                                                transformOrigin={ { vertical: "center", horizontal: "left" } } onClose={ handlePopoverClose_AverageTime } disableRestoreFocus >
                                            - Sumatoria (Tiempo de atención - tiempo de asignación) / Total de leads.
                                          </Popover>
                                        </div>

                                        <div>
                                          <Typography aria-owns={ open_AverageDaysSales ? "mouse-over-popover" : undefined } aria-haspopup="true"
                                                onMouseEnter={ handlePopoverOpen_AverageDaysSales } onMouseLeave={ handlePopoverClose_AverageDaysSales } >
                                              <div className={ `col ${  digital.BusinessLine === "PF"
                                                        ? "bg-light-primary"
                                                        : digital.BusinessLine === "SG"
                                                            ? "bg-light-info"
                                                            : digital.BusinessLine === "BF"
                                                                ? "bg-light-danger"
                                                                : "bg-danger"} px-6 py-8 rounded-xl mr-4 mb-2` }>
                                                Dias de Conversión Promedio: &nbsp;&nbsp;&nbsp;
                                                <span className="text-body font-weight-bolder font-size-h2">
                                                  { digital.AverageDaysSales ? digital.AverageDaysSales.toFixed( 1 ) : 0 }
                                                </span> día(s)
                                            </div>
                                          </Typography>
                                          <Popover id="mouse-over-popover" className={ classes1.popover } classes={ { paper: classes1.paper } }
                                                open={ open_AverageDaysSales } anchorEl={ anchorEl_AverageDaysSales } anchorOrigin={ { vertical: "top", horizontal: "center" } }
                                                transformOrigin={ { vertical: "center", horizontal: "left" } } onClose={ handlePopoverClose_AverageDaysSales } disableRestoreFocus >
                                            * De las ventas realizadas, Sumatoria (dia de alta - dia de ingreso) / Total de Ventas.
                                          </Popover>
                                        </div>

                                          {
                                          //#region DATOS ANTERIORES
                                          /* <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-2">
                                              Ticket Promedio: &nbsp;&nbsp;&nbsp;
                                              <span className="text-body font-weight-bolder font-size-h2">
                                                  { numberFormat( digital.AverageTicket ? digital.AverageTicket : 0 ) }
                                              </span>
                                          </div>

                                          <div className="col bg-light-primary px-6 py-8 rounded-xl mr-4 mb-2">
                                              Ticket por Servicio Promedio: &nbsp;&nbsp;&nbsp;
                                              <span className="text-body font-weight-bolder font-size-h2">
                                                  { numberFormat( digital.AverageTicketServices ? digital.AverageTicketServices : 0 ) }
                                              </span>
                                          </div> */
                                          //#endregion
                                          }
                                      </div>

                                  </div>
                              </AccordionDetails>
                          </Accordion>
                      ) )
                    : <></>
                  }
              </div>
            </div>
          </div>
        </div>
        {/* } */}
      </div>
    </>
  );
}
