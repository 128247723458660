import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Typography from '@material-ui/core/Typography';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function GlobalSnackbar(props) {
  return (
    <div>
      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={props.message.open}
        onClose={props.handleClose}
      >
        <Alert onClose={props.handleClose} severity={props.message.variant}>
          <Typography variant="subtitle1">{props.message.text}</Typography>
          {props.message.description && (
            <Typography variant="subtitle2">{props.message.description}</Typography>
          )}
          <ul>
            {props.message?.list?.map(item => (
              <li>{item}</li>
            ))}
          </ul>
  

        </Alert>
      </Snackbar>
    </div>
  );
}
