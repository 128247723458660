/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {  useEffect } from "react";
import ApexCharts from "apexcharts";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

export function StatsAdviserProspection({ className, createdProspectsDate, createdProspectsPerDay, rangeDate}) {

let series = [];
let categories = [];
let createdProspects =  createdProspectsDate && createdProspectsDate.length > 0 && createdProspectsDate[0].NumberProspects
if (createdProspectsPerDay && createdProspectsPerDay.length > 0) {
  createdProspectsPerDay.map((data) => {
    series.push(data.Cantidad);
    categories.push(data.Fecha);
  })
}
  useEffect(() => {
    const element = document.getElementById("kt_stats_widget_7_chart");
    if (!element) {
      return;
    }

    const options = {
      series: [
        {
          name: "Prospectos creados",
          data: series,
        },
      ],
      chart: {
        type: "bar",
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "";
        },
        offsetY: -30,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ["#304758"]
        }
      },
      legend: {
        show: true,
      },
      stroke: {
        curve: 'straight'
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      xaxis: {
        categories: categories,
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
    };
    try {
      if ( createdProspectsPerDay) {
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
          chart.destroy();
        };
      }
    } catch {}
  }, [
    createdProspectsPerDay
  ]);



  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
          <div className="d-flex flex-column mr-2">
            <span
              className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5"
            >
              Prospectos creados
            </span>
            <span
              className="text-primary text-hover-dark font-size-h6"
            >{rangeDate}</span>
             
            </div>
          <span className="symbol symbol-light-primary symbol-45">
            <span className="symbol-label font-weight-bolder font-size-h6">
              {createdProspects}
            </span>
          </span>
        </div>
        <div className="flex-grow-1">
          {createdProspectsPerDay ? (
            <div
              id="kt_stats_widget_7_chart"
              className="card-rounded-bottom"
              style={{ height: "230px" }}
            ></div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
