import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
  iconButton: {
    "&:hover":{
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  },
};


class CustomToolbar extends React.Component {
  render() {
    const { classes, handleClick } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={"Agregar"}>
          <IconButton
            className={classes.iconButton}
            color="primary"
            onClick={() => handleClick( null, "add")}
          >
            <AddIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(
  CustomToolbar
);