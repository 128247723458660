import React, { useState, useEffect } from "react";
import { Badge } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import endOfMonth from "date-fns/endOfMonth";
import startOfMonth from "date-fns/startOfMonth";
import format from "date-fns/format";
import ActivityTimeLine from "./TimeLine";
import { Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import lightBlue from "@material-ui/core/colors/lightBlue";
import deepPurple from "@material-ui/core/colors/deepPurple";
import TodayIcon from "@material-ui/icons/Today";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import CallIcon from "@material-ui/icons/Call";
import esLocale from "date-fns/locale/es";
import { useSelector } from "react-redux";

const materialTheme = createMuiTheme({
  MuiPickersToolbar: {
    toolbar: {
      backgroundColor: "#000 !important",
    },
  },
  MuiPickersBasePicker: {
    pickerView: {
      width: "100%",
    },
    backgroundColor: lightBlue.A200,
  },
  MuiPickersCalendarHeader: {
    switchHeader: {
      // backgroundColor: lightBlue.A200,
      // color: "white",
    },
  },
  MuiPickersDay: {
    day: {
      color: "red",
      backgroundColor: lightBlue["400"],
    },
    daySelected: {
      backgroundColor: lightBlue["400"],
    },
    dayDisabled: {
      color: lightBlue["100"],
    },
    current: {
      color: lightBlue["900"],
    },
  },
  MuiPickersModal: {
    dialogAction: {
      color: lightBlue["400"],
    },
  },
});

function CustomDatePicker({ fetchActivitiesTotales }) {
  const { user } = useSelector((state) => state.auth);
  const [month, setMonth] = useState(new Date());
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [totales, setTotales] = useState({
    llamadas: 0,
    citas: 0,
    correo: 0,
    whatsapp: 0,
    sms: 0,
  });

  const [totalesMonth, setTotalesMonth] = useState({
    llamadas: 0,
    citas: 0,
    correo: 0,
    whatsapp: 0,
    sms: 0,
  });
  const handleDateChange = async (event) => {
    setSelectedDate(event);
    let data = await fetchActivitiesTotales(event, event);
    let total = {
      llamadas: 0,
      citas: 0,
      correo: 0,
      whatsapp: 0,
      sms: 0,
    };

    let algo = data?.map((item) => {
      switch (item.ActivityTypeId) {
        case 1:
          total.citas = item.NumActivities;
          break;
        case 2:
          total.llamadas = item.NumActivities;
          break;
        case 3:
          total.correo = item.NumActivities;
          break;
        case 4:
          total.whatsapp = item.NumActivities;
          break;
        default:
          total.sms = item.NumActivities;
      }
    });
    setTotales(total);
    setSelectedActivities(data);
  };

  const eliminaDuplicados = (data) => {    
    let resArr = [];
    data.filter((item) => {
      var i = resArr.findIndex((x) => x.ActivityDate == item.ActivityDate);
      if (i <= -1) {
        resArr.push(item.ActivityDate);
      }
      return null;
    });
    
    return resArr;
  };

  const handleMonthChange = async (event) => {
    const result = endOfMonth(event);
    setMonth(event);
    let data = await fetchActivitiesTotales(event, result);
    let total = {
      llamadas: 0,
      citas: 0,
      correo: 0,
      whatsapp: 0,
      sms: 0,
    };

    let algo = data?.map((item) => {
      switch (item.ActivityTypeId) {
        case 1:
          total.citas = total.citas + item.NumActivities;
          break;
        case 2:
          total.llamadas = total.llamadas + item.NumActivities;
          break;
        case 3:
          total.correo = total.correo + item.NumActivities;
          break;
        case 4:
          total.whatsapp = total.whatsapp + item.NumActivities;
          break;
        default:
          total.sms = total.sms + item.NumActivities;
      }
    });
    setTotalesMonth(total);
    // just select random days to simulate server side based data
    return new Promise((resolve) => {
      setTimeout(() => {
        setSelectedDays(eliminaDuplicados(data));
        resolve();
      }, 1000);
    });
  };
  

  useEffect(() => {
    async function fetchdata() {
      let startDate = startOfMonth(new Date());
      let endDate = endOfMonth(new Date());
      let data = await fetchActivitiesTotales(startDate, endDate);
      if (Array.isArray(data)) {
        let date = format(new Date(), "yyyy-MM-dd")
          let today = data?.filter((activity) => activity.ActivityDate === format(new Date(), "yyyy-MM-dd"));
          let total = {
            llamadas: 0,
            citas: 0,
            correo: 0,
            whatsapp: 0,
            sms: 0,
          };

          let algo = today?.map((item) => {
            switch (item.ActivityTypeId) {
              case 1:
                total.citas = item.NumActivities;
                break;
              case 2:
                total.llamadas = item.NumActivities;
                break;
              case 3:
                total.correo = item.NumActivities;
                break;
              case 4:
                total.whatsapp = item.NumActivities;
                break;
              default:
                total.sms = item.NumActivities;
            }
          });
          setTotales(total);

          let totalMonth = {
            llamadas: 0,
            citas: 0,
            correo: 0,
            whatsapp: 0,
            sms: 0,
          };

          let algoMonth = data?.map((item) => {
            switch (item.ActivityTypeId) {
              case 1:
                totalMonth.citas = totalMonth.citas + item.NumActivities;
                break;
              case 2:
                totalMonth.llamadas = totalMonth.llamadas + item.NumActivities;
                break;
              case 3:
                totalMonth.correo = totalMonth.correo + item.NumActivities;
                break;
              case 4:
                totalMonth.whatsapp = totalMonth.whatsapp + item.NumActivities;
                break;
              default:
                totalMonth.sms = totalMonth.sms + item.NumActivities;
            }
          });

          setTotalesMonth(totalMonth);
          setSelectedActivities(today);
          setSelectedDays(eliminaDuplicados(data));
        
      } else {
        console.log(data);
      }
    }
    fetchdata();
  }, []);

  return (
    <div className="row">
      <div className="col-xs- 12 col-md-6 col-lg-5 col-xxl-4">
        <ThemeProvider theme={materialTheme}>
          <DatePicker
            label="With server data"
            variant="static"
            orientation="landscape"
            value={selectedDate}
            onChange={handleDateChange}
            onMonthChange={handleMonthChange}
            style={{ overFlowX: "unset" }}
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
              const date = format(day, "yyyy-MM-dd"); // skip this step, it is required to support date libs
              const isSelected =
                isInCurrentMonth && selectedDays.includes(date);
              // You can also use our internal <Day /> component
              return (
                <Badge
                  badgeContent={isSelected ? "📑" : undefined}
                  style={{ top: "10px" }}
                >
                  {dayComponent}
                </Badge>
              );
            }}
          />
        </ThemeProvider>
      </div>

      <div className="col-xs-12 col-md-6 col-lg-7 col-xxl-7">
        <div className="card card-stretch">
          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            component="p"
            style={{ margin: 20 }}
          >
            Total de actividades de{" "}
            {format(month, "MMMM-yyyy", {
              locale: esLocale,
            })}
          </Typography>
          <div className={"p-2 align-items-center justify-content-between"}>
            <span className="label label-xl label-light-danger label-inline m-2">
              <TodayIcon />
              &nbsp; {totalesMonth.citas} Citas
            </span>
            <span className="label label-xl label-light-primary label-inline m-2">
              <CallIcon />
              &nbsp; {totalesMonth.llamadas} Llamada
            </span>
            <span className="label label-xl label-light-dark label-inline m-2">
              <MailOutlineIcon />
              &nbsp; {totalesMonth.correo} Correo
            </span>
            <span className="label label-xl label-light-success label-inline m-2">
              <WhatsAppIcon />
              &nbsp; {totalesMonth.whatsapp} Whatsapp
            </span>
            <span className="label label-xl label-light-warning label-inline m-2">
              <ChatOutlinedIcon />
              &nbsp;{totalesMonth.sms} SMS
            </span>
          </div>
          <Typography
            variant="h5"
            align="center"
            color="textPrimary"
            component="p"
            style={{ margin: 10 }}
          >
            Total de actividades del {format(selectedDate, "dd/MM/yyyy")}
          </Typography>
          <div className={"p-2 align-items-center  justify-content-between"}>
            <span className="label label-xl label-light-danger label-inline m-2">
              <TodayIcon />
              &nbsp; {totales.citas} Citas
            </span>
            <span className="label label-xl label-light-primary label-inline m-2">
              <CallIcon />
              &nbsp; {totales.llamadas} Llamada
            </span>
            <span className="label label-xl label-light-dark label-inline m-2">
              <MailOutlineIcon />
              &nbsp; {totales.correo} Correo
            </span>
            <span className="label label-xl label-light-success label-inline m-2">
              <WhatsAppIcon />
              &nbsp; {totales.whatsapp} Whatsapp
            </span>
            <span className="label label-xl label-light-warning label-inline m-2">
              <ChatOutlinedIcon />
              &nbsp;{totales.sms} SMS
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomDatePicker;
