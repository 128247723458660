/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

export function StatsTopItemsSold({ className, topItemsSold, startDate, endDate, NameSeries }) {
  let series = [];
  let categories = [];

  if (!NameSeries || NameSeries.length === 0) {
    NameSeries = "Top artículos más vendidos";
  }
  if (topItemsSold && topItemsSold.length > 0) {
    topItemsSold.map((data) => {
      series.push(data.ItemsSold);
      categories.push(data.ItemName);
    });
  }
  useEffect(() => {
    const element = document.getElementById("kt_stats_topItemsSold");
    if (!element) {
      return;
    }

    const options = {
      series: [
        {
          name: NameSeries,
          data: series,
        },
      ],
      chart: {
        type: "bar",
        maxHeight: "fit-content",
        zoom: {
          enabled: false,
        },
      },

      colors: ["#ff92ed"],
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          return val;
        },
        offsetX:  30,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          colors: ["#000"]
        },
      },
      legend: {
        show: true,
      },
      stroke: {
        curve: "straight",
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: function(val) {
            return val;
          },
        },
      },

      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
    };
    try {
      if (topItemsSold) {
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
          chart.destroy();
        };
      }
    } catch {}
  }, [topItemsSold]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
        <div className="d-flex flex-row justify-content-between mr-2" style={{width: "100%"}}>
            <span
              className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5"
            >
              {NameSeries}
            </span>
            <span className="text-dark-75 text-hover-primary">
              Del {moment(startDate).format("DD/MM/yyyy")} al {moment(endDate).format("DD/MM/yyyy")}
            </span>
          </div>
        </div>
        <div className="flex-grow-1">
          {topItemsSold ? (
            <div
              id="kt_stats_topItemsSold"
              className="card-rounded-bottom"
              style={{ height: "230px" }}
            ></div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
