/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

export default function DateRangePickerCustom({
  className,
  handleCallback,
  startDate,
  endDate,
  handleEvent,
}) {
  return (
    <DateRangePicker
      initialSettings={{
        startDate: startDate,
        endDate: endDate,
        showDropdowns: true,
        locale: {
          format: "DD/MM/yyyy",
          applyLabel: "Aplicar",
          cancelLabel: "Cancelar",
          fromLabel: "Desde",
          toLabel: "Hasta",
          customRangeLabel: "Rango de fechas",
          daysOfWeek: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
          monthNames: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ],
        },
        ranges: {
          "Hoy": [
            moment().toDate(),
            moment().toDate(),
          ],
          "Los últimos 7 días": [
            moment()
              .subtract(6, "days")
              .toDate(),
            moment().toDate(),
          ],
          "Los últimos 30 días": [
            moment()
              .subtract(29, "days")
              .toDate(),
            moment().toDate(),
          ],
          "Este mes": [
            moment()
              .startOf("month")
              .toDate(),
            moment()
              .endOf("month")
              .toDate(),
          ],
          "El mes pasado": [
            moment()
              .subtract(1, "month")
              .startOf("month")
              .toDate(),
            moment()
              .subtract(1, "month")
              .endOf("month")
              .toDate(),
          ],
        },
      }}
      onEvent={handleEvent}
      onCallback={handleCallback}
    >
      <input
        type="text"
        className={className ? className : "form-control mt-3 col-md-4"}
        style={{ display: "inline-block" }}
      />
    </DateRangePicker>
  );
}
