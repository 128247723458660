/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Nav, Tab } from "react-bootstrap";
import { format, parseISO, differenceInDays } from "date-fns";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import TodayIcon from "@material-ui/icons/Today";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CallIcon from "@material-ui/icons/Call";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import api from "../../../../api";
import CustomModal from "../../dialogs/CustomModal";
import GlobalSnackbar from "../../../../app/components/GlobalSnackbar";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage, useIntl } from "react-intl";
import QuotationConfirmationDialog from "../../../../app/modules/Prospect/partials/QuotationConfirmationDialog";
import esLocale from "date-fns/locale/es";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PersonIcon from "@material-ui/icons/Person";
import CustomTable from "../../../../app/components/CustomTable";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "auto",
    margin: "1rem",
    maxHeight: "45vh",
    display: "inline-block",
    "&:hover, &:focus": {
      overflow: "auto",
      "&::-webkit-scrollbar": {
        display: "inline-block",
      },
    },
    "&::-webkit-scrollbar": {
      width: "0.5rem",
      height: "0.5rem",
      display: "inline-block",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#ccc",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#b3b3b3",
      boxShadow: "0 0 2px 1px rgba(0, 0, 0, 0.2)",
    },
    "&::-webkit-scrollbar-thumb:active": {
      backgroundColor: "#999999",
    },
    "&::-webkit-scrollbar-track": {
      background: "#e1e1e1",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track:hover": {
      background: "#d4d4d4",
    },
    "&::-webkit-scrollbar-track:active": {
      background: "#d4d4d4",
    },
  },
  bgDangerExpired: {
    borderLeft: "8px solid red",
  },
}));

export function AdvanceTablesActivities({ className }) {
  const { user } = useSelector((state) => state.auth);
  const classes = useStyles();
  const history = useHistory();
  const dueDate = new Date().setDate(new Date().getDate() + 2);
  const [activities, setActivities] = useState([]);
  const [key, setKey] = useState("Day");
  const [showModal, setShowModal] = useState(false);
  const [currentActivity, setCurrentActivity] = useState({});
  const [message, setMessage] = useState({
    text: "",
    variant: "",
    open: false,
  });
  const [openQuotationConfirmation, setOpenQuotationConfirmation] = useState(
    false
  );
  const [reschedule, setReschedule] = useState(false);

  const handleCloseQuotationYes = () => {
    setOpenQuotationConfirmation(false);
    if (
      currentActivity?.Id !== undefined &&
      currentActivity?.Id !== undefined
    ) {
      history.push({
        pathname: "/quotation/add",
        search: `?ActivityId=${currentActivity?.Id}&ProspectId=${currentActivity?.ProspectId}`,
      });
    }
  };

  const handleCloseQuotation = () => {
    setOpenQuotationConfirmation(false);
    history.push("/dashboard");
  };

  const fetchActivitiesExpired = async () => {
    if (key === "Last") {
      api.get(`activity/expired-activities`).then(
        async (result) => {
          if (result.data.status === 200) {
            let data = result.data.body.map((row) => {
              if (user?.Id === row?.CreatedUserId || user?.RoleId === 1) {
                return row;
              } else {
                row.MobilePhone = "";
                row.Email = "";
                return row;
              }
            });

            await setActivities(data);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const fetchActivities = async () => {
    var initialActivityDate = new Date();
    var finalActivityDate = new Date();

    if (key === "Week") {
      initialActivityDate = initialActivityDate.setDate(
        initialActivityDate.getDate() + 1
      );
      finalActivityDate = finalActivityDate.setDate(
        finalActivityDate.getDate() + 7
      );
    } else if (key === "Month") {
      initialActivityDate = initialActivityDate.setDate(
        initialActivityDate.getDate() + 8
      );
      finalActivityDate = null;
    } else if (key === "Last") {
      return fetchActivitiesExpired();
    } else {
      finalActivityDate = finalActivityDate;
    }
    api
      .get(
        `activity/activities?InitialActivityDate=${format(
          new Date(initialActivityDate),
          "yyyy/MM/dd"
        )}&FinalActivityDate=${
          finalActivityDate === null
            ? ""
            : format(new Date(finalActivityDate), "yyyy/MM/dd")
        }&All=true`
      )
      .then(
        (result) => {
          if (result.data.status === 200) {
            let data = result.data.body.map((row) => {
              if (user?.Id === row?.CreatedUserId || user?.RoleId === 1) {
                return row;
              } else {
                row.MobilePhone = "";
                row.Email = "";
                return row;
              }
            });
            setActivities(data);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    fetchActivities();
  }, [key]);

  const handleStartActivity = async (event, id, activity) => {
    event.preventDefault();
    api.get(`activity/${id}/start`).then(
      (result) => {
        if (result.data.status === 201 && activity !== undefined) {
          if (activity?.QuotationLaunch === 1) {
            setCurrentActivity(result.data.body);
            setOpenQuotationConfirmation(true);
          }
          switch (activity?.ActivityTypeId) {
            case 1:
              window.open(
                `https://www.google.com/maps/place/${activity?.Address}`,
                "_blank"
              );
              break;
            case 2:
              window.open(`tel:${activity?.MobilePhone}`);
              break;
            case 3:
              window.open(`mailto:${activity?.Email}`);
              break;
            case 4:
              window.open(
                `https://api.whatsapp.com/send?phone=+52${activity?.MobilePhone}&text=En%20*J.%20Garc%C3%ADa%20L%C3%B3pez*%20estamos%20muy%20contentos%20de%20poder%20apoyarte.%20`,
                "_blank"
              );
              break;
            case 5:
              window.open(`tel:${activity?.MobilePhone}`);
              break;
            default:
          }

          fetchActivities();
          let message = {
            text: <FormattedMessage id="TEXT.SUCCESS" />,
            variant: "success",
            open: true,
          };
          setCurrentActivity({});
          setMessage({
            text: message.text,
            variant: message.variant,
            open: message.open,
          });
          setTimeout(() => {
            setMessage({
              text: "",
              variant: message.variant,
              open: false,
            });
          }, 5000);
        } else {
          let message = {
            text: <FormattedMessage id="TEXT.ERROR" />,
            variant: "error",
            open: true,
          };
          setMessage({
            text: message.text,
            variant: message.variant,
            open: message.open,
          });
          setTimeout(() => {
            setMessage({
              text: "",
              variant: message.variant,
              open: false,
            });
          }, 5000);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleClose = (message) => {
    if (message) {
      setMessage({
        text: message.text,
        variant: message.variant,
        open: message.open,
      });
    } else {
      setMessage({
        text: "",
        variant: "",
        open: false,
      });
    }
    fetchActivities();
    setShowModal(false);
  };

  const handleShow = (activity, reschedule) => {
    //setCurrentActivityId(activity?.Id);
    setCurrentActivity(activity);
    setShowModal(true);
    setReschedule(reschedule);
  };

  const headRows = [
    {
      name: "ActivityDate",
      label: "Fecha",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          let activity = activities[dataIndex];
          return (
            <button
              onClick={() => {
                if (key !== "Last" && !activity?.StartDate) {
                  handleShow(activity, true);
                }
              }}
              className={`${
                activity?.OnTime === null
                  ? "btn mr-1 btn-sm btn-outline-light"
                  : activity?.OnTime === 1
                  ? "btn mr-1 btn-sm btn-outline-success"
                  : activity?.OnTime === 0
                  ? "btn mr-1 btn-sm btn-outline-danger"
                  : "btn mr-1 btn-sm btn-outline-warning"
              }`}
              disabled={
                key !== "Last" &&
                !activity?.StartDate /* && (
                          activity?.ActivityTypeId === 1 ||
                          activity?.ActivityTypeId === 2) */
                  ? false
                  : true
              }
            >
              <div className="font-size-h4 font-weight-bold">
                {activity?.ActivityDateFullDate &&
                  format(parseISO(activity?.ActivityDateFullDate), "dd")}
              </div>
              <div>
                {activity?.ActivityDateFullDate &&
                  format(parseISO(activity?.ActivityDateFullDate), "MMM", {
                    locale: esLocale,
                  })}
              </div>
            </button>
          );
        },
      },
    },
    {
      name: "ProspectName",
      label: "Nombre",
      options: {
        filter:
          user.Id === activities[0]?.CreatedUserId || user?.RoleId === 1
            ? true
            : false,
        sort: true,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          let activity = activities[dataIndex];
          return (
            <>
              <a
                href={`/prospect/${activity?.ProspectId}/detail`}
                className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
              >
                {activity?.ProspectName}
              </a>
              <br />
              {activity?.Email && (
                <span className="text-muted font-weight-500">
                  {activity?.Email}
                </span>
              )}
              <br />
              {activity?.MobilePhone && (
                <a
                  className="text-muted font-weight-500"
                  href={`tel:${activity?.MobilePhone}`}
                >
                  {activity?.MobilePhone}
                </a>
              )}
              <br />
              {activity?.ProspectionMean && (
                <span className="text-muted">
                  {" "}
                  <PersonPinCircleIcon style={{ fontSize: "24px" }} />
                  {activity?.ProspectionMean}
                </span>
              )}
            </>
          );
        },
      },
    },
    {
      name: "ProspectionMean",
      label: "Medio de prospection",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: false,
      },
    },
    {
      name: "Email",
      label: "Correo electrónico",
      options: {
        filter:
          user.Id === activities[0]?.CreatedUserId || user?.RoleId === 1
            ? true
            : false,
        sort: true,
        empty: true,
        display: false,
        customBodyRenderLite: (dataIndex) => {
          return user.RoleId === activities[dataIndex]?.CreateUserId ||
            user.RoleId === 1
            ? activities[dataIndex]?.Email
            : "";
        },
      },
    },
    {
      name: "MobilePhone",
      label: "Teléfono",
      options: {
        filter:
          user.Id === activities[0]?.CreatedUserId || user?.RoleId === 1
            ? true
            : false,
        sort: true,
        empty: true,
        display: false,
        customBodyRenderLite: (dataIndex) => {
          return user.RoleId === activities[dataIndex]?.CreateUserId ||
            user.RoleId === 1
            ? activities[dataIndex]?.MobilePhone
            : "";
        },
      },
    },
    {
      name: "Manager",
      label: "Gerencia",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: user.RoleId < 5 ? true : user.RoleId === 13 ? true : false,
        customBodyRenderLite: (dataIndex) => {
          let activity = activities[dataIndex];
          return (
            <>
              {user.RoleId <= 5 && activity?.Manager && (
                <span className="text-dark-75 mb-1 font-weight-bold">
                  <SupervisorAccountIcon
                    style={{ fontSize: 30, padding: "5px" }}
                  />
                  {activity?.Manager}
                </span>
              )}
              <br />
              {user.RoleId <= 5 || user.RoleId === 13 ? (
                <span className="text-muted font-weight-500">
                  <PersonIcon style={{ fontSize: 30, padding: "5px" }} />
                  {activity?.AdvisorName}
                </span>
              ) : (
                <></>
              )}
            </>
          );
        },
      },
    },
    {
      name: "AdvisorName",
      label: "Asesor",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: false,
      },
    },
    {
      name: "ActivitySubject",
      label: "Asunto",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          let activity = activities[dataIndex];
          return (
            <>
              <span className="text-muted font-weight-bold">
                {activity?.ActivitySubject}
              </span>
              {activity?.Comments !== null && activity?.Comments.length > 0 ? (
                <>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="quick-panel-tooltip">
                        {activity?.Comments}
                      </Tooltip>
                    }
                  >
                    <div
                      className="text-muted font-weight-bold"
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      <InfoOutlinedIcon />
                      {` Ver comentarios`}
                    </div>
                  </OverlayTrigger>
                </>
              ) : (
                ""
              )}
            </>
          );
        },
      },
    },
    {
      name: "ActivityType",
      label: "Actividad",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          let activity = activities[dataIndex];
          return (
            <>
              {activity?.ActivityTypeId === 1 ? (
                <a
                  href={`https://www.google.com/maps/place/${activity?.Address}`}
                  target="_blank"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <span className="label label-xl label-light-danger label-inline">
                    <TodayIcon />
                    &nbsp;
                    {activity?.ActivityType}
                  </span>
                  <span
                    className="label label-xl label-light-default label-inline"
                    style={{ marginLeft: "0.5rem" }}
                  >
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="quick-panel-tooltip">
                          {activity?.Address}
                        </Tooltip>
                      }
                    >
                      <RoomOutlinedIcon />
                    </OverlayTrigger>
                  </span>
                </a>
              ) : activity?.ActivityTypeId === 2 ? (
                <a href={`tel:${activity?.MobilePhone}`}>
                  <span className="label label-xl label-light-primary label-inline">
                    <CallIcon />
                    &nbsp;
                    {activity?.ActivityType}
                  </span>
                </a>
              ) : activity?.ActivityTypeId === 3 ? (
                <a href={`mailto:${activity?.Email}`}>
                  <span className="label label-xl label-light-dark label-inline">
                    <MailOutlineIcon />
                    &nbsp;
                    {activity?.ActivityType}
                  </span>
                </a>
              ) : activity?.ActivityTypeId === 4 ? (
                <a
                  href={`https://api.whatsapp.com/send?phone=+52${activity?.MobilePhone}&text=En%20*J.%20Garc%C3%ADa%20L%C3%B3pez*%20estamos%20muy%20contentos%20de%20poder%20apoyarte.%20`}
                  target="_blank"
                >
                  <span className="label label-xl label-light-success label-inline">
                    <WhatsAppIcon />
                    &nbsp;
                    {activity?.ActivityType}
                  </span>
                </a>
              ) : (
                <a
                  href={`sms:/${activity?.MobilePhone}&body=En%20*J.%20Garc%C3%ADa%20L%C3%B3pez*%20estamos%20muy%20contentos%20de%20poder%20apoyarte.%20`}
                  target="_blank"
                >
                  <span className="label label-xl label-light-success label-inline">
                    <ChatOutlinedIcon />
                    &nbsp;
                    {activity?.ActivityType}
                  </span>
                </a>
              )}
            </>
          );
        },
      },
    },
    {
      name: "StartTime",
      label: "Horario",
      options: {
        filter: false,
        sort: true,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          let activity = activities[dataIndex];
          return (
            <span className="label-lg label-inline">
              <AccessTimeIcon />
              {activity?.StartTime}
              {` - `}
              {activity?.EndTime}
            </span>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          let activity = activities[dataIndex];
          return (
            <>
              {differenceInDays(
                dueDate,
                parseISO(activity?.ActivityDateFullDate)
              ) <= 2 ? (
                <td className="text-right pr-0">
                  {activity?.StartDate ? (
                    <a
                      onClick={() => handleShow(activity)}
                      className="btn btn-success btn-sm mx-2"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <DoneAllIcon />
                      </span>
                      Finalizar
                    </a>
                  ) : (
                    <a
                      onClick={(event) =>
                        handleStartActivity(event, activity?.Id, activity)
                      }
                      className="btn btn-primary btn-sm mx-2"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <KeyboardArrowRightIcon />
                      </span>
                      Iniciar
                    </a>
                  )}
                </td>
              ) : (
                <>
                  <td className="text-center">
                    <span className="py-2 font-weight-bold text-danger">
                      No atendido a tiempo
                    </span>
                  </td>
                  <td className="text-center">
                    {activity?.StartDate ? (
                      <a
                        onClick={() => handleShow(activity)}
                        className="btn btn-success btn-sm mx-2"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <DoneAllIcon />
                        </span>
                        Finalizar
                      </a>
                    ) : (
                      <button
                        onClick={() => handleShow(activity)}
                        className="btn btn-warning btn-sm mx-2"
                      >
                        Nueva Actividad
                      </button>
                    )}
                  </td>
                </>
              )}
            </>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: true,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          let activity = activities[dataIndex];
          return (
            <>
              {activity?.ActivitySubject === "COTIZACION" && (
                <button
                  onClick={() =>
                    history.push({
                      pathname: "/quotation/add",
                      search: `?ActivityId=${activity?.Id}&ProspectId=${activity?.ProspectId}`,
                    })
                  }
                  className="btn btn-info btn-sm mx-2"
                >
                  <span className="svg-icon svg-icon-sm svg-icon-primary">
                    <ShoppingCartIcon />
                  </span>
                  Cotizar
                </button>
              )}
            </>
          );
        },
      },
    },
  ];

  return (
    <div className={`card card-custom ${className}`}>
      <GlobalSnackbar message={message} handleClose={handleClose} />
      {/* Head */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Actividades{" "}
            {(() => {
              switch (key) {
                case "Day":
                  return "de hoy";
                case "Week":
                  return "de la semana";
                case "Month":
                  return "futuras";
                case "Last":
                  return "sin realizar";
                default:
                  return "";
              }
            })()}
          </span>
        </h3>
        <div className="card-toolbar">
          <Tab.Container defaultActiveKey={key}>
            <Nav
              as="ul"
              onSelect={(_key) => setKey(_key)}
              className="nav nav-pills nav-pills-sm nav-dark-75"
            >
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Last"
                  className={`nav-link py-2 px-4 ${
                    key === "Last" ? "active" : ""
                  }`}
                >
                  Sin realizar
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Day"
                  className={`nav-link py-2 px-4 ${
                    key === "Day" ? "active" : ""
                  }`}
                >
                  Hoy
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Week"
                  className={`nav-link py-2 px-4 ${
                    key === "Week" ? "active" : ""
                  }`}
                >
                  Semana
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Month"
                  className={`nav-link py-2 px-4 ${
                    key === "Month" ? "active" : ""
                  }`}
                >
                  Futuras
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>
      </div>
      {/* Body */}
      <div className={`card-body pt-3 pb-0 pr-1 pl-2 ${classes.container}`}>
        <div className="table-responsive">
          <CustomTable
            data={activities}
            columns={headRows}
            handleActionClick={() => {}}
            title="Actividades"
            actionAdd={false}
            dateRange={false}
            handleCallback={() => {}}
          />
        </div>
      </div>
      {showModal && (
        <CustomModal
          keyLast={key === "Last" ? true : false}
          show={showModal}
          title={
            reschedule
              ? "Reagendar actividad"
              : key !== "Last" || currentActivity?.StartDate
              ? "Finalizar actividad"
              : "Nueva actividad"
          }
          activity={currentActivity}
          fetchActivities={fetchActivities}
          handleClose={handleClose}
          reschedule={reschedule}
        />
      )}

      <QuotationConfirmationDialog
        open={openQuotationConfirmation}
        handleCloseQuotation={handleCloseQuotation}
        handleCloseYes={handleCloseQuotationYes}
      />
    </div>
  );
}
