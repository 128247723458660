/* import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import scriptLoader from "react-async-script-loader";
import TextField from "@material-ui/core/TextField";
/ * import { numberFormat } from "../../../../functions"; * /
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import api from "../../../../api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { format, parseISO } from "date-fns";

const mapContainerStyle = {
  height: "600px",
};
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};
const center = {
  lat: 19.4319766,
  lng: -99.1356141,
};

function UsersMap({
  isScriptLoaded,
  isScriptLoadSucceed,
  selectedItem,
  inputQuantity,
  setSelectedItem,
  inputComments,
}) {
  const [address, setAddress] = React.useState("");
  const [markers, setMarkers] = React.useState([]);
  const [activeMarker, setActiveMarker] = useState(null);
  const [data, setData] = React.useState();
  const [selected, setSelected] = React.useState(null);
  const [distance, setDistance] = React.useState({});
  const [advisers, setAdvisers] = useState([]);
  const [salesAdvisors, setSalesAdvisors] = useState([]);
  const [managements, setManagements] = useState([]);
  const [managementsId, setManagementsId] = useState([]);

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const fetchManagements = () => {
    api.get("catalog/managements").then(
      (result) => {
        setManagements(result.data.body);
      },
      (error) => {
        setManagements([]);
      }
    );
  };
  const fetchAdvisers = () => {
    let managements = managementsId.map((item) => item.ManagementId);
    api
      .get("catalog/advisers", {
        params: {
          Management: managements,
        },
      })
      .then(
        (result) => {
          setAdvisers(result.data.body);
        },
        (error) => {
          setAdvisers([]);
        }
      );
  };
  const handleManagementChange = (event, value) => {
    if (value !== null) {
      setManagementsId(value);
      setSalesAdvisors([]);
    } else {
      setManagementsId(undefined);
    }
  };
  const handleAdviserChange = (event, value) => {
    if (value !== null) {
      setSalesAdvisors(value);
    } else {
      setSalesAdvisors(undefined);
    }
  };
  useEffect(() => {
    fetchManagements();
  }, []);
  useEffect(() => {
    fetchAdvisers();
  }, [managementsId]);

  useEffect(() => {
    fetchUserLocations();
  }, [salesAdvisors, managementsId]);

  const fetchUserLocations = () => {
    let ids = salesAdvisors.map((item) => item.SlpCode);
    let managements = managementsId.map((item) => item.ManagementId);
    let payload = {
      SlpCode: ids,
      Management: managements,
    };
    return api
      .get(`user/location/last-users-location?`, {
        params: payload,
      })
      .then(
        (result) => {
          if (result.status === 201) {
            let data = [];
            let algo = result.data.body?.map((item) => {
              item = {
                ...item,
                position: {
                  lat: parseFloat(item.Latitude),
                  lng: parseFloat(item.Longitude),
                },
              };
              data.push(item);
            });
            setMarkers(data);
            return result.data.body;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    fetchUserLocations();
  }, []);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  if (isScriptLoaded && isScriptLoadSucceed) {
    return (
      <Paper style={{ width: "100%", padding: "1rem" }}>
        <Typography variant="h4" color="secondary" gutterBottom>
          Ubicación de usuarios
        </Typography>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3
            className="card-title align-items-start flex-column"
            style={{ width: "20%" }}
          >
            <span className="card-label font-weight-bolder text-dark">
              Filtros
            </span>
          </h3>
          <Autocomplete
            style={{ width: "40%" }}
            multiple
            size="small"
            id="multiple-limit-tags"
            options={managements}
            getOptionLabel={(option) => option.ManagementName}
            onChange={handleManagementChange}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Gerencia" />
            )}
          />
          <Autocomplete
            style={{ width: "40%" }}
            name="SlpCode"
            multiple
            size="small"
            options={advisers}
            getOptionLabel={(option) =>
              `${option.EmployeeId} - ${option.Adviser}`
            }
            onChange={handleAdviserChange}
            value={salesAdvisors}
            renderInput={(params) => (
              <TextField {...params} label="Asesor" variant="outlined" />
            )}
          />
        </div>

        <GoogleMap
          id="map"
          mapContainerStyle={mapContainerStyle}
          zoom={8}
          center={center}
          options={options}
          onLoad={onMapLoad}
        >
          {markers.map(({ Id, Asesor, position, DateLocation, AppVersion, Device, SubDirector, Gerente }) => (
            <Marker
              key={Id}
              position={position}
              onClick={() => handleActiveMarker(Id)}
            >
              {activeMarker === Id ? (
                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                  <>
                    <div><b>Asesor </b>{Asesor}</div>
                    <div><b>Fecha inicio de sesión </b>{DateLocation ? format(parseISO(DateLocation), "dd/MM/yyyy hh:mm aaaa") : "Sin información"}</div>
                    <div><b>Gerente </b>{Gerente}</div>
                    <div><b>SubDirector </b>{SubDirector}</div>
                    <div><b>Versión de aplicación </b>{AppVersion}</div> 
                    <div><b>Dispositivo </b>{Device}</div>

                  </>
                </InfoWindow>
              ) : null}
            </Marker>
          ))}
        </GoogleMap>
      </Paper>
    );
  } else {
    return <div></div>;
  }
}

export default scriptLoader([
  `https://maps.googleapis.com/maps/api/js?key=AIzaSyA_eFfk9y7Oe_dD6KGWPZGqByUcYNwoX4c&libraries=places`,
])(UsersMap);
 */







/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

export function StatsHeatMapByCounty({ className, topHeatMapCounty, startDate,
  endDate }) {
  let series = [];
  let categories = [];
  if (topHeatMapCounty && topHeatMapCounty.length > 0) {
    topHeatMapCounty.map( (data) => {
      series.push( data.SalesCount );
      categories.push( `${data.StateCode} - ${data.County}` );
    });
  }
  
  useEffect(() => {
    const element = document.getElementById("kt_stats_topHeatMapCounty");
    if (!element) {
      return;
    }

    const options = {
      series: [
        {
          name: "Mapa de Calor por Alcaldia",
          data: series,
        },
      ],
      chart: {
        type: "bar",
        maxHeight: "fit-content",
        zoom: {
          enabled: false,
        },
      },

      colors: ["#ff92ed"],
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          return val;
        },
        offsetX:  30,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          colors: ["#000"]
        },
      },
      legend: {
        show: true,
      },
      stroke: {
        curve: "straight",
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: function(val) {
            return val;
          },
        },
      },

      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
    };
    try {
      if (topHeatMapCounty) {
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
          chart.destroy();
        };
      }
    } catch {}
  }, [topHeatMapCounty]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
        <div className="d-flex flex-row justify-content-between mr-2" style={{width: "100%"}}>
            <span
              className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5"
            >
              Mapa de Calor por Alcaldia
            </span>
            <span className="text-dark-75 text-hover-primary">
              Del {moment(startDate).format("DD/MM/yyyy")} al {moment(endDate).format("DD/MM/yyyy")}
            </span>
          </div>
        </div>
        <div className="flex-grow-1">
          {topHeatMapCounty ? (
            <div
              id="kt_stats_topHeatMapCounty"
              className="card-rounded-bottom"
              style={{ height: "230px" }}
            ></div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
