import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSubheader } from "../../../_metronic/layout";
import api from "../../../api";
import CustomTable from "../../../app/components/CustomTable";
import CustomWeekPicker from "../../../app/components/CustomWeekPicker";
import { format, parseISO, sub } from "date-fns";
import startOfWeek from "date-fns/startOfWeek";
import { numberFormat } from "../../../functions";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SignaturePad from "react-signature-canvas";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const useStyles = makeStyles((theme) => ({
  signatureCanvas: {
    border: "2px solid #000",
    background: "#ffffff",
    minWidth: "100%",
    maxWidth: "100vw",
    minHeith: "80vh",
    zIndex: 99999,
  },
}));

export function CommissionsBonusesAnalyticsReport() {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Bonos y comisiones");
  const { user } = useSelector((state) => state?.auth);
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [startDate, setStartDate] = useState();
  const [totales, setTotales] = useState([]);
  const [cash, setCash]= useState({});
  const [totalDownPayment, setTotalDownPayment] = useState({})
  const [advisers, setAdvisers] = useState([]);
  const [salesAdvisors, setSalesAdvisors] = useState({});
  const [managements, setManagements] = useState({});
  const [managementsId, setManagementsId] = useState({});
  const [open, setOpen] = useState(false);
  const [imageURL,setImageURL] = useState();
  const [status, setStatus] = useState(""); 
  const [weeks, setWeeks] = useState([]) 
  const sigCanvas = useRef({});
  const clear = () => sigCanvas.current.clear();


  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    api.post("bi/analytics-auth", {
      WeekNumber: startDate,
      Status: "A",
      Signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")

    }).then(
      (result) => {
        setOpen(false);
        fetchRows();
      },
      (error) => {
        console.log([]);
      }
    );
  };
  const handleConcilacion = () => {
    api.post("bi/analytics-auth", {
      WeekNumber: startDate,
      Status: "R",
    }).then(
      (result) => {
        setOpen(false);
        fetchRows();
      },
      (error) => {
        console.log([]);
      }
    );
  }

  
  const handleDownloadTrainer = () =>{
    api
      .get("bi/down-analytics-trainer", {
        responseType: "blob",
        params: {
          WeekNumber: startDate ,
          SlpCode: salesAdvisors.SlpCode
        },
      })
      .then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${salesAdvisors.Name ? salesAdvisors.Name : `${user?.Employee?.FirstName}_${user?.Employee?.LastName}`}_${startDate}_comisiones.pdf`);
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          console.log(error)
        }
      );
  }


  const handleDownloadManager = () =>{
    api
      .get("bi/down-analytics-manager", {
        responseType: "blob",
        params: {
          WeekNumber: startDate ,
          Management:[managementsId?.ManagementId]
        },
      })
      .then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${salesAdvisors.Name ? salesAdvisors.Name : `${user?.Employee?.FirstName}_${user?.Employee?.LastName}`}_${startDate}_comisiones.pdf`);
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          console.log(error)
        }
      );
  }

  const handleDownloadCanalManager = () =>{
    api
      .get("bi/down-analytics-canalmanager", {
        responseType: "blob",
        params: {
          WeekNumber: startDate ,
          Management:[managementsId?.ManagementId]
        },
      })
      .then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${salesAdvisors.Name ? salesAdvisors.Name : `${user?.Employee?.FirstName}_${user?.Employee?.LastName}`}_${startDate}_comisiones.pdf`);
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          console.log(error)
        }
      );
  }

  const handleDownloadSubdirector = () =>{
    api
      .get("bi/down-analytics-subdirector", {
        responseType: "blob",
        params: {
          WeekNumber: startDate ,
          Viceprincipal: salesAdvisors.Name ? salesAdvisors.Name : `${user?.Employee?.FirstName} ${user?.Employee?.LastName}`
        },
      })
      .then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${salesAdvisors.Name ? salesAdvisors.Name : `${user?.Employee?.FirstName}_${user?.Employee?.LastName}`}_${startDate}_comisiones.pdf`);
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          console.log(error)
        }
      );
  }


  const handleDownload = () =>{
    api
      .get("bi/down-analytics-auth", {
        responseType: "blob",
        params: {
          WeekNumber: startDate ,
          SlpCode: salesAdvisors.SlpCode
        },
      })
      .then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${salesAdvisors.Name ? salesAdvisors.Name : `${user?.Employee?.FirstName}_${user?.Employee?.LastName}`}_${startDate}_comisiones.pdf`);
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          console.log(error)
        }
      );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchManagements = () => {
    api.get("catalog/managements").then(
      (result) => {
        setManagements(result.data.body);
      },
      (error) => {
        setManagements([]);
      }
    );
  };
  const fetchAdvisers = () => {
    api
      .get("catalog/advisers", {
        params: {
          Management:[managementsId?.ManagementId]  ,
        },
      })
      .then(
        (result) => {   
          result.data.body.push( { EmployeeId: user.EmployeeId, SlpCode: user.Employee.SlpCode, Adviser: user.Name } );
          //console.log( "ASOSORES >>>>>>>>>", result.data.body, "USUARIO >>>>>>>>>", user );
          setAdvisers(result.data.body);
        },
        (error) => {
          setAdvisers([]);
        }
      );
  };
  const fetchWeeks = () => {
    api
      .get("catalog/weeks")
      .then(
        (result) => {
          setWeeks(result.data.body);
        },
        (error) => {
          setWeeks([]);
        }
      );
  };

  const handleManagementChange = (event, value) => {
    if (value !== null) {      
      setManagementsId(value);
      setSalesAdvisors({})
    } else {
      setManagementsId(undefined);
    }
  };
  const handleAdviserChange = (event, value) => {
    if (value !== null) {
      setSalesAdvisors(value);
    } else {
      setSalesAdvisors(undefined);
    }
  };
  const handleWeekChange = (event, value) => {
    if (value !== null) {
      setStartDate(value);
    } else {
      setStartDate(undefined);
    }
  };

  useEffect(() => {
    fetchManagements();
    fetchAdvisers();
    fetchWeeks();
  }, []);

  useEffect(() => {
    fetchAdvisers();
  }, [managementsId]);

  const fetchRows = () => {
    api.get("bi/commissions-bonuses-analytics", {
      params: {
        WeekNumber: startDate,
        SlpCode: salesAdvisors.SlpCode,
        ManagerId: managementsId?.ManagementId,
      }
    }).then(
      (result) => {
        if(Array.isArray(result?.data?.body?.Data)){
            let data = result?.data?.body?.Data?.map(item=> {
            let uno = {...item, COM_TOTAL: item.["  COM_TOTAL"], TIPO: item.TIPO === "ENGANCHE" ? "NUEVA VENTA" : item.TIPO } 
            return uno
            }
            );
            let resArr = [];
            data.filter(function(item){
              var i = resArr.findIndex(x => (x.TIPO == item.TIPO));
              if(i <= -1){
                    resArr.push(item);
              }
              return null;
            });
            let orderByTipo = []
            let all = []
            let otro =  resArr.map( uniqueItem => { 
            let resultData  = data.filter(item => item.TIPO === uniqueItem.TIPO )
            let resultTotal = data.filter(item => item.TIPO === uniqueItem.TIPO ).reduce(function(_total, _item) {
              return _total + parseFloat( _item.PAGAR ?? 0)
              }, 0).toFixed(2);

            orderByTipo.push(...resultData); 
            let name = {
              name: uniqueItem.TIPO,
              amount: resultTotal}
            all.push(name) 
                  return {group:resultData, [uniqueItem.TIPO]: resultTotal}
            }) 

            let cash = {
              name: "TOTAL A PAGAR",
              amount:data.reduce(function(_total, _item) {
              return _total + parseFloat( _item.PAGAR ?? 0)
              }, 0).toFixed(2),
            }
            let totalDownPayment = {
              name: "MONTO POR VENTA NUEVA",
              amount:data.reduce(function(_total, _item) {
              return _total + parseFloat( _item.["MONTO POR VENTA NUEVA"] ?? 0)
              }, 0).toFixed(2),
            }

            setTotalDownPayment(totalDownPayment)
            setCash(cash) 

            setTotales(all)
        setRows(orderByTipo);
         
      }else{
        setRows([])
        setTotales([])
        setTotalDownPayment({})
        setCash({}) 
      }
      setStatus(result?.data?.body?.Status)

        },
      (error) => {
        setRows([]);
        console.log(error);
      }
    );
  };
  useEffect(() => {
		if( startDate){
			fetchRows();
		}
    return () => {
    }
  }, [salesAdvisors?.SlpCode, startDate]);

  const headRows = [
    {name: "TIPO", label: "TIPO",
    options: {
        filter: true,
        customBodyRender: (value) => {
          return value === "ENGANCHE" ? "NUEVA VENTA "+value : value;
        },
      },},
    {name: "CONTRATO", label: "CONTRATO"},
    {name: "SEMANAVTA", label: "SEMANA VENTA"},
    {name: "CLIENTE", label: "CLIENTE"},
    {name: "MONTO POR VENTA NUEVA", label: "MONTO POR VENTA NUEVA",
    options: {
        filter: false,
        customBodyRender: (value) => {
          return numberFormat(value) ;
        },
      },
    },
    {name: "COBROSINIVA", label: "MONTO POR VENTA SIN IMPUESTO",
    options: {
            filter: false,
            customBodyRenderLite: (dataIndex) => {
                return (        
                    numberFormat((rows[dataIndex].["MONTO POR VENTA NUEVA"]) /1.16)
                );
            },
        },
    },
    {name: "COM_TOTAL", label: "COMISION / BONO TOTAL",
options: {
        filter: false,
        customBodyRender: (value) => {
          return numberFormat(value) ;
        },
      },},
    {name: "COBROCONIVA", label: "MONTO PAGO INGRESADO",
    options: {
            filter: false,
            customBodyRender: (value) => {
            return numberFormat(value) ;
            },
        },
    },
    {name: "COM_VTA_NVA", label: "COMISIONES / BONO VENTAS NUEVAS",
    options: {
            filter: false,
            customBodyRender: (value) => {
            return numberFormat(value) ;
            },
        },
    },
    {name: "COM_MENSUALIDAD", label: "COMISIONES / BONO MENSUALIDADES",
options: {
        filter: false,
        customBodyRender: (value) => {
          return numberFormat(value) ;
        },
      },},
    {name: "COM_PENDIENTE", label: "COMISIÓN RESTANTE",
    options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
            if(rows[dataIndex].TIPO === "NUEVA VENTA"){
                return (        
                    numberFormat((rows[dataIndex].COM_TOTAL ?? 0)-(rows[dataIndex].PAGAR ?? 0) )
                  );
            }else{
                return (        
                    numberFormat((rows[dataIndex].COM_TOTAL ?? 0) )
                  );
            }
        },
      },},
    {name: "CLAVE VENDEDOR", label: "BONO MENSUAL",
    options: {
        filter: false,
        customBodyRender: (value) => {
          return numberFormat(value) ;
        },
      },},
    {name: "PAGAR", label: "TOTAL POR PAGAR",
    options: {
        filter: false,
        customBodyRender: (value) => {
          return numberFormat(value) ;
        },
      },},
  ];
  
  return (
		<div className="card">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Firma de conformidad de bonos y comisiones"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Yo {user?.Employee?.FirstName} {user?.Employee?.LastName} acepto el pago de {numberFormat(cash.amount ?? 0)} de la semana {startDate}
          </DialogContentText>
          {!imageURL ? (
              <>
                <SignaturePad
                  ref={sigCanvas}
                  canvasProps={{
                    className: classes.signatureCanvas,
                  }}
                />
                
                <Button variant="contained" onClick={clear}>
                  Limpiar
                </Button>
              </>
            ) : (
              <></>
            )}


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={save} color="primary" autoFocus>
            Acepto
          </Button>
        </DialogActions>
      </Dialog>
		<div className="card-header border-0 pt-5 bg-light">
			<div className="row align-items-center">
            <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xxl-2 mb-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Filtros
                </span>
              </h3>
            </div>
            {user.RoleId < 8 && (<>
             <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xxl-4 mb-5">
              <Autocomplete                               
                size="small"
                id="multiple-limit-tags"
                options={managements}
                getOptionLabel={(option) => option.ManagementName}
                onChange={handleManagementChange}                
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Gerencia"
                    placeholder="Gerencia"
                  />
                )}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xxl-3 mb-5">
              <Autocomplete
                name="SlpCode"
                size="small"
                options={advisers}
                getOptionLabel={(option) =>
                  `${option.EmployeeId ?? ""} - ${option.Adviser ?? ""}`
                }
                onChange={handleAdviserChange}
                value={salesAdvisors}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="SlpCode"
                    label="Asesor"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
            </div>
             </>)}
            <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xxl-3 mb-5">
             <Autocomplete
                name="week"
                size="small"
                options={weeks}
                getOptionLabel={(option) =>
                  `${option?? ""}`
                }
                onChange={handleWeekChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="week"
                    label="Semana"
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />

              {/* <CustomWeekPicker
                setStartDate={setStartDate}
                startDate={startDate}
              />  */}
            </div>
            <div className="col-md-12 col-lg-12 col-xxl-12 mb-5 bg-success rounded-sm py-5">
              <div className="row m-0">
                <div className="col px-6 py-2 rounded-xl">
                  <h3 className="card-title font-weight-bolder my-0 text-white text-center">
                    REPORTE ANALITICO DE COMISIONES Y BONOS
                  </h3>
                  {rows.length > 0 && (
                    <h3 className="card-title font-weight-bolder my-3 text-white text-center">
                      De la semana {rows[0]?.SEMANA} del periodo {format(parseISO(rows[0]?.FECHA_INI ) ?? new Date(), "dd/MM/yyyy")} al {format(parseISO(rows[0]?.FECHA_FIN) ?? new Date(), "dd/MM/yyyy")} 
                    </h3>  
                  )}
                    
                  <h3 className="card-title font-weight-bolder my-5 text-black text-center">
                    {rows[0]?.Vendedor}
                  </h3>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col px-6 py-2 rounded-xl">
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>
                  <h3 className="card-title font-weight-bolder my-0 text-white">
                   {cash.name}
                  </h3>
                  <span className="svg-icon svg-icon-3x svg-icon-warning d-block"></span>

                  <span className="text-white font-weight-bolder font-size-h1">
                    <i className="flaticon-coins display-4 text-white font-weight-bold"></i>{" "}
                   {numberFormat(cash.amount ?? 0)}
                  </span>
                </div>
                
                <div className="col px-6 py-2 rounded-xl text-right">
                {user.RoleId == 8 && status === "P" || user.RoleId == 9 && status === "P" ? (
                  <>
                    <Button  variant="contained" color="secondary" onClick={handleClickOpen}>
                      Firma de conformidad
                    </Button>
                    <Button className="ml-6" variant="outlined" color="secondary" onClick={handleConcilacion}>
                      Conciliación
                    </Button>
                  </>
                ) : <>
                <span className="text-white font-weight-bolder font-size-h2 mr-6">
                        {status === "A"? "Aceptada": status === "R"? "En conciliación" : "Pendiente"}                      
                      </span>
                </>}
                </div>                
              </div>
              <div className="row m-0 mb-5">
                <div className="col px-2 py-2 rounded-xl text-right">
                  <Button className="" variant="outlined" color="secondary" 
                    endIcon={<ArrowDownwardIcon />}
                    onClick={handleDownload}>
                          Descargar PDF
                  </Button>
                </div>
                {
                  user.RoleId === 7 ? (
                  <div className="col px-2 pl-2 pr-6 rounded-xl text-right">
                  <Button className="" variant="outlined" color="secondary" 
                    endIcon={<ArrowDownwardIcon />}
                    onClick={handleDownloadTrainer}>
                          Descargar PDF Entrenador
                  </Button>
                </div>) : <></>
                }
                {
                  user.RoleId === 1 || user.RoleId === 6 || user.RoleId === 7 ? (
                  <div className="col px-2 pl-2 pr-6 rounded-xl text-right">
                    <Button className="" variant="outlined" color="secondary" 
                      endIcon={<ArrowDownwardIcon />}
                      onClick={handleDownloadManager}>
                          Descargar PDF Gerente
                    </Button>
                  </div>) : <></>
                }
                {
                  user.RoleId === 1 || user.RoleId === 5 ? (
                  <div className="col px-2 pl-2 pr-6 rounded-xl text-right">
                    <Button className="" variant="outlined" color="secondary" 
                      endIcon={<ArrowDownwardIcon />}
                      onClick={handleDownloadCanalManager}>
                          Descargar PDF Gerente de Canal
                    </Button>
                  </div>) : <></>
                }
                {
                  user.RoleId === 1 || user.RoleId === 4 ? (
                  <div className="col px-2 pl-2 pr-6 rounded-xl text-right">
                    <Button className="" variant="outlined" color="secondary" 
                      endIcon={<ArrowDownwardIcon />}
                      onClick={handleDownloadSubdirector}>
                          Descargar PDF Subdirector
                    </Button>
                  </div>) : <></>
                }

              </div>
              <div className="row m-0">
                {totales.map((total, index) => (
                  <div className="col-sm-3" key={`total,${index}`}>
                  <div className="bg-light-warning px-6 py-8 rounded-xl mb-7">
                  <span className="text-warning font-weight-bold font-size-h6">
                      Comisión
                    </span>
                    <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                     <span className="text-warning font-weight-bolder font-size-h1">
                        {numberFormat(total.amount ?? 0)}                        
                      </span>
                    </span>
                    <span className="text-warning font-weight-bold font-size-h6">
                      Total {total.name}
                    </span>
                  </div>
                  </div>
               ))}
               <div className="col-sm-3">
                  <div className="bg-light-primary px-6 py-8 rounded-xl mb-7">
                    <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                     <span className="text-primary font-weight-bolder font-size-h1">
                        {numberFormat(totalDownPayment.amount ?? 0)}                        
                      </span>
                    </span>
                    <span className="text-primary font-weight-bold font-size-h6">
                      Total {totalDownPayment.name}
                    </span>
                  </div>
                  </div>
              </div>
            </div> 
			  </div>
			</div>
      <CustomTable
      data={rows}
      columns={headRows}
      title={suhbeader.title}
      actionAdd={false}
      dateRange={false}
    />
      </div>
    
  );
}

export default CommissionsBonusesAnalyticsReport;