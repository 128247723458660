import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { injectIntl, FormattedMessage } from "react-intl";

function QuotationConfirmationDialog(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id="TEXT.QUOTATION.CONFIRMATION" />
        </DialogTitle>
        <DialogActions>
          <Button onClick={props.handleCloseYes} color="primary">
            <FormattedMessage id="TEXT.YES" />
          </Button>
          <Button onClick={props.handleClose} color="primary" autoFocus>
            <FormattedMessage id="TEXT.NO" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default injectIntl(QuotationConfirmationDialog);
