/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { numberFormat } from "../../../../functions";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

export function ProfileReport(props) {
  const {
    amountData,
    activitiesCountData,
   /*  tipifyTracingsCount,
    prospectionMeansCount, */
  } = props;
  return (
    <>
      <div className="d-flex mb-9">
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between flex-wrap mt-1">
            <div className="d-flex mr-3">
              <span className=" text-primary font-size-h5 font-weight-bold mr-3">
                Por actividad
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap mt-8">
        {activitiesCountData &&
          activitiesCountData.length > 0 &&
          activitiesCountData.map((element) => (
            <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2" key={element.Name}>
              <span className="mr-4">
                {element.Name === "SMS" ? (
                  <i className="flaticon-chat-1 display-4 text-muted font-weight-bold"></i>
                ) : element.Name === "LLAMADA" ? (
                  <i className="flaticon-support display-4 text-muted font-weight-bold"></i>
                ) : element.Name === "CITA" ? (
                  <i className="flaticon-calendar-1 display-4 text-muted font-weight-bold"></i>
                ) : element.Name === "CORREO" ? (
                  <i className="flaticon-email display-4 text-muted font-weight-bold"></i>
                ) : (
                  <WhatsAppIcon className="display-4 text-muted" />
                )}
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span
                  className="font-weight-bolder font-size-sm"
                  style={{ textTransform: "capitalize", "&::firstLetter":{textTransform: "uppercase"} }}
                >
                  {element.Name}
                </span>
                <span className="font-weight-bolder font-size-h5 text-dark-50">
                  {element.Count}
                </span>
              </div>
            </div>
          ))}
      </div>
      <div className="separator separator-solid"></div>
      <div className="d-flex mb-9">
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between flex-wrap mt-1">
            <div className="d-flex mr-3">
              <span className=" text-primary font-size-h5 font-weight-bold mr-3">
                Por monto
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap mt-8">
        {amountData &&
          amountData.length > 0 &&
          amountData.map((element) => (
            <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2" key={element.Name}>
              <span className="mr-4">
                {element.Name === "Cotizado" ? (
                  <i className="flaticon-file-2 display-4 text-muted font-weight-bold"></i>
                ) : (
                  <i className="flaticon-piggy-bank display-4 text-muted font-weight-bold"></i>
                )}
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span className="font-weight-bolder font-size-sm">
                  {element.Name}
                </span>
                <span className="font-weight-bolder font-size-h5 text-dark-50">
                  {numberFormat(element.Amount)}
                </span>
                <span className="font-weight-bolder font-size-h5 text-dark-50">
                  de {element.Quantity}
                </span>
              </div>
            </div>
          ))}
      </div>
      <div className="separator separator-solid"></div>
     {/*  <div className="d-flex mb-9">
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between flex-wrap mt-1">
            <div className="d-flex mr-3">
              <span className=" text-primary font-size-h5 font-weight-bold mr-3">
                Por tipificación
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap mt-8">
        {tipifyTracingsCount &&
          tipifyTracingsCount.length > 0 &&
          tipifyTracingsCount.map((element) => (
            <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
              <span className="mr-4">
                <i className="flaticon-pie-chart display-4 text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span className="font-weight-bolder font-size-sm">
                  {element.Name}
                </span>
                <span className="font-weight-bolder font-size-h5 text-dark-50">
                  {element.Count}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="separator separator-solid"></div> */}
       {/*  <div className="d-flex mb-9">
          <div className="flex-grow-1">
            <div className="d-flex justify-content-between flex-wrap mt-1">
              <div className="d-flex mr-3">
                <span className=" text-primary font-size-h5 font-weight-bold mr-3">
                  Por medio de prospección
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center flex-wrap mt-8">
          {prospectionMeansCount &&
            prospectionMeansCount.length > 0 &&
            prospectionMeansCount.map((element) => (
              <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span className="mr-4">
                  <i className="flaticon-map-location display-4 text-muted font-weight-bold"></i>
                </span>
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-sm">
                    {element.Name}
                  </span>
                  <span className="font-weight-bolder font-size-h5 text-dark-50">
                    {element.Count}
                  </span>
                </div>
              </div>
            ))}
        </div>
      <div className="separator separator-solid"></div> */}
    </>
  );
}
