import clsx from "clsx";
import format from "date-fns/format";
import sub from "date-fns/sub";
import isValid from "date-fns/isValid";
import isSameDay from "date-fns/isSameDay";
import endOfWeek from "date-fns/endOfWeek";
import React, { PureComponent } from "react";
import startOfWeek from "date-fns/startOfWeek";
import isWithinInterval from "date-fns/isWithinInterval";
import { DatePicker } from "@material-ui/pickers";
import { createStyles } from "@material-ui/styles";
import { IconButton, withStyles } from "@material-ui/core";

class CustomWeekPicker extends PureComponent {
    
  state = {
    selectedDate: sub(new Date(), {days: 14})
  };

  handleWeekChange = date => {
    this.props.setStartDate(format(startOfWeek(date, {
        weekStartsOn: 3,
    }), "ww-yyyy"))
    this.setState({ selectedDate: date})
  };

  formatWeekSelectLabel = (date, invalidLabel) => {
    let dateClone = date
    return dateClone && isValid(dateClone)
      ? `Semana ${format(startOfWeek(dateClone, {
        weekStartsOn: 3,
    }), "ww")} del ${format(startOfWeek(dateClone, {
        weekStartsOn: 3,
    }), "dd/MM/yyyy")} al ${format(endOfWeek(dateClone, {
        weekStartsOn: 3,
    }), "dd/MM/yyyy")}`
      : invalidLabel;
  };

  renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const { classes } = this.props;
    let dateClone = date;
    let selectedDateClone = selectedDate

    
    const start = startOfWeek(selectedDateClone, {
        weekStartsOn: 3,
    });
    const end =endOfWeek(selectedDateClone, {
        weekStartsOn: 3,
    });

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, "dd")} </span>
        </IconButton>
      </div>
    );
  };

  render() {
    const { selectedDate } = this.state;
    

    return (
      <DatePicker
        label="Semana" 
        inputVariant="outlined"    
        size="small"       
        disableFuture
        minDate={ sub(new Date(), {
          days: 90,
        }) < new Date("11/10/2021") ? new Date("11/10/2021") : sub(new Date(), {
          days: 90,
        })}
        maxDate={
          sub(new Date(), {
            days: 14,
          })
        }
        value={selectedDate}
        onChange={this.handleWeekChange}
        renderDay={this.renderWrappedWeekDay}
        labelFunc={this.formatWeekSelectLabel}
      />
    );
  }
}

const styles = createStyles(theme => ({
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));

export default withStyles(styles)(CustomWeekPicker);
