import React, { useState, useEffect } from "react";
import api from "../../../api";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { format, isValid } from "date-fns";
import ActivityTimeLine from "../../../app/components/TimeLine";
import { makeStyles } from "@material-ui/core/styles";
import GlobalSnackbar from "../../../app/components/GlobalSnackbar";
import { isArray } from "lodash";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    overflowY: "auto",
    paddingRight: "1rem",
    "&:hover, &:focus": {
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        display: "inline-block",
      },
    },
    "&::-webkit-scrollbar": {
      width: "0.5rem",
      height: "0.5rem",
      display: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#ccc",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#b3b3b3",
      boxShadow: "0 0 2px 1px rgba(0, 0, 0, 0.2)",
    },
    "&::-webkit-scrollbar-thumb:active": {
      backgroundColor: "#999999",
    },
    "&::-webkit-scrollbar-track": {
      background: "#e1e1e1",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track:hover": {
      background: "#d4d4d4",
    },
    "&::-webkit-scrollbar-track:active": {
      background: "#d4d4d4",
    },
  },
}));

function CustomModal(props) {
  const classes = useStyles();
  const { show, title, activity, keyLast, prospectId, newActivity } = props;
  const [currentActivities, setCurrentActivities] = useState([]);
  const [selectedTipifyTracing, setSelectedTipifyTracing] = useState({});
  const [tipifyTracings, setTipifyTracings] = useState([]);
  const [tipifyTracingsReschedule, setTipifyTracingsReschedule] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);
  const [activitySubjects, setActivitySubjects] = useState([]);
  const [values, setValues] = useState({
    ActivityDate: new Date(),
    StartTime: new Date(),
    EndTime: new Date(),
  });
  const [filterActivitySubjects, setFilterActivitySubjects] = useState(0);
  const [hasErrors, setHasErrors] = useState({});
  const [message, setMessage] = useState({
    text: "",
    variant: "",
    open: false,
  });
  const [saved, setSaved] = useState(false);
  const temperatures = [
    {
      Name: "Alta",
      Code: "A",
    },
    {
      Name: "Media",
      Code: "M",
    },
    {
      Name: "Baja",
      Code: "B",
    },
  ];

  const fetchTipifyTracings = (activityTypeId) => {
    try {
      api.get(`catalog/tipify-tracings/?${activityTypeId}`).then(
        (result) => {
          if (result.data.status === 200) {
            let data = result.data.body;
            if (isArray(data)) {
              setTipifyTracings(
                data?.filter((element) => element.Reschedule === 0)
              );
              setTipifyTracingsReschedule(
                data?.filter((element) => element.Reschedule === 1)
              );
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch {}
  };

  function validate() {
    let validateHasError = {
      ActivityTypeId:
        values.ActivityTypeId && values.ActivityTypeId > 0 ? false : true,
      ActivitySubjectId:
        values.ActivitySubjectId && values.ActivitySubjectId > 0 ? false : true,
      ActivityDate:
        values.ActivityDate && values.ActivityDate.toString().length > 0
          ? false
          : true,
      StartTime:
        values.StartTime && values.StartTime.toString().length > 0
          ? false
          : true,
      EndTime:
        values.EndTime && values.EndTime.toString().length > 0 ? false : true,
    };

    if (selectedTipifyTracing && selectedTipifyTracing.FollowIt === 0) {
      var result = true;
    } else {
      if (activity) {
        if (!canActivity()) {
          return false;
        }
      }

      setHasErrors(validateHasError);
      var result = Object.values(validateHasError).every(
        (item) => item === false
      );
      return result;
    }
    return result;
  }

  function canActivity() {
    switch (values.ActivityTypeId) {
      case 1:
        return (activity && activity.Address && activity.Address.length > 0) ||
          (activity &&
            activity.Prospect &&
            activity.Prospect.Addresses &&
            activity.Prospect.Addresses.length > 0)
          ? true
          : (setMessage({
              text: "No es posible agendar una cita sin dirección.",
              variant: "warning",
              open: "open",
            }),
            false);
      case 2:
        return (activity &&
          activity.MobilePhone &&
          activity.MobilePhone.length > 0) ||
          (activity &&
            activity.Prospect &&
            activity.Prospect.MobilePhone &&
            activity.Prospect.MobilePhone.length > 0)
          ? true
          : (setMessage({
              text: "No es posible agendar una llamada sin número de contacto.",
              variant: "warning",
              open: "open",
            }),
            false);
      case 3:
        return (activity && activity.Email && activity.Email.length > 0) ||
          (activity &&
            activity.Prospect &&
            activity.Prospect.Email &&
            activity.Prospect.Email.length > 0)
          ? true
          : (setMessage({
              text:
                "No es posible agendar un correo sin dirección electrónica.",
              variant: "warning",
              open: "open",
            }),
            false);
      case 4:
        return (activity &&
          activity.MobilePhone &&
          activity.MobilePhone.length > 0) ||
          (activity &&
            activity.Prospect &&
            activity.Prospect.MobilePhone &&
            activity.Prospect.MobilePhone.length > 0)
          ? true
          : (setMessage({
              text: "No es posible agendar un whatsapp sin número de contacto.",
              variant: "warning",
              open: "open",
            }),
            false);
      case 5:
        return (activity &&
          activity.MobilePhone &&
          activity.MobilePhone.length > 0) ||
          (activity &&
            activity.Prospect &&
            activity.Prospect.MobilePhone &&
            activity.Prospect.MobilePhone.length > 0)
          ? true
          : (setMessage({
              text:
                "No es posible agendar un mensaje de texto sin número de contacto.",
              variant: "warning",
              open: "open",
            }),
            false);
      default:
        return (
          setMessage({
            text:
              "No es posible utilizar ese tipo de actividad, revisa la información.",
            variant: "warning",
            open: "open",
          }),
          false
        );
    }
  }

  const handleActivityEnd = () => {
    if (validate()) {
      setSaved(true);
      let payload = {
        TipifyTracingId:
          props.reschedule === true
            ? 7
            : keyLast && !activity.StartDate
            ? -1
            : values.TipifyTracingId,
        Observations: values.Observations,
        ProspectTemperature: values.ProspectTemperature,
        ProspectId: activity.ProspectId,
        ActivityTypeId: values.ActivityTypeId,
        ActivitySubjectId: values.ActivitySubjectId,
        ActivityDate: values.ActivityDate,
        Comments: values.Comments,
        StartTime: format(values.StartTime, "HH:mm"),
        EndTime: format(values.EndTime, "HH:mm"),
        PhoneNumber: values.PhoneNumber,
        ProspectAddress: values.ProspectAddress,
        Subject: values.Subject,
      };
      if (payload && payload.ProspectId) {
        try {
          api
            .post(
              `activity/${activity.Id}/${
                props.reschedule ? "reschedule" : "end"
              }`,
              payload
            )
            .then(
              (result) => {
                if (result.data.status === 201) {
                  props.fetchActivities && props.fetchActivities();
                  props.handleClose({
                    text: `Se ${
                      props.reschedule ? "reagendó" : "finalizó"
                    } correctamente la actividad`,
                    variant: "success",
                    open: true,
                  });
                  setValues({
                    ActivityDate: new Date(),
                    StartTime: new Date(),
                    EndTime: new Date(),
                  });
                  setSelectedTipifyTracing({});
                  setSaved(false);
                } else {
                  props.handleClose({
                    text: `Actividad ya finalizada`,
                    variant: "warning",
                    open: true,
                  });
                  setSaved(false);
                }
              },
              (error) => {
                console.log(error);
              }
            );
        } catch {}
      }
    }
  };
  const handleActivityAdd = () => {
    if (validate()) {
      setSaved(true);
      let payload = {
        TipifyTracingId:
          props.reschedule === true ? 7 : keyLast ? -1 : values.TipifyTracingId,
        Observations: values.Observations,
        ProspectId: activity.ProspectId ? activity.ProspectId : prospectId,
        ActivityTypeId: values.ActivityTypeId,
        ActivitySubjectId: values.ActivitySubjectId,
        ActivityDate: values.ActivityDate,
        Comments: values.Comments,
        StartTime: format(values.StartTime, "HH:mm"),
        EndTime: format(values.EndTime, "HH:mm"),
        PhoneNumber: values.PhoneNumber,
        ProspectAddress: values.ProspectAddress,
        Subject: values.Subject,
      };
      if (payload && payload.ProspectId) {
        debugger;
        try {
          api.post(`activity/add`, payload).then(
            (result) => {
              if (result.data.status === 201) {
                props.fetchActivities();
                props.handleClose({
                  text: `Se ${
                    props.reschedule ? "reagendó" : "finalizó"
                  } correctamente la actividad`,
                  variant: "success",
                  open: true,
                });
                setValues({
                  ActivityDate: new Date(),
                  StartTime: new Date(),
                  EndTime: new Date(),
                });
                setSelectedTipifyTracing({});
                setSaved(false);
              }
            },
            (error) => {
              console.log(error);
            }
          );
        } catch {}
      }
    }
  };

  const fetchActivityTypes = async () => {
    api.get("catalog/activity-types").then((result) => {
      setActivityTypes(result.data.body);
    });
  };

  const fetchActivitySubjects = async () => {
    api.get("catalog/activity-subjects").then((result) => {
      setActivitySubjects(result.data.body);
    });
  };

  const filteredActivitySubjects = activitySubjects.filter(
    (activitySubject) => {
      return activitySubject.ActivityTypeId === filterActivitySubjects;
    }
  );

  useEffect(() => {
    if (activity && activity.Id) {
      fetchTipifyTracings(activity.ActivityTypeId);
    }
  }, [activity]);

  useEffect(() => {
    handleEventChange(values.ActivityDate);
  }, []);

  useEffect(() => {
    fetchActivityTypes();
    fetchActivitySubjects();
  }, []);

  const handleActivityTypeChange = (event, value) => {
    if (value) {
      setValues({
        ...values,
        ["ActivityTypeId"]: value !== null ? value.Id : "",
      });
      setFilterActivitySubjects(value.Id);
      fetchActivitySubjects();

      let date = new Date();
      let minutos = date.getMinutes() + value.DurationTime;
      date.setMinutes(minutos);

      setValues({
        ...values,
        StartTime: new Date(),
        EndTime: date,
        ActivityTypeId: value != null ? value.Id : null,
        ActivitySubjectId: null,
      });
      setFilterActivitySubjects(value.Id);
    }
  };

  const handleEventChange = (date) => {
    if (isValid(date)) {
      setValues({
        ...values,
        ActivityDate: date,
      });

      var data = {
        InitialActivityDate: format(date, "yyyy/MM/dd"),
        FinalActivityDate: format(date, "yyyy/MM/dd"),
      };
      api
        .get("activity/activities", { params: data })
        .then((result) => {
          setCurrentActivities(result.data.body);
        })
        .catch((error) =>
          console.log(`No se pudo obtener las actividades: ${error}`)
        );
    } else {
      console.log("Fecha invalida: " + date);
    }
  };

  const handleClose = (message) => {
    if (message !== null) {
      setMessage({
        text: message.text,
        variant: message.variant,
        open: message.open,
      });
    }
  };
  return (
    <div>
      <GlobalSnackbar message={message} handleClose={handleClose} />
      {activity && (
        <Dialog
          open={show}
          onClose={props.handleCloseQuotation}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <h3>{title}</h3>
          </DialogTitle>
          <DialogContent>
            {activity && activity.ProspectId && (
              <Grid container>
                <Grid item xs={4} sm={3} md={2}>
                  Prospecto
                </Grid>
                <Grid item xs={8} sm={9} md={10}>
                  <h6>{activity.ProspectName}</h6>
                </Grid>
                {!keyLast && (
                  <>
                    <Grid item xs={4} sm={2}>
                      Tipo
                    </Grid>
                    <Grid item xs={8} sm={10}>
                      <b>
                        {typeof activity.ActivityType === "object"
                          ? activity.ActivityType.Name
                          : activity.ActivityType}
                      </b>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                      Asunto
                    </Grid>
                    <Grid item xs={8} sm={10}>
                      <b>
                        {typeof activity.ActivityType === "object"
                          ? activity.ActivitySubjects.Name
                          : activity.ActivitySubject}
                      </b>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                      Fecha
                    </Grid>
                    <Grid item xs={8} sm={10}>
                      <b>{format(values.ActivityDate, "dd/MM/yyyy")}</b>
                    </Grid>
                  </>
                )}
              </Grid>
            )}
            <Grid item xs={12} className={classes.container}>
              <Grid container spacing={1}>
                {((activity !== undefined &&
                  activity.StartDate !== null &&
                  !newActivity) ||
                  props.reschedule === true) && (
                  <>
                    <Grid item xs={12}>
                      <Autocomplete
                        name="TipifyTracingId"
                        options={
                          props.reschedule
                            ? tipifyTracingsReschedule
                            : tipifyTracings
                        }
                        getOptionLabel={(option) => `${option.Name}`}
                        value={(props.reschedule
                          ? tipifyTracingsReschedule
                          : tipifyTracings
                        ).find((option) => {
                          if (option.Id === values.TipifyTracingId) {
                            return option;
                          }
                        })}
                        onInputChange={(event, newValue) => {
                          if (newValue) {
                            let currentOption = props.reschedule
                              ? tipifyTracingsReschedule.find((option) => {
                                  if (option.Name === newValue) {
                                    return option;
                                  }
                                })
                              : tipifyTracings.find((option) => {
                                  if (option.Name === newValue) {
                                    return option;
                                  }
                                });

                            setValues({
                              ...values,
                              TipifyTracingId: currentOption.Id
                                ? currentOption.Id
                                : 0,
                            });
                            setSelectedTipifyTracing(currentOption);
                          } else {
                            setSelectedTipifyTracing(null);
                          }
                        }}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tipificación"
                            variant="outlined"
                          />
                        )}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="temperature">
                          Temperatura
                        </InputLabel>
                        <Select
                          labelId="temperature"
                          id="temperature"
                          value={temperatures.find(
                            (option) => option.Code === values.ProspectTemperature
                          )}
                          onChange={(event)=>(
                            setValues({
                              ...values,
                              ProspectTemperature: event.target.value
                            })
                          )}
                          label="ProspectTemperature"
                        >
                          <MenuItem value="">
                            
                          </MenuItem>
                          <MenuItem value={"A"} style={{ color: "#ff0000", fontWeight: "600" }}>Alta</MenuItem>
                          <MenuItem value={"M"} style={{ color: "#ffc931", fontWeight: "600" }}>Media</MenuItem>
                          <MenuItem value={"B"} style={{ color: "#007eff", fontWeight: "600" }}>Baja</MenuItem>
                        </Select>
                      </FormControl>
                     {/*  <Autocomplete
                        name="ProspectTemperature"
                        options={temperatures}
                        getOptionLabel={(option) => `${option.Name}`}
                        value={temperatures.find(
                          (option) => option.Code === values.ProspectTemperature
                        )}
                        onInputChange={(event, newValue) => {
                          setValues({
                            ...values,
                            ProspectTemperature:
                              newValue === "Alta"
                                ? "A"
                                : newValue === "Media"
                                ? "M"
                                : newValue === "Baja"
                                ? "B"
                                : "",
                          });
                        }}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Temperatura"
                            variant="outlined"
                            style={{ background: "#000" }}
                          />
                        )}
                        required
                      /> */}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="Observations"
                        variant="outlined"
                        multiline
                        rows={2}
                        margin="normal"
                        label="Observaciones"
                        onChange={(event) => {
                          setValues({
                            ...values,
                            Observations: event.target.value,
                          });
                        }}
                      />
                    </Grid>
                  </>
                )}
                {(selectedTipifyTracing && selectedTipifyTracing.FollowIt) ||
                newActivity ? (
                  <>
                    <Grid item xs={12}>
                      <h4>Proxima actividad</h4>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        name="ActivityTypeId"
                        options={activityTypes}
                        getOptionLabel={(option) => `${option.Name}`}
                        value={activityTypes.find(
                          (option) => option.Id === values.ActivityTypeId
                        )}
                        onChange={(event, newValue) => {
                          handleActivityTypeChange(event, newValue);
                        }}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tipo de actividad"
                            variant="outlined"
                            error={hasErrors.ActivityTypeId}
                          />
                        )}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        name="ActivitySubjectId"
                        options={filteredActivitySubjects}
                        getOptionLabel={(option) => `${option.Name}`}
                        value={filteredActivitySubjects.find(
                          (option) => option.Id === values.ActivitySubjectId
                        )}
                        onChange={(event, newValue) => {
                          setValues({
                            ...values,
                            ["ActivitySubjectId"]:
                              newValue !== null ? newValue.Id : "",
                          });
                        }}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Asunto"
                            variant="outlined"
                            error={hasErrors.ActivitySubjectId}
                          />
                        )}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <KeyboardDatePicker
                        fullWidth
                        error={hasErrors.ActivityDate}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Fecha de actividad"
                        format="dd/MM/yyyy"
                        InputAdornmentProps={{ position: "end" }}
                        name="ActivityDate"
                        value={values.ActivityDate}
                        onChange={(date) => handleEventChange(date)}
                        minDate={new Date()}
                        maxDate={new Date("2100")}
                        views={["year", "month", "date"]}
                        minDateMessage="La fecha no debe ser anterior a la fecha mínima"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <KeyboardTimePicker
                        fullWidth
                        ampm={false}
                        error={hasErrors.StartTime}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Hora de inicio"
                        name="StartDate"
                        value={values.StartTime}
                        InputAdornmentProps={{ position: "end" }}
                        onChange={(time) => {
                          if (isValid(time)) {
                            if (values.ActivityTypeId) {
                              let duration = activityTypes.find(
                                (option) => option.Id === values.ActivityTypeId
                              ).DurationTime;
                              let d = new Date(time);
                              d.setMinutes(d.getMinutes() + duration);
                              let payload = {
                                EndTime: new Date(d),
                                StartTime: time,
                              };
                              setValues({ ...values, ...payload });
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <KeyboardTimePicker
                        fullWidth
                        ampm={false}
                        error={hasErrors.EndTime}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Hora de finalización"
                        InputAdornmentProps={{ position: "end" }}
                        name="EndTime"
                        value={values.EndTime}
                        onChange={(time) => {
                          if (isValid(time)) {
                            if (time < values.StartTime) {
                              if (values.ActivityTypeId) {
                                let duration = activityTypes.find(
                                  (option) =>
                                    option.Id === values.ActivityTypeId
                                ).DurationTime;
                                let d = new Date(activity.StartTime);
                                d.setMinutes(d.getMinutes() + duration);
                                let payload = {
                                  EndTime: new Date(d),
                                };
                                setValues({ ...values, ...payload });
                              }
                            } else {
                              setValues({ ...values, EndTime: time });
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="Comments"
                        variant="outlined"
                        multiline
                        rows={2}
                        margin="normal"
                        label="Comentarios"
                        onChange={(event) => {
                          setValues({
                            ...values,
                            Comments: event.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <ActivityTimeLine
                      title={`Actividades programadas para el día: ${values.ActivityDate &&
                        format(new Date(values.ActivityDate), "dd/MM/yyyy")}`}
                      activities={currentActivities}
                    />
                  </>
                ) : activity &&
                  !activity.StartDate &&
                  !selectedTipifyTracing.FollowIt ? (
                  <>
                    <Grid item xs={12}>
                      <h4>Proxima actividad</h4>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        name="ActivityTypeId"
                        options={activityTypes}
                        getOptionLabel={(option) => `${option.Name}`}
                        value={activityTypes.find(
                          (option) => option.Id === values.ActivityTypeId
                        )}
                        onChange={(event, newValue) => {
                          handleActivityTypeChange(event, newValue);
                        }}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tipo de actividad"
                            variant="outlined"
                            error={hasErrors.ActivityTypeId}
                          />
                        )}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        name="ActivitySubjectId"
                        options={filteredActivitySubjects}
                        getOptionLabel={(option) => `${option.Name}`}
                        value={filteredActivitySubjects.find(
                          (option) => option.Id === values.ActivitySubjectId
                        )}
                        onChange={(event, newValue) => {
                          setValues({
                            ...values,
                            ["ActivitySubjectId"]:
                              newValue !== null ? newValue.Id : "",
                          });
                        }}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Asunto"
                            variant="outlined"
                            error={hasErrors.ActivitySubjectId}
                          />
                        )}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <KeyboardDatePicker
                        fullWidth
                        error={hasErrors.ActivityDate}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Fecha de actividad"
                        format="dd/MM/yyyy"
                        InputAdornmentProps={{ position: "end" }}
                        name="ActivityDate"
                        value={values.ActivityDate}
                        onChange={(date) => handleEventChange(date)}
                        minDate={new Date()}
                        maxDate={new Date("2100")}
                        views={["year", "month", "date"]}
                        minDateMessage="La fecha no debe ser anterior a la fecha mínima"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <KeyboardTimePicker
                        fullWidth
                        ampm={false}
                        error={hasErrors.StartTime}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Hora de inicio"
                        name="StartDate"
                        value={values.StartTime}
                        InputAdornmentProps={{ position: "end" }}
                        onChange={(time) => {
                          if (isValid(time)) {
                            if (values.ActivityTypeId) {
                              let duration = activityTypes.find(
                                (option) => option.Id === values.ActivityTypeId
                              ).DurationTime;
                              let d = new Date(time);
                              d.setMinutes(d.getMinutes() + duration);
                              let payload = {
                                EndTime: new Date(d),
                                StartTime: time,
                              };
                              setValues({ ...values, ...payload });
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <KeyboardTimePicker
                        fullWidth
                        ampm={false}
                        error={hasErrors.EndTime}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Hora de finalización"
                        InputAdornmentProps={{ position: "end" }}
                        name="EndTime"
                        value={values.EndTime}
                        onChange={(time) => {
                          if (isValid(time)) {
                            //setValues({ ...values, ["EndTime"]: time });
                            if (time < values.StartTime) {
                              if (values.ActivityTypeId) {
                                let duration = activityTypes.find(
                                  (option) =>
                                    option.Id === values.ActivityTypeId
                                ).DurationTime;
                                let d = new Date(activity.StartTime);
                                d.setMinutes(d.getMinutes() + duration);
                                let payload = {
                                  EndTime: new Date(d),
                                };
                                setValues({ ...values, ...payload });
                              }
                            } else {
                              setValues({ ...values, EndTime: time });
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="Comments"
                        variant="outlined"
                        multiline
                        rows={2}
                        margin="normal"
                        label="Comentarios"
                        onChange={(event) => {
                          setValues({
                            ...values,
                            Comments: event.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <ActivityTimeLine
                      title={`Actividades programadas para el día: ${values.ActivityDate &&
                        format(new Date(values.ActivityDate), "dd/MM/yyyy")}`}
                      activities={currentActivities}
                    />
                  </>
                ) : !activity ? (
                  <>
                    <Grid item xs={12}>
                      <h4>Proxima actividad</h4>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        name="ActivityTypeId"
                        options={activityTypes}
                        getOptionLabel={(option) => `${option.Name}`}
                        value={activityTypes.find(
                          (option) => option.Id === values.ActivityTypeId
                        )}
                        onChange={(event, newValue) => {
                          handleActivityTypeChange(event, newValue);
                        }}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tipo de actividad"
                            variant="outlined"
                            error={hasErrors.ActivityTypeId}
                          />
                        )}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        name="ActivitySubjectId"
                        options={filteredActivitySubjects}
                        getOptionLabel={(option) => `${option.Name}`}
                        value={filteredActivitySubjects.find(
                          (option) => option.Id === values.ActivitySubjectId
                        )}
                        onChange={(event, newValue) => {
                          setValues({
                            ...values,
                            ["ActivitySubjectId"]:
                              newValue !== null ? newValue.Id : "",
                          });
                        }}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Asunto"
                            variant="outlined"
                            error={hasErrors.ActivitySubjectId}
                          />
                        )}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <KeyboardDatePicker
                        fullWidth
                        error={hasErrors.ActivityDate}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Fecha de actividad"
                        format="dd/MM/yyyy"
                        InputAdornmentProps={{ position: "end" }}
                        name="ActivityDate"
                        value={values.ActivityDate}
                        onChange={(date) => handleEventChange(date)}
                        minDate={new Date()}
                        maxDate={new Date("2100")}
                        views={["year", "month", "date"]}
                        minDateMessage="La fecha no debe ser anterior a la fecha mínima"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <KeyboardTimePicker
                        fullWidth
                        ampm={false}
                        error={hasErrors.StartTime}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Hora de inicio"
                        name="StartDate"
                        value={values.StartTime}
                        InputAdornmentProps={{ position: "end" }}
                        onChange={(time) => {
                          if (isValid(time)) {
                            if (values.ActivityTypeId) {
                              let duration = activityTypes.find(
                                (option) => option.Id === values.ActivityTypeId
                              ).DurationTime;
                              let d = new Date(time);
                              d.setMinutes(d.getMinutes() + duration);
                              let payload = {
                                EndTime: new Date(d),
                                StartTime: time,
                              };
                              setValues({ ...values, ...payload });
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <KeyboardTimePicker
                        fullWidth
                        ampm={false}
                        error={hasErrors.EndTime}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Hora de finalización"
                        InputAdornmentProps={{ position: "end" }}
                        name="EndTime"
                        value={values.EndTime}
                        onChange={(time) => {
                          if (isValid(time)) {
                            //setValues({ ...values, ["EndTime"]: time });
                            if (time < values.StartTime) {
                              if (values.ActivityTypeId) {
                                let duration = activityTypes.find(
                                  (option) =>
                                    option.Id === values.ActivityTypeId
                                ).DurationTime;
                                let d = new Date(activity.StartTime);
                                d.setMinutes(d.getMinutes() + duration);
                                let payload = {
                                  EndTime: new Date(d),
                                };
                                setValues({ ...values, ...payload });
                              }
                            } else {
                              setValues({ ...values, EndTime: time });
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="Comments"
                        variant="outlined"
                        multiline
                        rows={2}
                        margin="normal"
                        label="Comentarios"
                        onChange={(event) => {
                          setValues({
                            ...values,
                            Comments: event.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <ActivityTimeLine
                      title={`Actividades programadas para el día: ${values.ActivityDate &&
                        format(new Date(values.ActivityDate), "dd/MM/yyyy")}`}
                      activities={currentActivities}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={props.handleClose} color="primary">
            Cancelar
          </Button> */}
            <Button
              onClick={() => {
                props.handleClose({});
                setValues({
                  ActivityDate: new Date(),
                  StartTime: new Date(),
                  EndTime: new Date(),
                });
                setSaved(false);

                setSelectedTipifyTracing({});
              }}
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              onClick={newActivity ? handleActivityAdd : handleActivityEnd}
              color="primary"
              disabled={saved}
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
export default CustomModal;
