import React, { useState, useEffect } from "react";
import {
  AdvanceTablesActivities,
  StatsAdviserProspection,
  StatsDonut,
  StatsDonutActivities,
  StatsDonutProspect,
  ListsWidgetCarousel,
  ProfileReport,
  StatsProspectionMeans,
  StatsTipifyTracings,
} from "../widgets";
import CustomTable from "../../../app/components/CustomTable";

import moment from "moment";
import DateRangePickerCustom from "../../../app/components/DateRangePickerCustom";
import api from "../../../api";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";

export function AdviserDashboard(props) {
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
  const [amountData, setAmountData] = useState([]);
  const [activitiesCountData, setActivitiesCountData] = useState([]);
  const [tipifyTracingsCount, setTipifyTracingsCount] = useState([]);
  const [prospectionMeansCount, setProspectionMeansCount] = useState([]);
  const [activitiesStatus, setActivitiesStatus] = useState({});
  const [createdProspectsPerDay, setCreatedProspectsPerDay] = useState([]);
  const [createdProspectsDate, setCreatedProspectsDate] = useState([]);
  let rangeDate = `Del ${moment(startDate).format("DD/MM/yyyy")} al ${moment(
    endDate
  ).format("DD/MM/yyyy")}`;

  const [salesAdvisors, setSalesAdvisors] = useState([]);
  const [advisers, setAdvisers] = useState([]);
  const [assistantManagers, setAssistantManagers] = useState([]);
  const [assistantManagersId, setAssistantManagersId] = useState([]);
  const [managements, setManagements] = useState([]);
  const [managementsId, setManagementsId] = useState([]);

  const { user } = useSelector((state) => state.auth);


  const fetchManagements = () => {
    let assistantManagers = assistantManagersId.map(item => item.AssistantManagerId);
    api.get("catalog/managements", {
      params: {
        _AssistantManagers: assistantManagers, 
      },
    }).then(
      (result) => {
        setManagements(result.data.body);
      },
      (error) => {
        setManagements([]);
      }
    );
  }

  const fetchAdvisers = () => {
    let managements = managementsId.map(item => item.ManagementId);
    api.get("catalog/advisers", {
        params: {
          Management: managements,
        },
      })
      .then(
        (result) => {
          setAdvisers(result.data.body);
        },
        (error) => {
          setAdvisers([]);
        }
      );
  }

  const handleManagementChange = (event, value) => {
    if (value !== null) {      
      setManagementsId(value);
      setSalesAdvisors([])
    } else {
      setManagementsId(undefined);
    }
  }

  const handleAdviserChange = (event, value) => {
    if (value !== null) {
      setSalesAdvisors(value);
    } else {
      setSalesAdvisors(undefined);
    }
  }

  const fetchActivitiesCount = () => {
    let ids = salesAdvisors.map( item => item.EmployeeId );
    let managements = managementsId.map( item => item.ManagementId );
    return api
      .get(`bi/dashboard/activities-count`, {
        params: {
          StartDate: moment(startDate).format("yyyy/MM/DD"),
          EndDate: moment(endDate).format("yyyy/MM/DD"),
          Advisors: ids,
          Management: managements,
        },
      })
      .then(
        (result) => {
          if (result.status === 200) {
            return result.data.body;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    fetchManagements();
  }, [] /* [assistantManagersId] */ );

  useEffect(() => {
    fetchAdvisers();
  }, [managementsId]);

  const fetchAmount = () => {
    let ids = salesAdvisors.map( item => item.EmployeeId );
    let managements = managementsId.map( item => item.ManagementId );
    return api
      .get(`bi/sales-amount`, {
        params: {
          StartDate: moment(startDate).format("yyyy/MM/DD"),
          EndDate: moment(endDate).format("yyyy/MM/DD"),
          Advisors: ids,
          Management: managements,
        },
      })
      .then(
        (result) => {
          if (result.status === 200) {
            return result.data.body;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const fetchTipifyTracingsCount = () => {
    let ids = salesAdvisors.map( item => item.EmployeeId );
    let managements = managementsId.map( item => item.ManagementId );
    return api
      .get(`/bi/dashboard/tipify-tracings-count`, {
        params: {
          StartDate: moment(startDate).format("yyyy/MM/DD"),
          EndDate: moment(endDate).format("yyyy/MM/DD"),
          Advisors: ids,
          Management: managements,
        },
      })
      .then(
        (result) => {
          if (result.status === 200) {
            return result.data.body;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const fetchProspectionMeansCount = () => {
    let ids = salesAdvisors.map( item => item.EmployeeId );
    let managements = managementsId.map( item => item.ManagementId );
    return api
      .get(`/bi/dashboard/prospection-means-count`, {
        params: {
          StartDate: moment(startDate).format("yyyy/MM/DD"),
          EndDate: moment(endDate).format("yyyy/MM/DD"),
          Advisors: ids,
          Management: managements,
        },
      })
      .then(
        (result) => {
          if (result.status === 200) {
            return result.data.body;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const fetchActivitiesStatus = () => {
    let ids = salesAdvisors.map( item => item.EmployeeId );
    let managements = managementsId.map( item => item.ManagementId );
    return api
      .get(`/dashboard/get-activities`, {
        params: {
          StartDate: moment(startDate).format("yyyy/MM/DD"),
          EndDate: moment(endDate).format("yyyy/MM/DD"),
          Advisors: ids,
          Management: managements,
        },
      })
      .then(
        (result) => {
          if (result.status === 201) {
            return result.data.body;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const fetchCreatedProspectsPerDay = () => {
    let ids = salesAdvisors.map( item => item.EmployeeId );
    let managements = managementsId.map( item => item.ManagementId );
    return api
      .get(`bi/created-prospects-per-date`, {
        params: {
          InitialDate: moment(startDate).format("yyyy/MM/DD"),
          FinalDate: moment(endDate).format("yyyy/MM/DD"),
          Advisors: ids,
          Management: managements,
        },
      })
      .then(
        (result) => {
          if (result.status === 200) {
            return result.data.body;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const fetchCreatedProspectsDate = () => {
    let ids = salesAdvisors.map( item => item.EmployeeId );
    let managements = managementsId.map( item => item.ManagementId );
    return api
      .get(`bi/created-prospects-date`, {
        params: {
          InitialDate: moment(startDate).format("yyyy/MM/DD"),
          FinalDate: moment(endDate).format("yyyy/MM/DD"),
          Advisors: ids,
          Management: managements,
        },
      })
      .then(
        (result) => {
          if (result.status === 200) {
            return result.data.body;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleCallback = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const rowsDownPayment = [
    {
      name: "ProspectName",
      label: "Prospecto",
    },
    {
      name: "Name",
      label: "Asunto",
    },
    {
      name: "ActivityDate",
      label: "Fecha en que debió iniciar la actividad",
      options: {
        customBodyRender: (val) => {
          return moment(val).format("DD/MM/yyyy");
        },
      },
    },
    {
      name: "Advisor",
      label: "Asesor",
    }
  ];

  useEffect(() => {
    Promise.all([
      fetchProspectionMeansCount(),
      fetchTipifyTracingsCount(),
      fetchActivitiesCount(),
      fetchAmount(),
      fetchActivitiesStatus(),
      fetchCreatedProspectsPerDay(),
      fetchCreatedProspectsDate(),
    ]).then((values) => {
      setProspectionMeansCount(values[0]);
      setTipifyTracingsCount(values[1]);
      setActivitiesCountData(values[2]);
      setAmountData(values[3]);
      if (values[4] && values[4].OnTime) {
        setActivitiesStatus(values[4]);
      }
      setCreatedProspectsPerDay(values[5]);
      setCreatedProspectsDate(values[6]);
    });
  }, [endDate, startDate, managementsId, salesAdvisors]);

  return (
    <>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-xxl-6 order-3 order-xxl-3 mb-5">
          <StatsDonutProspect
            activitiesStatus={activitiesStatus}
            className="card-stretch gutter-b align-items-start"
          />
        </div>
        <div className="col-md-6 col-lg-6 col-xxl-6 order-3 order-xxl-3 mb-5">
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-body d-flex flex-column p-2 align-items-start" >
              <CustomTable
                data={activitiesStatus?.UnattendedDetail}
                columns={rowsDownPayment}
                title="Detalle de prospectos no atendidos"
                actionAdd={false}
                dateRange={false}
                style={{maxHeight: "500px"}}
              />
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-12 col-xxl-12 order-3 order-xxl-3 mb-5">
          <div className="card card-custom">
            <div className="card-header border-0 pt-5 bg-light">
              <div className="col-md-12 col-lg-12">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Rango de fechas
                </span>
              </h3>
              <div className="col-md-12 col-lg-12">
                <DateRangePickerCustom
                  handleCallback={handleCallback}
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
              </div>
              {user && user.RoleId  > 5 ? (<></>) : (
                <div className="col-md-12 col-lg-12">
                  <div className="col-xs-12 col-sm-6">
                    <Autocomplete
                      multiple               
                      options={managements}
                      getOptionLabel={(option) => option.ManagementName}
                      onChange={handleManagementChange}                
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Gerencia"
                          placeholder="Gerencia"
                        />
                      )}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <Autocomplete
                      name="SlpCode"
                      multiple   
                      options={advisers}
                      getOptionLabel={(option) =>
                        `${option.EmployeeId} - ${option.Adviser}`
                      }
                      onChange={handleAdviserChange}
                      value={salesAdvisors}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="SlpCode"
                          label="Asesor"
                          margin="normal"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              ) }

            </div>

            <div className="card-body">
              <ProfileReport
                amountData={amountData}
                activitiesCountData={activitiesCountData}
                tipifyTracingsCount={tipifyTracingsCount}
                prospectionMeansCount={prospectionMeansCount}
              />
              {/* <Amount className="card-stretch gutter-b" startDate={startDate} endDate={endDate} />
                <ActivitiesCount startDate={startDate} endDate={endDate}/> */}
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-12 col-xxl-12 order-5 order-xxl-5">
          <StatsAdviserProspection
            createdProspectsPerDay={createdProspectsPerDay}
            createdProspectsDate={createdProspectsDate}
            className="card-stretch gutter-b"
            rangeDate={rangeDate}
          />
        </div>

        <div className="col-md-6 col-lg-6 col-xxl-6 order-5 order-xxl-5">
          <StatsDonut
            activitiesStatus={activitiesStatus}
            className="card-stretch gutter-b"
            rangeDate={rangeDate}
          />
        </div>
        <div className="col-md-6 col-lg-6 col-xxl-6 order-5 order-xxl-5">
          <StatsDonutActivities
            activitiesStatus={activitiesStatus}
            className="card-stretch gutter-b"
            rangeDate={rangeDate}
          />
        </div>
        <div className="col-md-12 col-lg-12 col-xxl-12 order-5 order-xxl-5">
          <StatsTipifyTracings
            tipifyTracingsCount={tipifyTracingsCount}
            className="card-stretch gutter-b"
            rangeDate={rangeDate}
          />
        </div>
        <div className="col-md-12 col-lg-12 col-xxl-12 order-5 order-xxl-5">
          <StatsProspectionMeans
            prospectionMeansCount={prospectionMeansCount}
            className="card-stretch gutter-b"
            rangeDate={rangeDate}
          />
        </div>
      </div>
    </>
  );
}
