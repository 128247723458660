// Mixed
export { MixedWidget1 } from "./mixed/MixedWidget1";
export { MixedWidget14 } from "./mixed/MixedWidget14";

// Lists
export { ListsWidget1 } from "./lists/ListsWidget1";
export { ListsWidget3 } from "./lists/ListsWidget3";
export { ListsWidget4 } from "./lists/ListsWidget4";
export { ListsWidget8 } from "./lists/ListsWidget8";
export { ListsWidget9 } from "./lists/ListsWidget9";
export { ListsWidgetCarousel } from "./lists/ListsWidgetCarousel";

// Stats
export { StatsWidget11 } from "./stats/StatsWidget11";
export { StatsAdviserProspection } from "./stats/StatsAdviserProspection";
export { StatsProspectionMeans } from "./stats/StatsProspectionMeans";
export { StatsTipifyTracings } from "./stats/StatsTipifyTracings";
export { StatsWidget12 } from "./stats/StatsWidget12";
export { StatsDonut } from "./stats/StatsDonut";
export { StatsDonutActivities } from "./stats/StatsDonutActivities";
export { StatsDonutProspect } from "./stats/StatsDonutProspect";
export { StatsTotalDonut } from "./stats/StatsTotalDonut";
export { StatsDailySales } from "./stats/StatsDailySales";
export { StatsProspectionSales } from "./stats/StatsProspectionSales";
export { StatsRanking } from "./stats/StatsRanking";
export { StatsTopItemsSold } from "./stats/StatsTopItemsSold";
export { StatsFinancing } from "./stats/StatsFinancing";
export { StatsCash } from "./stats/StatsCash";
export { StatsSalesAmount } from "./stats/StatsSalesAmount";
export { StatsHeatMapByCounty } from "./stats/StatHeatMapByCounty";
/* export {StatsHeatMapByItemCode} from "./stats/StatsHeatMapByItemCode"; */

export { StatsSalesCompare } from "./stats/StatsSalesCompare";

// Advance tables
export { AdvanceTablesActivities } from "./advance-tables/AdvanceTablesActivities";
export { AdvanceTablesWidget4 } from "./advance-tables/AdvanceTablesWidget4";

//Reports
export { Amount } from "./reports/Amount";
export { ActivitiesCount } from "./reports/ActivitiesCount";
export { ProfileReport } from "./profile/Profile";
