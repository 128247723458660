/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

export function StatsFinancing({ className, financing, endDate, startDate}) {

let series = [];
let categories = []; 
let total = 0;
if (financing && financing.length > 0) {
  financing.sort(function (a, b) {
    return b.SalesPerMethod - a.SalesPerMethod;
  })
    financing.map((data) => {
    series.push(data.SalesPerMethod);
    categories.push(data.PaymentMethod);
  })
total = series.reduce((total, num)=> num + total);
}
  useEffect(() => {
    const element = document.getElementById("kt_stats_financing");
    if (!element) {
      return;
    }
    const options = {
      series: [
        {
          name: "De",
          data: series,
        },
      ],
      chart: {
        type: "bar",
        maxHeight: "fit-content",
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
            horizontal: true,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "";
        },
        offsetX:  30,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ["#304758"]
        }
      },
      legend: {
        show: true,
      },
      stroke: {
        curve: 'straight'
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      xaxis: {
        categories: categories,
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
    };
    try {
      if ( financing) {
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
          chart.destroy();
        };
      }
    } catch {}
  }, [
    financing
  ]);



  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
          <div className="d-flex flex-column mr-2">
            <span
              className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5"
            >
              Financiamiento
            </span>
            <span className="text-dark-75 text-hover-primary">
              Del {moment(startDate).format("DD/MM/yyyy")} al {moment(endDate).format("DD/MM/yyyy")}
            </span>
            </div>
          <span className="symbol symbol-light-primary symbol-45">
            <span className="symbol-label font-weight-bolder font-size-h6">
              {total}
            </span>
          </span>
        </div>
        <div className="flex-grow-1">
          {financing ? (
            <div
              id="kt_stats_financing"
              className="card-rounded-bottom"
              style={{ height: "230px" }}
            ></div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
