/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import ApexCharts from "apexcharts";;

export function StatsDonutActivities({
  className,
  activitiesStatus,
  rangeDate
}) {
  let series = [];
  let categories = [];
  if (activitiesStatus) {
    let payload = {
      "A tiempo" : activitiesStatus?.OnTime ?? 0,
      "Atrasadas": activitiesStatus?.OutTime ?? 0,   
    }
    series = Object.values(payload);
    categories = Object.keys(payload);
  }

  useEffect(() => {
    const element = document.getElementById("kt_stats_widget_chart_donut_activities");

    if (!element) {
      return;
    }

    const options = {
      series: series,
      plotOptions: {
        pie: {
          customScale: 0.8,
          donut: {
            labels: {
              show: true,
              
            }
          },
        },
      },
      labels: categories,
      colors: ["#00e495","#feaf1a", "#008ffb", "#ff4560", "#feaf1a", "#00e495"],
      chart: {
        type: "donut",
      },
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    try {
      if (activitiesStatus) {
        const chartActivities = new ApexCharts(element, options);
        chartActivities.render();
        return function cleanUp() {
          chartActivities.destroy();
        };
      }
    } catch {}
  }, [activitiesStatus]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column p-2">
        <div className="d-flex align-items-start justify-content-between card-spacer">
          <div className="d-flex flex-column mr-2">
            <span
              className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5"
            >
              Resultado de actividades
            </span>
            <span
              className="text-primary text-hover-dark font-size-h6"
            >{rangeDate}</span>
          </div>
          <span className="symbol symbol-light-primary symbol-45">
            <span className="symbol-label font-weight-bolder font-size-h6">
              {activitiesStatus?.OnTime +
                activitiesStatus?.OutTime }
            </span>
          </span>
        </div>
        <>
          {activitiesStatus ? (
            <div
              id="kt_stats_widget_chart_donut_activities"
              className="card-rounded-bottom"
              style={{ height: "80px" }}
            ></div>
          ) : (
            <></>
          )}
        </>
      </div>
    </div>
  );
}
